import React, { Component } from 'react';
import RadioField from '../components/common/RadioFieldPIV'
import { Jumbotron, Container, Row, Col, Button, Alert, Badge, Card, CardImg, CardText, CardTitle } from 'reactstrap';

class SelectFacilityForm extends Component {
    render() {
        const facilityList = Array.from(this.props.Authenticate.idpList).map((facility) =>
            <div key={facility.idp_id}>
                <div className='facRow'>
                    <RadioField fieldName="facility" fieldValue={facility.idp_id} fieldLabel={ facility.idp_friendly_name } fieldID={'fac' + facility.idp_id} handleChange={this.props.onRadioUpdate} checked={facility.idp_id===this.props.Authenticate.idpList[0].idp_id}/> 

               </div>
            </div>
          )
        
        return (
            <Jumbotron className="slectFacForm">
                <Container>
                 
                <Row>
                    <Col>


                        <h1 className="text-left">Sites to Share Data for Research {/*<Badge color="success" pill>Step 1/4</Badge>*/}</h1>


                        <hr className="my-2" />
                        
                        <p>Please select a health system where you want to share your health data for research.  You will then be prompted to login to the patient portal at the selected health system and be asked to allow iAgree one-time access to demographic information stored there. This will only be used once to verify your identity by sending a passcode.

                        <br /><br />The next few screens you will see are maintained by each health system. Therefore, iAgree cannot control all the language you will encounter from your health system. Please keep in mind iAgree is requesting one-time access to verify your identity.

                        <br /><br />A separate form will allow you to specify what type of health data you want to share for research studies.</p>

                        {facilityList}
                        

                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.props.err &&<Alert color="danger">{this.props.err}</Alert>}
                         {this.props.error &&<Alert color="danger">{this.props.error}</Alert>} 
                        <Button id="backBtn" size="lg" color="secondary" onClick={() => {this.props.handleBack('back')}}><i className="fa fa-angle-double-left" />{" "}Back </Button>

                        <Button color="primary" size="lg" id="facilitySelect" onClick={this.props.handleOauth2}>Continue{" "}<i className="fa fa-angle-double-right" /></Button> 

                        {/*<Button color="primary" size="lg" id="facilitySelect" onClick={this.props.handleContinue}>Continue{" "}<i className="fa fa-angle-double-right" /></Button> */}
                    </Col>
                </Row>
                </Container>
            </Jumbotron>

        );
    }

}


export default SelectFacilityForm;
