import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Jumbotron, Container, Col, Row, Button, Alert } from 'reactstrap';

import { SetAuthSuccess } from '../actions/Authenticate'
import { fetchFacilityMFAOptions, fetchAuthChallenge } from '../actions/AuthChallenge'
//import ProgressBar from '../components/nav/ProgressBar'
import FacilityMFAForm from '../components/FacilityMFAForm'
import '../components/global/Main.css';

/**
 * FacilityMFA (Screen 15) - Set or perform MFA
 */
class FacilityMFA extends Component {
  /**
   * Constructor for class
   * @param {object} props 
   * 
   */
  constructor(props) {
    super(props);
    /**
     * @property {string} selectedMethod        - selected method for MFA verification
     * @property {string} errorMessage          - error message string
     * @property {boolean} newMFA               - true if setting MFA for first time (default: false) unused at the moment
     * @property {boolean} validateMFA          - true if need to display secondary confirmation screen for user input of confirmation code
     * @property {array} mfaOptions             - array holds all the MFA options available for logged in user's account
     * @property {string} userID                - username used to process MFA
     * @property {string} passcode              - verification code entered by user when requested by system
     * @property {string} device                - device ID of logged-in user's registered device
     * @property {string} stepComplete          - set to value of next screen
     */
    this.state = {
      newUser: false,
      selectedMethod: '',
      errorMessage: '',
      newMFA: false,
      validateMFA: false,
      mfaOptions: [],
      userID: '',
      passCode: '',
      device: '',
      loadMsg: '',
      stepComplete: ''
    }

    this.handleSelection = this.handleSelection.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.checkValidation = this.checkValidation.bind(this)
    this.handleMFACodeChange = this.handleMFACodeChange.bind(this)
    this.validateMFA = this.validateMFA.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.resetMFA = this.resetMFA.bind(this)
  }

  /**
   * First step, check MFA options to see 1) if user is enrolled, and 2) if enrolled, which factor type to use
   */

  componentWillMount = () => {
    const { location, Authenticate } = this.props
    if (location.pathname === "/ProviderIDVerification") {
      this.setState({ newUser: true })
    }
    if (Authenticate.provider !== undefined) {
      this.setState({ userID: Authenticate.provider.user.sam_account_name })
    }

  }

  /**
   * After first mounting of page, store the userid of logged in user in local state
   */
  componentDidMount = () => {
    const { dispatch } = this.props
    if (this.state.userID !== '') {
      dispatch(fetchFacilityMFAOptions({
        //user: this.state.userID
      }))
    }
  }

  /*
   * Formats capabilities array based on type
   */
  formatResponse = (response) => {
    if (response.type === 'phone') {
      if ('capabilities' in response) {
        return response.capabilities
      }
      else {
        return [response.type]
      }
    }
    else if (response.type === 'token') {
      return [response.type]
    }
    return []
  }

  /*
   * Display phone number if device is not a hard token
   */
  formatNumber = (response) => {
    if (response.type === 'phone') {
      if ('number' in response) {
        return response.number
      }
      else {
        return response.device
      }
    }
    else if (response.type === 'token') {
      if ('name' in response) {
        return 'Hard Token (' + response.name + ')'
      }
      else {
        return 'Hard Token'
      }
    }
    return ''
  }

  /**
   * Set the local state to match what fetchMFAOptions returned
   */
  componentDidUpdate = () => {
    const { AuthChallenge } = this.props
    if (AuthChallenge.mfaOptions !== undefined && this.state.mfaOptions.length === 0) {
      const result = AuthChallenge.mfaOptions.result

      if (result === 'deny' || this.props.AuthChallenge.isErr) {
        this.setState({ errorMessage: AuthChallenge.mfaOptions.status_msg })
      } else if (result === 'auth') {
        let capabilitiesArr = []
        let devicesArr = []
        let numbersArr = []

        for (var i = 0; i < AuthChallenge.mfaOptions.devices.length; i++) {
          capabilitiesArr.push(this.formatResponse(AuthChallenge.mfaOptions.devices[i]))
          devicesArr.push(AuthChallenge.mfaOptions.devices[i].device)
          numbersArr.push(this.formatNumber(AuthChallenge.mfaOptions.devices[i]))
        }

        let mfaArr = []
        for (var j = 0; j < capabilitiesArr.length; j++) {
          let mfaOptions = []
          capabilitiesArr[j].forEach(function (val, i) {
            switch (val) {
              case 'push':
                mfaOptions.push({
                  key: i,
                  mfaFieldValue: 'push',
                  mfaFieldLabel: 'Duo Push (notification sent to your phone via the installed Duo Mobile app)',
                })
                break
              case 'sms':
                mfaOptions.push({
                  key: i,
                  mfaFieldValue: 'sms',
                  mfaFieldLabel: 'SMS (code sent to your registered mobile phone as a text message)',
                })
                break
              case 'phone':
                mfaOptions.push({
                  key: i,
                  mfaFieldValue: 'call',
                  mfaFieldLabel: 'Phone Call (automated voice call)',
                })
                break
              case 'mobile_otp':
                mfaOptions.push({
                  key: i,
                  mfaFieldValue: 'passcode',
                  mfaFieldLabel: 'Duo One-Time Passcode (generate passcode via installed Duo Mobile app)',
                })
                break
              case 'token':
                mfaOptions.push({
                  key: i,
                  mfaFieldValue: 'passcode',
                  mfaFieldLabel: 'Duo One-Time Passcode (Hard Token)',
                })
                break
              default:
                break
            }
          })
        mfaArr.push({'capabilities': mfaOptions, 'device': devicesArr[j], 'number': numbersArr[j]})
        //mfaArr.push({'capabilities': mfaOptions})
        //mfaArr.push(mfaOptions)
        }
        this.setState({
          errorMessage: '',
          mfaOptions: mfaArr,
          device: '',
        })
      }
    }
    if (AuthChallenge.authData) {
      this.checkValidation(AuthChallenge)
    }
  }

  /**
   * Check if we received a validation success message, and if so update the local store so we can move on
   */
  checkValidation = (AuthChallenge) => {
    const { dispatch } = this.props
    if (AuthChallenge.authData.user_auth_factor_challenge_status.success === 'true') {
      if (!this.state.newUser) {
        dispatch(SetAuthSuccess({
          accountType: 'provider',
          newUser: false,
          ProcessToken: true
        }))
      }
      this.setState({ stepComplete: this.state.newUser ? '/ProviderEmail' : '/AccountHome' })
    }
  }

  /**
   * store the selected radio button in the local state
   */
  handleSelection = param => e => {
    this.setState({ selectedMethod: e.target.value })
    this.setState({device: param})
  }

  /** 
   * Update local state with MFA as it is entered on screen.
   */
  handleMFACodeChange = e => {
    //console.log(e.target)
    this.setState({ 'passCode': e.target.value })
  }

  /**
   * Fetch the initial auth challenge
   */
  handleSubmit = () => {
    const { dispatch } = this.props
    const { selectedMethod, device } = this.state
    if (selectedMethod === '') {
      this.setState({ errorMessage: 'Please select a method before continuing.' })
    } else {
      this.setState({ errorMessage: '' })
      switch (selectedMethod) {
        case 'push':
          this.setState({ loadMsg: 'Please check your registered mobile device for notification' })
          break
        case 'sms':
          this.setState({ loadMsg: 'Sending text message to your device' })
          break
        case 'call':
          this.setState({ loadMsg: 'Calling your registered phone number to complete verification' })
          break
        case 'mobile_otp':
          this.setState({ loadMsg: 'Please check the Duo app on your mobile device to get verification code' })
          break
        default:
          this.setState({ loadMsg: '' })
          break
      }

      if (selectedMethod === 'sms' || selectedMethod === 'passcode') {
        this.setState({ validateMFA: true })
      }
      if (selectedMethod !== 'passcode') {
        const payload = {
          authFactorType: selectedMethod,
          mfaType: 'facility',
          deviceId: device,
        }
        dispatch(fetchAuthChallenge(payload))
      }
    }
  }

  /**
   * Validate supplied MFA code (valid for SMS and passcode)
   */
  validateMFA = (e) => {
    e.preventDefault();
    const { dispatch } = this.props
    const { selectedMethod, passCode, device } = this.state
    const payload = {
      authFactorType: selectedMethod === 'sms' ? 'passcode' : selectedMethod,
      mfaType: 'facility',
      passCode: passCode,
      deviceId: device
    }
    if (selectedMethod === 'passcode') {
      dispatch(fetchAuthChallenge(payload))
    } else {
      this.setState({ loadMsg: 'Validating verification code...' })
      dispatch(fetchAuthChallenge(payload))
    }
  }


  /**
   * Close the modal window handling MFA verification
   */
  closeModal = () => {
    const { inProgress } = this.state
    let resetOption = {
      phoneOptions: {},
      inProgress: ''
    }
    resetOption.phoneOptions[inProgress] = false
    this.setState(resetOption)
  }

  /**
 * reset MFA process
 */
  resetMFA = (e) => {
    e.preventDefault();
    this.setState({
      errorMessage: '',
      validateMFA: false,
      passCode: '',
      loadMsg: '',
      stepComplete: ''
    })
    this.closeModal()
  }


  render() {
    //const hasKey = (obj, key) => key in obj;
    const err = this.state.errorMessage !== '' ? this.state.errorMessage : ''
    const mfaOptionsResult = this.props.AuthChallenge.mfaOptions !== undefined ? this.props.AuthChallenge.mfaOptions.result : ''
    //const navPath = this.state.newUser ? 'newProvider' : 'existingProviderLogin'
    return (
      <div className="facilityMFA">
        {this.props.Authenticate.provider === undefined &&
          <Redirect push to="/" />
        }
        {this.state.stepComplete !== '' &&
          <Redirect push to={this.state.stepComplete} />
        }

        {/* <ProgressBar navPath={navPath} /> */}

        {mfaOptionsResult === 'auth' || this.props.AuthChallenge.isFetching === true ? (
          <FacilityMFAForm
            formState={this.state}
            errorMessage={err}
            AuthChallenge={this.props.AuthChallenge}
            Authenticate={this.props.Authenticate}
            handleSelection={this.handleSelection}
            closeModal={this.closeModal}
            handlePhoneInput={this.handlePhoneInput}
            validateMFA={this.validateMFA}
            onMFACodeChange={this.handleMFACodeChange}
            processSelection={this.handleSubmit}
            resetMFA={this.resetMFA}
            facility={this.props.Authenticate.provider.idp.idp_friendly_name}
          />
        ) : (
            <Jumbotron>
              <Container>
                <Row>
                  <Col>
                    <span>
                      <h1 style={{ color: '#f0ad4e' }}>Cannot Perform Second Factor Authentication</h1>
                      <hr className="my-2" />
                    </span>

                    <Alert color="danger">{err}</Alert>
                    <Button color="warning" size="lg" id="mfaSubmit" type="submit" onClick={() => this.setState({ stepComplete: '/' })}>Start Over »</Button>

                  </Col>
                </Row>
              </Container>
            </Jumbotron>

          )}
      </div>
    )
  }
}


const mapStateToProps = (state, ownProps) => ({
  Authenticate: state.Authenticate,
  AuthChallenge: state.AuthChallenge
})



export default connect(mapStateToProps)(FacilityMFA)