import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import Loader from './common/Loader';
import RadioField from '../components/common/RadioField'
import MFAForm from '../components/MFAForm'
import { Jumbotron, Container, Col, Row, Button, Alert, Modal } from 'reactstrap';

const customStyles = {
    content: {
        top: '50%',
        left: '47%',
        // right: 'auto',
        // bottom: 'auto',
        // marginRight: '-50%',
        //transform: 'translate(-50%, -50%)'
    }
};

/**
 * Facility MFA Form (15B)
 */
class PatientMFAForm extends Component {
    render() {
        //const { isFetching } = this.props.AuthChallenge
        const { formState, Authenticate } = this.props

        if (Authenticate.sfidentity.user_single_file_identity.sf_mfa_status_value === 'Active') {
            if (Authenticate.sfidentity.user_single_file_identity.sf_mfa_type_value === 'TWILIO') {
                var options = Authenticate.sfidentity.user_single_file_identity.options
            } else {
                var options = ['Google Auth Token']
            }
            var mfaOptions = options.map((item) => {
                return (
                    <RadioField key={item} fieldName='twilio' fieldValue={item} fieldLabel={item} fieldID={item} handleChange={this.props.handleSelection(item)} checked={item===this.props.formState.selectedMethod}/> 
                )
            });
        } 
        // var devices = this.props.formState.mfaOptions.map((device, index) => {
        //     var d = device.device
        //     var options = device.capabilities.map((item) => {
        //         return (
        //             <RadioField key={item.key} fieldName='duo' fieldValue={item.mfaFieldValue} fieldLabel={item.mfaFieldLabel} fieldID={item.mfaFieldValue} handleChange={this.props.handleSelection(d)} />
        //         )
        //     });

        //     return (
        //         <div>
        //             <br></br>
        //             <p key={index}>{device.number}</p>
        //             {options}
        //         </div>
        //     )
        // });
        
        return (
            <Jumbotron>
                <Container>
                    <Row>
                        <Col>
                            <span className="authSecurity">
                                <h1 className="text-center">Security</h1>
                                <hr className="my-2" />
                                <h4>To verify your identity, we need to send you a passcode to {Authenticate.sfidentity.user_single_file_identity.phoneNumber}</h4>
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/*<p>{Authenticate.sfidentity.user_single_file_identity.sf_mfa_status_value}: {Authenticate.sfidentity.user_single_file_identity.phoneNumber}</p>*/}
                            <p>How would you like to receive it?</p>
                            {mfaOptions}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            {this.props.loadMsg !== '' && <Alert color="default">{this.props.loadMsg}</Alert>}
                        </Col>
                        <Col>
                            {this.props.errorMessage !== '' && <Alert color="danger">{this.props.errorMessage}</Alert>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>The code expires 10 minutes after you request it.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="primary" size="lg" id="mfaSubmit" type="submit" onClick={this.props.processSelection}>Continue »</Button>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <p>If you do not receive a text message or phone call within 5 minutes, or do not have access to {Authenticate.sfidentity.user_single_file_identity.phoneNumber} please email <a href="mailto:info@iagree2share.org?subject=iAgree to Share Question">info@iagree2share.org</a> to update your active phone number.</p>
                                
                            <Modal isOpen={formState.validateMFA === true} autoFocus={false} onRequestClose={this.props.closeModal} style={customStyles} contentLabel='Verify Selection'>
                                <div>
                                    <MFAForm formState={formState} resendMFA={this.props.resendMFA} AuthChallenge={this.props.AuthChallenge} errorMessage={this.props.errorMessage} onMFACodeChange={this.props.onMFACodeChange} validateMFA={this.props.validateMFA} />
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        );
    }

}

PatientMFAForm.PropTypes = {
    AuthChallenge: PropTypes.object,
    errorMessage: PropTypes.string,
    formState: PropTypes.object.isRequired,
    handleSelection: PropTypes.func.isRequired,
    processSelection: PropTypes.func.isRequired,
    onMFACodeChange: PropTypes.func.isRequired,
    validateMFA: PropTypes.func.isRequired,
    resendMFA: PropTypes.func.isRequired
}

export default PatientMFAForm;
