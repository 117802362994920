import React, { Component } from 'react'
//import { withRouter } from 'react-router-dom'
//import { connect } from 'react-redux'
//import { Redirect } from 'react-router'
import { AvForm, AvField } from 'availity-reactstrap-validation'
//import { zxcvbn } from 'zxcvbn'
var zxcvbn = require('zxcvbn');
import PasswordStrengthMeter from './PasswordStrengthMeter';
import { Jumbotron, Container, Row, Col, Button, Alert } from 'reactstrap'
/**
 * ID Verification - Password reset (#21.D) - 
 * Fields to enter new password and matching
 */
class NewPassword extends Component {
    /*constructor(props) {
        super(props);
    }*/

    state={
        password: '',
        suggestions: [],
        warning: '',
    }

    onPassChange = (e) => {
        const password = e.target.value
        const evaluation = zxcvbn(password)
        console.log(evaluation)
        this.setState({
            password,
            score: evaluation.score,
            suggestions: evaluation.feedback.suggestions,
            warning: evaluation.feedback.warning,
        })
    }

    render() {
        const {score, suggestions, warning, password} = this.state
        return (
            <Jumbotron className="NewPassword">
                <Container>
                    <Row>
                        <Col>
                            <h1 className="text-center">Reset password</h1>
                            <hr className="my-2" />
                            <p>Update Single-FILE Managed Password</p>

                            <AvForm autoComplete="off">
                                {/* <AvField name="newpassword" label="New password" type="text" errorMessage="Invalid password" validate={{
                                        required: {value: true},
                                        minLength: {value: 8, errorMessage: 'Passwords must be at least 8 characters long'}
                                      }} /> */}
                                <AvField name="newpassword" label="New password" type="text" value={this.state.password} onChange={this.onPassChange} /> 
                                {warning && <Alert color="danger">{warning}</Alert>}       
                                {/*<ul>
                                    {suggestions.map((s, index) =>
                                        <li key={index}>{s}</li>
                                    )}
                                </ul>
                                {score*/}
                                <PasswordStrengthMeter password={password} />
                                <AvField name="newpassword2" label="Retype password" errorMessage="Not matching password" type="text" validate={{match:{value:'newpassword'}}} />
                                <Button color="primary" size="lg">Submit</Button>
                            </AvForm>
                        </Col>
                    </Row>     
                </Container>
            </Jumbotron>
        );
    }
}

export default NewPassword;
