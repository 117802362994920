import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Col } from 'reactstrap';

class RadioField extends Component {

    render() {
        const { fieldName, fieldValue, fieldLabel, fieldID, handleChange, checked } = this.props
        const fLabel = fieldLabel !== 'SMS'? (fieldLabel !== 'Call'? fieldLabel:'Phone call') : 'Text message'

        return (
            <Col>
                <Input type="radio" name={fieldName} value={fieldValue} id={fieldID} onChange={handleChange} defaultChecked={checked} /> <label htmlFor={fieldID}>{fLabel}</label>
            </Col>
        );
    }

}

RadioField.PropTypes = {
    fieldName: PropTypes.string.isRequired,
    fieldValue: PropTypes.string,
    fieldLabel: PropTypes.string,
    checked: PropTypes.string,
    fieldID: PropTypes.string.isRequired,
}

export default RadioField
