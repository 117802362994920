import React, { Component } from 'react'
import { Row, Col, Button } from 'reactstrap';

/**
 * Patient Main Menu (part of #20)
 */
class PatientHelpContent extends Component {
    render() {
        return (
            <Row>
                <Col>
                    <h1 color="primary">iAgree</h1>
                    <br />
                    <hr className="my-2" />
                    <p>Documentation</p>
                    <p>&lt;User Documentation Link TBD&gt;</p>

                    <br />
                    <hr className="my-0" />
                    <p>For additional assistance, please email <Button outline color="primary" size="sm" href="mailto:info@iagree2share.org">info@iagree2share.org</Button></p>
                </Col>
            </Row>
        )
    }

}


export default PatientHelpContent

