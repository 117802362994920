import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Container, Button, Modal } from 'reactstrap';

import { checkRelyingParties } from '../../actions/Authenticate'
import './Main.css';

class SessionMonitor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exp: null,
            timer: null,
            timeDiff: null,
            showModal: false
        };

        this.tick = this.tick.bind(this)
        this.refreshToken = this.refreshToken.bind(this)
        this.doLogout = this.doLogout.bind(this)
        this.closeModal = this.closeModal.bind(this)


    }
    componentWillUnmount = () => {
        clearInterval(this.state.timer)
    }

    componentDidUpdate = () => {
        const { Authenticate } = this.props
        if (Authenticate.expiry !== undefined) {
            if (Authenticate.expiry.exp !== this.state.exp) {
                const { exp } = Authenticate.expiry
                clearInterval(this.state.timer)
                let timer = setInterval(this.tick, 1000)
                const now = parseInt(Date.now() / 1000)
                this.setState({
                    exp: exp,
                    timer: timer,
                    timeDiff: exp - now
                })
            }
        }

    }

    tick = () => {
        const now = parseInt(Date.now() / 1000)
        const showModal = this.state.exp - now <= 60 ? true : false
        this.setState({
            showModal: showModal,
            timeDiff: this.state.exp - now
        })
    }

    refreshToken = () => {
        const {dispatch} = this.props
        this.setState({
            exp: null,
            timer: null,
            timeDiff: null,
            showModal: false
        })
       dispatch(checkRelyingParties({
          }))
    }

    doLogout = () => {
        clearInterval(this.state.timer)
        this.setState({
            exp: null,
            timer: null,
            timeDiff: 0,
            showModal: false
        })
    }

    closeModal = () => {
        this.setState({ showModal: false })
    }

    render() {
        return (
            <div>
                {this.state.timeDiff !== 0 && 
                <Modal isOpen={this.state.showModal} onRequestClose={this.closeModal} contentLabel='Timeout Warning'>
                    <Container style={{ margin: "10px" }}>
                        <p><b>You will be logged out in less than a minute due to inactivity.</b></p>
                        <p>To continue your session, please click Continue below, or Logout if you are finished.</p>
                        <Button color='primary' size='lg' type='submit' onClick={this.doLogout}>Logout</Button>
                        <Button color='warning' className="float-right" style={{ marginRight: "18px" }} size='lg' type='submit' onClick={this.refreshToken}>Continue</Button>
                    </Container>
                </Modal>
                }
                {this.state.timeDiff === 0 &&
                    <Redirect push to="/LoggedOut" />}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(SessionMonitor)
