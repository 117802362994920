import React, { Component } from 'react';
import RadioField from '../components/common/RadioFieldPIV'
import { Jumbotron, Container, Row, Col, Button, Alert, Badge, Card, CardImg, CardText, CardTitle } from 'reactstrap';

class SelectFacilityForm3 extends Component { 
    render() {
        const facilityList = Array.from(this.props.availRp).map((facility) =>
            <div key={facility.idp_id}>
                <div className='facRow'>
                    <RadioField fieldName="facility" fieldValue={facility.facility_id} fieldLabel={ facility.facility_name } fieldID={'fac' + facility.facility_id} handleChange={this.props.onRadioUpdate} checked={facility.idp_id===this.props.availRp[0].idp_id}/> 
                </div>
            </div>
          )
        return (
            <Jumbotron className="slectFacForm">
                <Container>
                <Row>
                    <Col>
                        <h3 className="text-left text-danger">Unable to link your iAgree account to the Health System you selected.</h3>
                        <hr className="my-2" />
                        
                        <p>Please select a health system where you want to share your health data for research.  You will then be prompted to login to the patient portal at the selected health system and be asked to allow iAgree one-time access to demographic information stored there. This will only be used once to verify your identity by sending a passcode.

                        <br /><br />The next few screens you will see are maintained by each health system. Therefore, iAgree cannot control all the language you will encounter from your health system. Please keep in mind iAgree is requesting one-time access to verify your identity. 

                        <br /><br />A separate form will allow you to specify what type of health data you want to share for research studies.</p>
                    
                        {/*<div className="facSearch"><input placeholder="Filter facility by name" className='searchBox' /></div>*/}

                        {facilityList}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.props.err &&<Alert color="danger">{this.props.err}</Alert>}
                        <Button color="info" size="lg"  onClick={() => {this.props.handleBack('back')}}><i className="" />{" "}Maybe later</Button>

                        <Button color="primary" size="lg" id="facilitySelect" onClick={this.props.handleOauth2}>Yes{" "}<i className="" /></Button> 

                        {/*<Button color="primary" size="lg" id="facilitySelect" onClick={this.props.handleContinue}>Continue{" "}<i className="fa fa-angle-double-right" /></Button> */}
                    </Col>
                </Row>
                </Container>
            </Jumbotron>

        );
    }
}

export default SelectFacilityForm3;
