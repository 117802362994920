//#5
import React, { Component } from 'react';
import Loader from './common/Loader';
import InputField from '../components/common/InputField'
import { Container, Col, Row, Button, Form, Alert } from 'reactstrap';
import { SocialIcon } from 'react-social-icons';

class ProviderLoginRegForm extends Component {

    render() {
        const { errorMessage, facility } = this.props
        const { isFetching } = this.props.Authenticate
        return (
                <Container>
                    <Row>
                        <Col>
                            <Col>
                            {isFetching && <Loader msg='Logging you in...' /> }
                            <br />
                            <Form onSubmit={this.props.doLogin}>
                                <InputField outline fieldName='email' fieldId='email' fieldLabel='Facility email address:' fieldPlaceholder='Email' fieldClassname='fa fa-envelope-o fa-fw' handleChange={this.props.handleChange} />
                                <br />
                                <InputField fieldName='password' fieldId='password' fieldType='password' fieldPlaceholder='Password' fieldLabel='Facility password:' fieldClassname='fa fa-lock fa-fw' handleChange={this.props.handleChange} />
                                <br />
                                <InputField fieldName='password' fieldId='password' fieldType='password' fieldPlaceholder='Re-Type Password' fieldLabel='Facility password:' fieldClassname='fa fa-lock fa-fw' handleChange={this.props.handleChange} />
                                <br /><br />

                                {errorMessage && <Alert color="danger">{errorMessage}</Alert> }
                                <Button color="primary" size="lg" type='submit' onClick={this.props.doLogin} block><i className="fa fa-sign-in fa-fw"></i>Sing Up</Button>

                                <br /><br />
                                <Row className="text-center">
                                    <Col sm={{ size: 8, push: 2 }}>
                                      <p color="green">OR CONTINUE SIGN IN WITH</p>
                                    </Col>
                                </Row>

                                <Row className="text-center">
                                    <Col>
                                        <SocialIcon network="google" />{" "}<SocialIcon network="facebook" />{" "}<SocialIcon network="twitter" />{" "}<SocialIcon network="linkedin" />                                    
                                    </Col>
                                </Row>
                                <br /><br/>

                                
                                <br />
                                <p className="text-right">Already have an account? <a href="#">Log in</a></p>
                            </Form>

                            </Col>
                        </Col>
                    </Row>
                </Container>
        );
    }
}

export default ProviderLoginRegForm;
