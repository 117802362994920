//#17
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
//import ProgressBar from '../components/nav/ProgressBar'
import ProviderHelpContent from '../components/ProviderHelpContent'
import PatientHelpContent from '../components/PatientHelpContent'
import { Jumbotron, Container, Row, Col } from 'reactstrap';

/**
 * Account Home - Screen 17
 */
class Help extends Component {
  /**
   * Constructor for class
   * @param {object} props 
   * 
   */
  constructor(props) {
    super(props)
    this.state = {
      accountType: ''
    }


  }

  /**
   * determine what kind of user
   */
  componentWillMount = () => {
    const { Authenticate } = this.props
    if (Authenticate.AuthSuccess !== undefined) {
      this.setState({ accountType: Authenticate.AuthSuccess.accountType })
    } else {
      this.setState({ loggedOut: true })
    }
  }


  render() {
    //const navPath = (this.state.accountType === 'provider') ? 'providerAccount' : 'patientAccount'
    return (
      <div className="Help">
        {/* <ProgressBar navPath={navPath} /> */}
        <Jumbotron>
          <Container>
            <Row>
              <Col>
                { this.state.loggedOut === true && <Redirect push to="/" /> }
                { this.state.accountType === 'provider' ? ( <ProviderHelpContent facilities={this.props.Authenticate.relying_parties} /> ) : ( <PatientHelpContent /> ) }
              </Col>
            </Row>
          </Container>  
        </Jumbotron>
      </div>  
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(Help)