import * as types from '../actions/ActionTypes'

export default function doSSO(state = [], action) {
    switch (action.type) {
        case types.CREATE_SSO_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                createSSO: action.payload
            })
        case types.CREATE_SSO_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                createSSO: action.payload.data
            })
        case types.CREATE_SSO_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                createSSO: action.payload
            })
        default:
            return state
    }
}