//#17
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { facilityLogin, checkRelyingParties, clearRemoveRP, clearProviderVerify, clearRelyingParties, doMyChartVerification, getUserAvailableRelyingParties, removeRelyingParty, doFacilityAccountVerification, createSingleFileRelyingParty, createNewFacilityRecord, fetchIDPList, addPatientFacility } from '../actions/Authenticate'
import { fetchFacilityMFAOptions, fetchAuthChallenge, clearMFAOptions, clearMFACode } from '../actions/AuthChallenge'
//import ProgressBar from '../components/nav/ProgressBar'
import ProviderAddFacilityForm from '../components/ProviderAddFacilityForm'
import PatientAddFacilityForm from '../components/PatientAddFacilityForm'
import { Jumbotron, Container } from 'reactstrap';
//import { OTP_REQUEST } from '../actions/ActionTypes';

/**
 * Add/Remove Facility - Screen 19
 */
class AddFacility extends Component {
    /**
     * Constructor for class
     * @param {object} props 
     * 
     */
    constructor(props) {
        super(props)

        this.initialState = {
            accountType: '',
            addFacilityStep: '',
            removeConfirm: false,
            addConfirm: false,
            facilityChangeID: '',
            facilityChangeName: '',
            email: '',
            password: '',
            errorMessage: '',
            mfaOptions: [],
            selectedMethod: '',
            userID: '',
            passCode: '',
            device: '',
            loadMsg: '',
            stepComplete: '',
            validateMFACode: false,
            finishProcess: false

        }
        this.state = this.initialState

        this.setFacilitiesLists = this.setFacilitiesLists.bind(this)
        this.removeClick = this.removeClick.bind(this)
        this.addClick = this.addClick.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.processRemove = this.processRemove.bind(this)
        this.doLogin = this.doLogin.bind(this)
        this.handleLoginChange = this.handleLoginChange.bind(this)
        this.doMFA = this.doMFA.bind(this)
        this.checkMFAValidation = this.checkMFAValidation.bind(this)
        this.handleMFACodeChange = this.handleMFACodeChange.bind(this)
        this.handleMFASelection = this.handleMFASelection.bind(this)
        this.resetMFA = this.resetMFA.bind(this)
        this.cancelAdd = this.cancelAdd.bind(this)
    }

    /*
     * Formats capabilities array based on type
     */
    formatResponse = (response) => {
    if (response.type === 'phone') {
      if ('capabilities' in response) {
        return response.capabilities
      }
      else {
        return [response.type]
      }
    }
    else if (response.type === 'token') {
      return [response.type]
    }
    return []
    }

    /*
     * Display phone number if device is not a hard token
     */
    formatNumber = (response) => {
    if (response.type === 'phone') {
      if ('number' in response) {
        return response.number
      }
      else {
        return response.device
      }
    }
    else if (response.type === 'token') {
      if ('name' in response) {
        return 'Hard Token (' + response.name + ')'
      }
      else {
        return 'Hard Token'
      }
    }
    return ''
    }

    componentWillMount = () => {
        const { Authenticate, dispatch, location } = this.props
        dispatch(clearMFAOptions())
        dispatch(clearMFACode())
        dispatch(clearRemoveRP())
        dispatch(clearProviderVerify())
        dispatch(clearRelyingParties())
   if (location.pathname === '/ProcessFacility') {
            this.setState({ finishProcess: true })
        } else {
            if (Authenticate.sfidentity !== undefined) {
                const { Authenticate, dispatch } = this.props
                dispatch(checkRelyingParties({
                }))
                this.setState({ accountType: Authenticate.AuthSuccess.accountType })
                this.setFacilitiesLists()
            } else {
                this.setState({ loggedOut: true })
            }
        }
    }


    componentWillReceiveProps = (nextProps) => {
        const { dispatch, Authenticate, AuthChallenge } = nextProps
        let { isErr } = Authenticate

        if (this.state.stepComplete === 'true') {
            const { dispatch, Authenticate } = this.props
            dispatch(clearMFAOptions())
            dispatch(clearMFACode())
            dispatch(clearRemoveRP())
            dispatch(clearProviderVerify())
            dispatch(clearRelyingParties())
            dispatch(checkRelyingParties({
            }))
            this.setState(this.initialState)
            this.setState({ accountType: Authenticate.AuthSuccess.accountType })
            this.setFacilitiesLists()
        }

        if (Authenticate.providerVerify !== undefined && AuthChallenge.mfaOptions === undefined && this.state.addFacilityStep !== 'mfa') {

            switch (isErr) {
                case true:
                    let errMsg = ''
                    switch (Authenticate.providerVerify.status) {
                        case "0":
                            errMsg = 'Unknown Error'
                            break
                        case "2":
                            errMsg = 'Username/Password Incorrect'
                            break
                        case "3":
                            errMsg = 'Password Expired'
                            break
                        case "4":
                            errMsg = 'Account Deactivated'
                            break
                        case "5":
                            errMsg = 'Username/Password Incorrect'
                            break
                        case "6":
                            errMsg = 'Account Expired'
                            break
                        case "12":
                            errMsg = 'Maximum Login Attempts Exceeded'
                            break
                        default:
                            errMsg = 'Username/Password Incorrect'
                            break
                    }
                    this.setState({ errorMessage: errMsg })

                case false:
                    if (Authenticate.providerVerify.user !== undefined) {
                        this.setState({ addFacilityStep: 'mfa' })
                        dispatch(fetchFacilityMFAOptions({
                            user: Authenticate.providerVerify.user.sam_account_name,
                            idp: Authenticate.providerVerify.idp_info,
                            verify: true
                        }))
                    }
                    break
                default:
                    break
            }
        }

        if (Authenticate.myChartVerify !== undefined && AuthChallenge.mfaOptions === undefined && this.state.addFacilityStep !== 'mfa') {
            let myChartErr = ''
            let myChartSuccess = false
            const myChartStatus = Authenticate.myChartVerify.authenticateWebAccountResult.status
            if (myChartStatus === '1') {
                myChartSuccess = true
            } else if (myChartStatus === '12') {
                myChartErr = 'Maximum Login Attempts Exceeded'
            } else {
                myChartErr = 'Incorrect Username and/or Password'
            }

            if (myChartSuccess === true) {
                this.setState({addFacilityStep: 'mfa'})
                dispatch(fetchFacilityMFAOptions({
                    user: Authenticate.providerVerify.user.sam_account_name,
                    verify: true
                }))
    }
        }
    }




    /**
     * Set the local state to match what fetchMFAOptions returned
     */
    componentDidUpdate = () => {
        const { AuthChallenge, Authenticate } = this.props

        if (AuthChallenge.mfaOptions !== undefined && this.state.mfaOptions.length === 0) {
            const result = AuthChallenge.mfaOptions.result
            if (result === 'deny' || this.props.AuthChallenge.isErr) {
                this.setState({ errorMessage: AuthChallenge.mfaOptions.status_msg })
            } else if (result === 'auth') {
                let capabilitiesArr = []
                let devicesArr = []
                let numbersArr = []

                for (var i = 0; i < AuthChallenge.mfaOptions.devices.length; i++) {
                  capabilitiesArr.push(this.formatResponse(AuthChallenge.mfaOptions.devices[i]))
                  devicesArr.push(AuthChallenge.mfaOptions.devices[i].device)
                  numbersArr.push(this.formatNumber(AuthChallenge.mfaOptions.devices[i]))
                }

                let mfaArr = []

                for (var j = 0; j < capabilitiesArr.length; j++) {
                    let mfaOptions = []
                    capabilitiesArr[j].forEach(function (val, i) {
                        switch (val) {
                            case 'push':
                                mfaOptions.push({
                                    key: i,
                                    mfaFieldValue: 'push',
                                    mfaFieldLabel: 'Duo Push (notification sent to your phone via the installed Duo app)',
                                })
                                break
                            case 'sms':
                                mfaOptions.push({
                                    key: i,
                                    mfaFieldValue: 'sms',
                                    mfaFieldLabel: 'SMS (code sent to your registered mobile phone as a text message)',
                                })
                                break
                            case 'phone':
                                mfaOptions.push({
                                    key: i,
                                    mfaFieldValue: 'call',
                                    mfaFieldLabel: 'Phone Call (code sent to your phone via automated voice call)',
                                })
                                break
                            case 'mobile_otp':
                                mfaOptions.push({
                                    key: i,
                                    mfaFieldValue: 'passcode',
                                    mfaFieldLabel: 'Duo One-Time Passcode (code sent to your phone via installed Duo app)',
                                })
                                break
                            default:
                                break
                        }

                    })
                    mfaArr.push({'capabilities': mfaOptions, 'device': devicesArr[j], 'number': numbersArr[j]})
                }
                this.setState({
                    userID: Authenticate.providerVerify.user.sam_account_name,
                    errorMessage: '',
                    mfaOptions: mfaArr,
                    //device: '',//AuthChallenge.mfaOptions.devices.device,
                    idp: Authenticate.providerVerify.idp_info
                })
            }
        }
        if (AuthChallenge.authData) {
            this.checkMFAValidation(AuthChallenge)
        }
        if (AuthChallenge.authData === undefined && AuthChallenge.isErr === true && AuthChallenge.errorMsg !== undefined && this.state.errorMessage !== AuthChallenge.errorMsg) {
            this.setState({
                errorMessage: AuthChallenge.errorMsg
            })
        }


    }

    /**
     * Check if we received a validation success message, and if so update the local store so we can move on
     */
    checkMFAValidation = (AuthChallenge) => {
        if (AuthChallenge.authData.user_auth_factor_challenge_status.success === 'true') {
            const { dispatch, Authenticate } = this.props
            if (AuthChallenge.isFetching === false && AuthChallenge.isErr === false && this.state.stepComplete !== 'true') {
                dispatch(createNewFacilityRecord({ //createSingleFileRelyingParty({
                    relying_party_info: Authenticate.availRp.find(x => x.idp_id === this.state.facilityChangeID).relying_party_info,
                    relying_party_access_info: Authenticate.providerVerify.user.user_principal_name,
                    sam: Authenticate.providerVerify.user.sam_account_name,
                    verify: true,
                    idpInfo: Authenticate.providerVerify.idp_info
                }))
                this.closeModal()
                this.setState({ stepComplete: 'true' })
            }
        }
    }

    // /**
    //  * store the selected radio button in the local state
    //  */
    // handleMFASelection = e => {
    //     this.setState({ selectedMethod: e.target.value })
    // }

    /**
    * store the selected radio button in the local state
    */
    handleMFASelection = param => e => {
        this.setState({ selectedMethod: e.target.value })
        this.setState({device: param})
    }

    /** 
     * Update local state with MFA as it is entered on screen.
     */
    handleMFACodeChange = e => {
        this.setState({ 'passCode': e.target.value })
    }
    /**
   * Validate supplied MFA code (valid for SMS and passcode)
   */
    validateMFA = (e) => {
        e.preventDefault()
        const { dispatch } = this.props
        const { selectedMethod, passCode, userID, device, idp } = this.state
        const payload = {
            authFactorType: selectedMethod === 'sms' ? 'passcode' : selectedMethod,
            mfaType: 'facility',
            passCode: passCode,
            userName: userID,
            deviceId: device,
            idp: idp
        }
        if (selectedMethod === 'passcode') {
            dispatch(fetchAuthChallenge(payload))
        } else {
            this.setState({ loadMsg: 'Validating verification code...' })
            dispatch(fetchAuthChallenge(payload))
        }
    }

    setFacilitiesLists = () => {
        const { dispatch } = this.props
        dispatch(getUserAvailableRelyingParties({ 
        }))

    }

    removeClick = (e, i) => {
        console.log("--removeClick--")
        this.setState({
            removeConfirm: true,
            facilityChangeID: e,
            facilityChangeName: i
        })
        
    }

    addClick = (e) => {
        const { dispatch, Authenticate } = this.props
        if (this.props.Authenticate.AuthSuccess.accountType === "provider") {
            this.setState({
                addConfirm: true,
                facilityChangeID: e,
                addFacilityStep: 'login'
            })
        }
        else {
             dispatch(fetchIDPList({ idpType: 'patient' })).then(
                res => {
                    const selectedFacility = this.props.Authenticate.idpList.find(x => x.idp_id === e)
                    //Add addFacility variable to selectedFacility object, this will keep the user logged in
                    selectedFacility.addFacility = "True"
                    dispatch(addPatientFacility(selectedFacility))
                    dispatch(facilityLogin(selectedFacility))

                    //dispatch(addPatientFacility(selectedFacility))
                    //this.props.history.push('/IDVerification')
                    //window.location.reload()
                },
                err => console.log('error')
            )
        }
    }

    cancelAdd = () => {
        const { dispatch } = this.props
        dispatch(clearMFAOptions())
        dispatch(clearMFACode())
        dispatch(clearProviderVerify())
        this.setState({
            removeConfirm: false,
            addConfirm: false,
            facilityChangeID: '',
            validateMFACode: false,
            errorMessage: ''
        })
    }

    closeModal = () => {
        this.setState({
            removeConfirm: false,
            addConfirm: false,
            facilityChangeID: '',
            validateMFACode: false,
            errorMessage: ''
        })
    }

    processRemove = () => {
        console.log("--processRemove--")

        const { dispatch, Authenticate } = this.props
        console.log(this.state.facilityChangeID)
        dispatch(removeRelyingParty({
            idp_id: this.state.facilityChangeID
        }))
        this.closeModal()
        this.setState({ stepComplete: 'true' })
    }


    /**
     * Process AD Authentication
     * @param {object} e    - login button
     */
    doLogin = e => {
        e.preventDefault();
        const { dispatch, Authenticate } = this.props
        if (Authenticate.AuthSuccess.accountType === 'provider') {
            dispatch(doFacilityAccountVerification({
                userName: this.state.email,
                password: this.state.password,
                idpId: this.state.facilityChangeID
            }))
        } else {
            dispatch(doMyChartVerification({
                WebAccountUsername: this.state.email,
                WebAccountPassword: this.state.password,
                idpId: Authenticate.facility.idp_id       
            }))
        }
    }

    doMFA = e => {
        e.preventDefault();
        const { dispatch } = this.props
        const { selectedMethod, userID, device, idp } = this.state
        if (selectedMethod === '') {
            this.setState({ errorMessage: 'Please select a method before continuing.' })
        } else {
            this.setState({ errorMessage: '' })
            switch (selectedMethod) {
                case 'push':
                    this.setState({ loadMsg: 'Please check your registered mobile device for notification' })
                    break
                case 'sms':
                    this.setState({ loadMsg: 'Sending SMS code to your device' })
                    break
                case 'call':
                    this.setState({ loadMsg: 'Calling your registered phone number to complete verification' })
                    break
                case 'mobile_otp':
                    this.setState({ loadMsg: 'Please check the Duo app on your mobile device to get verification code' })
                    break
                default:
                    this.setState({ loadMsg: '' })
                    break
            }

            if (selectedMethod === 'sms' || selectedMethod === 'passcode') {
                this.setState({ validateMFACode: true })
            }
            if (selectedMethod !== 'passcode') {
                const payload = {
                    authFactorType: selectedMethod,
                    mfaType: 'facility',
                    userName: userID,
                    deviceId: device,
                    idp: idp,
                    verify: true
                }
                dispatch(fetchAuthChallenge(payload))
            }
        }

    }

    /**
     * Update local state with username/password as they are entered
     * @param {object} e    - Form field being updated
     */
    handleLoginChange = e => {
        if (e.target.value !== '') {
            let payload = {}
            payload[e.target.name] = e.target.value
            this.setState(payload)
        }
    }

    resetMFA = (e) => {
        this.setState({ validateMFACode: false, errorMessage: '' })

    }

    render() {
        const { Authenticate } = this.props
        //const navPath = (this.state.accountType === 'provider') ? 'providerAccount' : 'patientAccount'
        //Replaced empty array with availble relying_parties
        const relyingParties = Authenticate.relying_parties !== undefined ? Authenticate.relying_parties : Authenticate.availRp 
        const availableRP = Authenticate.availRp !== undefined ? Authenticate.availRp : []
        return (
            <div className="AddFacility">
                {/* <ProgressBar navPath={navPath} /> */}
                <Jumbotron>
                    <Container>
                        {this.state.loggedOut === true &&
                            <Redirect push to="/" />
                        }

                        {this.props.Authenticate.AuthSuccess.accountType === "provider" && <h1 color="text-primary" className="addRemoveFacilites">Health Systems Linked To My iAgree Account</h1>}
                        {this.props.Authenticate.AuthSuccess.accountType === "provider" && 
                            <ProviderAddFacilityForm
                                relyingParties={relyingParties}
                                availableRP={availableRP}
                                addClick={this.addClick}
                                removeClick={this.removeClick}
                                addFacilityStep={this.state.addFacilityStep}
                                removeConfirm={this.state.removeConfirm}
                                addConfirm={this.state.addConfirm}
                                closeModal={this.closeModal}
                                processRemove={this.processRemove}
                                processAdd={this.processAdd}
                                Authenticate={this.props.Authenticate}
                                facilityChangeID={this.state.facilityChangeID}
                                handleLoginChange={this.handleLoginChange}
                                doLogin={this.doLogin}
                                doMFA={this.doMFA}
                                mfaOptions={this.state.mfaOptions}
                                handleMFASelection={this.handleMFASelection}
                                AuthChallenge={this.props.AuthChallenge}
                                loadMsg={this.state.loadMsg}
                                errorMessage={this.state.errorMessage}
                                validateMFA={this.validateMFA}
                                validateMFACode={this.state.validateMFACode}
                                resetMFA={this.resetMFA}
                                selectedMethod={this.state.selectedMethod}
                                onMFACodeChange={this.handleMFACodeChange}
                                cancelAdd={this.cancelAdd}
                            />
                        }

                        {this.props.Authenticate.AuthSuccess.accountType === "patient" && <h1 color="text-primary" className="addRemoveFacilites">Health Systems Linked To My iAgree Account</h1>}
                        {this.props.Authenticate.AuthSuccess.accountType === "patient" && 
                             <PatientAddFacilityForm
                                relyingParties={relyingParties}
                                availableRP={availableRP}
                                addClick={this.addClick}
                                removeClick={this.removeClick}
                                facilityChangeName={this.state.facilityChangeName}
                                addFacilityStep={this.state.addFacilityStep}
                                removeConfirm={this.state.removeConfirm}
                                addConfirm={this.state.addConfirm}
                                closeModal={this.closeModal}
                                processRemove={this.processRemove}
                                processAdd={this.processAdd}
                                Authenticate={this.props.Authenticate}
                                facilityChangeID={this.state.facilityChangeID}
                                handleLoginChange={this.handleLoginChange}
                                doLogin={this.doLogin}
                                doMFA={this.doMFA}
                                mfaOptions={this.state.mfaOptions}
                                handleMFASelection={this.handleMFASelection}
                                AuthChallenge={this.props.AuthChallenge}
                                loadMsg={this.state.loadMsg}
                                errorMessage={this.state.errorMessage}
                                validateMFA={this.validateMFA}
                                validateMFACode={this.state.validateMFACode}
                                resetMFA={this.resetMFA}
                                selectedMethod={this.state.selectedMethod}
                                onMFACodeChange={this.handleMFACodeChange}
                                cancelAdd={this.cancelAdd}
                            />
                        }
                    </Container>
                </Jumbotron>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate,
    AuthChallenge: state.AuthChallenge
})

export default connect(mapStateToProps)(AddFacility)