import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import Loader from './common/Loader';
import RadioField from '../components/common/RadioField'
import FacilityMFAValidateForm from '../components/FacilityMFAValidateForm'
import { Jumbotron, Container, Col, Row, Button, Alert, Modal } from 'reactstrap';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

/**
 * Facility MFA Form (15B)
 */
class FacilityMFAForm extends Component {
    render() {
        const { isFetching } = this.props.AuthChallenge
        const { formState } = this.props
        var devices = this.props.formState.mfaOptions.map((device, index) => {
            var d = device.device
            var options = device.capabilities.map((item) => {
                return (
                    <RadioField key={item.key} fieldName='duo' fieldValue={item.mfaFieldValue} fieldLabel={item.mfaFieldLabel} fieldID={item.mfaFieldValue} handleChange={this.props.handleSelection(d)} />
                )
            });

            return (
                <div>
                    <br></br>
                    <p key={index}>{device.number}</p>
                    {options}
                </div>
            )
        });
        
        return (
            <Jumbotron>
                <Container>
                    <Row>
                        <Col>
                            {isFetching && <Loader msg={formState.loadMsg} />}
                            {this.props.Authenticate.provider.pwdExpire !== undefined && this.props.Authenticate.provider.pwdExpire <= 14 && <Alert color="danger">{'Your password will expire in ' + this.props.Authenticate.provider.pwdExpire + ' days'}</Alert>}

                            {formState.newUser ? (
                                <span>
                                    <h1 className="text-warning">Your medical facility access has been verified.</h1>
                                    <br />
                                    <hr className="my-2" />
                                    <p><b>Your Facility:</b> {this.props.facility}</p>
                                    <p>To verify your identity, please select a method of second factor authentication for the medical facility.</p>
                                </span>
                            ) : (
                                    <span className="authSecurity">
                                        <h1 className="text-center">Security</h1>
                                        <hr className="my-2" />
                                        {/*<p><b>Your Facility:</b> {this.props.facility}</p>*/}
                                        <h4>For your security, please select a method of second factor authentication.</h4>
                                    </span>
                                )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {devices}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            {this.props.errorMessage !== '' && <Alert color="danger">{this.props.errorMessage}</Alert>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="primary" size="lg" id="mfaSubmit" type="submit" onClick={this.props.processSelection}>Continue »</Button>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <p>If you are unable to perform authentication, please contact your medical facility and reset your second factor authentication.</p>

                            <Modal isOpen={formState.validateMFA === true} onRequestClose={this.props.closeModal} style={customStyles} contentLabel='Verify Selection'>
                                <div>
                                    <FacilityMFAValidateForm formState={formState} resetMFA={this.props.resetMFA} AuthChallenge={this.props.AuthChallenge} errorMessage={this.props.errorMessage} onMFACodeChange={this.props.onMFACodeChange} validateMFA={this.props.validateMFA} />
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        );
    }

}

FacilityMFAForm.PropTypes = {
    AuthChallenge: PropTypes.object,
    errorMessage: PropTypes.string,
    formState: PropTypes.object.isRequired,
    handleSelection: PropTypes.func.isRequired,
    processSelection: PropTypes.func.isRequired,
    onMFACodeChange: PropTypes.func.isRequired,
    validateMFA: PropTypes.func.isRequired
}

export default FacilityMFAForm;