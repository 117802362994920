//#17
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Jumbotron, Container, Row, Col, Modal, Button } from 'reactstrap'
import Loader from '../components/common/Loader';
//import { updateUserEmail, checkRelyingParties } from '../actions/Authenticate'
import { checkRelyingParties } from '../actions/Authenticate'
//import ProgressBar from '../components/nav/ProgressBar'
import ProfileContent from '../components/ProfileContent'
import ProfileAccountForm from '../components/ProfileAccountForm'

/**
 * Profile Menu - Screen 18
 */
class Profile extends Component {
    /**
     * Constructor for class
     * @param {object} props 
     * 
     */
    constructor(props) {
        super(props)
        this.state = {
            accountType: '',
            modalContent: '',
            emailchange: '',
            loadMsg: '',
            errorMsg: '',
            nextStep: ''
        }

        this.EmailClick = this.EmailClick.bind(this)
        this.updateEmail = this.updateEmail.bind(this)
        this.changeEmail = this.changeEmail.bind(this)
        this.MFAClick = this.MFAClick.bind(this)
        this.FacilityClick = this.FacilityClick.bind(this)
        this.HelpClick = this.HelpClick.bind(this)
        this.CloseAcctClick = this.CloseAcctClick.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    /**
     * determine what kind of user
     */
    componentWillMount = () => {
        const { Authenticate } = this.props
        if (Authenticate.AuthSuccess !== undefined) {
            if (Authenticate.AuthSuccess.accountType === 'provider') {
                this.setState({
                    accountType: Authenticate.AuthSuccess.accountType,
                    emailchange: Authenticate.provider.user.email
                })
            } else {
                this.setState({
                    accountType: Authenticate.AuthSuccess.accountType,
                    emailchange: Authenticate.user_profile.email
                })
            }
        } else {
            this.setState({ loggedOut: true })
        }
    }

    componentDidMount = () => {
        const { dispatch } = this.props
    }

    EmailClick = () => {
        if (this.state.accountType === 'provider') {
            this.setState({ modalContent: 'ProfileAccountForm' })

        }
    }

    updateEmail = (e) => {
        this.setState({
            emailchange: e.target.value
        })
    }

    changeEmail = (e) => {
        const { dispatch, Authenticate } = this.props
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.emailchange) === false || this.state.emailchange === '') {
            return this.setState({ errorMsg: 'Invalid/missing email address' })
        } else {
            this.setState({ loadMsg: 'Updating Email Address' })
            /*dispatch(updateUserEmail({
                userId: Authenticate.sfidentity.user_single_file_identity.sf_user_identity_id,
                email: this.state.emailchange
            }))*/
            this.setState({ errorMsg: '', loadMsg: '' })
        }
    }


    MFAClick = () => {
        if (this.state.accountType === 'provider') {
            this.setState({ modalContent: 'MFAContent' })
        } else {

        }
    }


    FacilityClick = () => {
        this.setState({ nextStep: '/AddFacility' })
    }
    HelpClick = () => {
        this.setState({ nextStep: '/Help' })
    }
    CloseAcctClick = () => {
        this.setState({ nextStep: '/CloseAccount' })
    }

    closeModal = () => {
        this.setState({
            modalContent: ''
        })
    }

    render() {
      //  const navPath = (this.state.accountType === 'provider') ? 'providerAccount' : 'patientAccount'
        const { isFetching } = this.props.Authenticate
        const { Authenticate } = this.props
        let modalBody = ''
        switch (this.state.modalContent) {
            case 'MFAContent':
                modalBody = <MFAContent closeModal={this.closeModal} />
                break
            case 'ProfileAccountForm':
                modalBody = <ProfileAccountForm
                    sfId={Authenticate.sfidentity.user_single_file_identity.sf_user_identity_id}
                    email={this.state.emailchange}
                    updateEmail={this.updateEmail}
                    changeEmail={this.changeEmail}
                    closeModal={this.closeModal}
                />
                break
            default:
                break
        }
        return (
            <div className="Profile">
                {isFetching && <Loader msg={this.state.loadMsg} />}
                {/*<ProgressBar navPath={navPath} />*/}
                <Jumbotron>
                    <Container>
                        <Row>
                            <Col>
                                {this.state.nextStep !== '' &&
                                    <Redirect push to={this.state.nextStep} />
                                }

                                {this.state.loggedOut === true &&
                                    <Redirect push to="/" />
                                }
                                <ProfileContent
                                    EmailClick={this.EmailClick}
                                    LoginClick={this.EmailClick}
                                    MFAClick={this.MFAClick}
                                    FacilityClick={this.FacilityClick}
                                    HelpClick={this.HelpClick}
                                    CloseAcctClick={this.CloseAcctClick}
                                    closeModal={this.closeModal}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <Modal
                        isOpen={this.state.modalContent !== ''}
                        onRequestClose={this.closeModal}
                        contentLabel='Modify Profile'
                    >
                        {modalBody}
                    </Modal>
                </Jumbotron>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(Profile)

function MFAContent(props) {
    return (
        <Jumbotron>
            <Container>
                <Row>
                    <Col>

                        <h6>Your second factor authentication is linked to your medical facility account associated with Single-
FILE.</h6>
                        <h6>To change your second factor authentication options, please contact the system administrator for
your facility.</h6>
                        <Button id="continueBtn" size="lg" color="warning"
                            onClick={props.closeModal}>Close
                            </Button>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
    )
}
