import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col, Button } from 'reactstrap';

/**
 * Profile Menu content (part of #18)
 */
class ProfileContent extends Component {
    render() {
        return (
            <Row>
                <Col>
                    {/**        <span>

                    <h3>Account</h3>
                    <hr className="my-0" />
                    <Button outline color="primary" onClick={this.props.EmailClick}>Update iAgree Contact Email Address</Button>{' '}{' '}
                    <Button outline color="primary" onClick={this.props.LoginClick}>Update Login Accounts Associated with iAgree</Button>
                    </span>
                       
                    <h3>Second Factor Authentication</h3>
                    <hr className="my-0" />
                    <Button outline color="primary" disabled onClick={this.props.MFAClick}>Update Methods</Button>
                     **/}

                    <h3>Manage Linked Health Systems</h3>
                    <hr className="my-0" />
                    <Button outline color="primary" onClick={this.props.FacilityClick}>Manage Health Systems linked to my iAgree account</Button>

                    <h3>Help</h3>
                    <hr className="my-0" />
                    <Button outline color="primary" onClick={this.props.HelpClick}>Documentation and Support Materials</Button>

                    <h3>Close Account</h3>
                    <hr className="my-0" />
                    <Button outline color="primary" onClick={this.props.CloseAcctClick}>Discontinue Your iAgree Account</Button>
                </Col>
            </Row>
        )
    }

}

ProfileContent.PropTypes = {
    EmailClick: PropTypes.func.isRequired,
    LoginClick: PropTypes.func.isRequired,
    MFAClick: PropTypes.func.isRequired,
    FacilityClick: PropTypes.func.isRequired,
    HelpClick: PropTypes.func.isRequired,
    CloseAcctClick: PropTypes.func.isRequired

}


export default ProfileContent

