import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { createSingleFileIdentity, SetAuthSuccess } from '../actions/Authenticate'
//import ProgressBar from '../components/nav/ProgressBar'
import EstablishProviderMFAContent from '../components/EstablishProviderMFAContent'
import '../components/global/Main.css'

/**
 * Establish Provider MFA (#15C) - Let's provider know we'll use their established info for MFA
 */
class EstablishProviderMFA extends Component {
    /**
     * constructor
     * @param {object} props
     */
    constructor(props) {
        super(props)
        /**
        * @type {object}
        * @property {string} nextStep         - populate with next screen when the time comes
        * @property {string} insertUserStep   - keep track of the steps being processed during the Insert User process
        */
        this.state = {
            nextStep: '',
            insertUserStep: '',
            errorMessage: ''
        }

        this.createSFIdentity = this.createSFIdentity.bind(this)
        this.validateSuccess = this.validateSuccess.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    createSFIdentity = () => {
        const { dispatch, Authenticate } = this.props
        if (Authenticate.isFetching === false && Authenticate.isErr === false) {
            dispatch(createSingleFileIdentity({
                sf_user_identity_caller: "WEBPORTAL",
                sf_user_identity_mfa_preference: 'Facility Provided',
                sf_user_identity_email: Authenticate.user_email.email,
                sf_user_identity_type: 'Provider',
                idp_info: Authenticate.provider.idp.idp_info,
                idpId: Authenticate.provider.idp.idpd_idp_id,
                mfa_type: 'DUO'
            })).then(response => {
                if (this.props.Authenticate.isFetching === false && this.props.Authenticate.isErr === false) {
                    this.validateSuccess()
                } else {
                    this.setState({ errorMessage: 'Error occurred while creating user account'})
                }
            })
        }
    }

    validateSuccess = () => {
        const { dispatch } = this.props
        dispatch(SetAuthSuccess({
            accountType: 'provider',
            newUser: true,
            ProcessToken: true
        }))
        this.setState({
            insertUserStep: '',
            nextStep: '/AccountHome'
        })
    }


    /**
     * process button click on screen content
     * @property {string}     - string for clicking Back or Continue
     */
    handleClick = (selectedBtn) => {
        if (selectedBtn === 'back') {
            this.props.history.goBack()
        } else {
            const nextProps = this.props
            const nextState = this.state
            this.createSFIdentity()
        }
    }

    render() {
        const { nextStep } = this.state
        return (
            <div className="EstablishMFA">
                {nextStep === "/AccountHome" ? (
                    <Redirect push to="/AccountHome" />
                ) : (
                        <span>
                            {/* <ProgressBar navPath='newProvider' /> */}
                            <EstablishProviderMFAContent err={this.state.errorMessage} Authenticate={this.props.Authenticate} onButtonClick={this.handleClick} />
                        </span>
                    )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(EstablishProviderMFA)