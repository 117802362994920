import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import ProviderAddFacilityLoginForm from '../components/ProviderAddFacilityLoginForm'
import { Card, Button, CardHeader, CardText, Row, Col, Modal } from 'reactstrap';

import Loader from './common/Loader'
/**
 * Provider Add/Remove Facility Form (part of #19)
 */
class ProviderAddFacilityForm extends Component {
    render() {
        const { relyingParties, availableRP, removeClick, addClick, loadMsg, } = this.props
        const { isFetching } = this.props.AuthChallenge

        const facilityList = relyingParties.length > 0 ? relyingParties.map((facility) =>
            <Card outline color="danger" key={facility.facility_id}>
                <CardHeader onClick={() => { removeClick(facility.relying_party_id) }}>{facility.facility_name}</CardHeader>
                <hr className="my-1" />
                <Button color="danger" onClick={() => { removeClick(facility.relying_party_id) }}>Remove</Button>
            </Card>
        ) : <Card outline color="primary">
                <CardText>No facilities are registered.</CardText>
            </Card>

        const rpList = availableRP.length > 0 ? availableRP.map((facility) =>
            <Card outline color="primary" key={facility.idp_id}>
                <CardHeader onClick={() => { addClick(facility.idp_id) }}>{facility.facility_name}</CardHeader>
                <hr className="my-1" />
                <Button color="primary" onClick={() => { addClick(facility.idp_id) }}>Add</Button>
            </Card>
        ) : <Card outline color="primary">
                <CardText>No facilities available.</CardText>
            </Card>

        const rpListPatient = availableRP.length > 0 ? availableRP.map((facility) =>
            <Card outline color="primary" key={facility.idp_id}>
                <CardHeader onClick={() => { addClick(facility.idp_id) }}>{facility.facility_name}</CardHeader>
                <hr className="my-1" />
                <Button color="primary" onClick={() => { addClick(facility.idp_id) }}>Add</Button>
            </Card>
        ) : <Card outline color="primary">
                <CardText>No facilities available.</CardText>
            </Card>    
        return (
            <Row>
                <Col sm="6">
                    <h6>To remove an EMR system in your iAgree portal.</h6>
                    <hr className="my-0" />
                    <br />
                    {facilityList}
                </Col>
                <Col sm="6">
                    <h6>To add an EMR system in your iAgree portal.</h6>
                    <hr className="my-0" />
                    <br />
                    {this.props.Authenticate.AuthSuccess.accountType === "patient" ? rpListPatient : rpList}
                </Col>
                <Modal
                    isOpen={this.props.removeConfirm === true || this.props.addConfirm === true}
                    onRequestClose={this.props.closeModal}
                    contentLabel='Verify Selection'
                >
                    <div>
                        {isFetching && <Loader msg={loadMsg} />}
                        {this.props.removeConfirm &&
                            <div className="removeFacility" style={{ textAlign: 'center' }}>
                                <p><b>Are you sure you want to remove this facility?</b></p>
                                <Button color="danger" onClick={this.props.processRemove}>OK</Button> <Button onClick={this.props.closeModal}>Cancel</Button>
                            </div>
                        }
                        {this.props.addConfirm &&
                            <ProviderAddFacilityLoginForm
                                addFacilityStep={this.props.addFacilityStep}
                                errorMessage={this.props.errorMessage}
                                availRP={availableRP} 
                                facility={this.props.facilityChangeID}
                                Authenticate={this.props.Authenticate}
                                handleLoginChange={this.props.handleLoginChange}
                                doLogin={this.props.doLogin}
                                closeModal={this.props.closeModal}
                                doMFA={this.props.doMFA}
                                mfaOptions={this.props.mfaOptions}
                                validateMFA={this.props.validateMFA}
                                validateMFACode={this.props.validateMFACode}
                                AuthChallenge={this.props.AuthChallenge}
                                handleMFASelection={this.props.handleMFASelection}
                                processMFA={this.props.processMFA}
                                onMFACodeChange={this.props.onMFACodeChange}
                                resetMFA={this.props.resetMFA}
                                selectedMethod={this.props.selectedMethod}
                                onMFACodeChange={this.props.onMFACodeChange}
                                cancelAdd={this.props.cancelAdd}
                            />
                        }
                    </div>

                </Modal>
            </Row>
        )
    }

}

/**
 * @type {Object}
 * @property {array} relyingParties      - List of registered relying parties (required)
 * @property {array} availableRP         - List of available relying parties that can be registered (required)
 * @property {function} removeClick      - function to process removal of relying party from account (required)
 * @property {function} addClick         - function to process adding new relying party to account (required)
 * @property {string} loadMsg            - message to appear with loading spinner
 * @property {object} AuthChallenge      - AuthChallenge object from store
 * @property {function} closeModal       - handler to close modal
 * @property {boolean} removeConfirm     - true for removal process
 * @property {boolean} addConfirm        - true for add process
 * @property {function} processRemove    - handler to actually remove relying party from account
 * @property {string} addFacilityStep    - keeps track of which step we're in during add process
 * @property {object} Authenticate       - Authenticate object from store
 * @property {function} handleLoginChange - handler to update local store with username/password
 * @property {function} doLogin          - handler to perform authentication
 * @property {function} doMFA            - handler to perform MFA
 * @property {array} mfaOptions          - MFA options available for user account
 * @property {function} validateMFA      - handler to validate MFA
 * @property {function} handleMFASelection - handler to add selected MFA method to local store
 * @property {function} processMFA       - handler to process MFA validation code
 * @property {function} onMFACodeChange  - handler to update local store with MFA validation code entered by user
 * @property {string} errorMessage       - contains error messaging
 */

ProviderAddFacilityForm.PropTypes = {
    relyingParties: PropTypes.array.isRequired,
    availableRP: PropTypes.array.isRequired,
    removeClick: PropTypes.func.isRequired,
    addClick: PropTypes.func.isRequired,
    loadMsg: PropTypes.string,
    AuthChallenge: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    removeConfirm: PropTypes.boolean,
    addConfirm: PropTypes.boolean,
    processRemove: PropTypes.func,
    addFacilityStep: PropTypes.string,
    Authenticate: PropTypes.object,
    handleLoginChange: PropTypes.func,
    doLogin: PropTypes.func,
    doMFA: PropTypes.func,
    mfaOptions: PropTypes.array,
    validateMFA: PropTypes.func,
    handleMFASelection: PropTypes.func,
    processMFA: PropTypes.func,
    onMFACodeChange: PropTypes.func,
    errorMessage: PropTypes.string
}

export default ProviderAddFacilityForm

