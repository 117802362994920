import React, { Component } from 'react';
//import { Link } from 'react-router';
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom';
import { DropdownMenu, DropdownItem } from 'reactstrap';
import '../global/Main.css'

class ProgressBar extends Component {

  /**
 * Constructor for class
 * @param {object} props 
 * 
 */
  constructor(props) {
    super(props);
    /**
     * @property {string} inProgress        - set to MFA type while in progress
     * @property {string} verificationCode  - code entered by user
     * @property {string} errorMessage      - error message string
     * @property {boolean} stepComplete     - set to true when validation succeeds
     * @property {string} phoneNumber       - device number used for MFA
     */
    this.state = {
      newUser: [
        {
          name: 'Account Creation',
          route: '/SFCreate',
        },
        {
          name: 'Facility',
          route: '/SelectFacility'
        },
        {
          name: 'Identity Verification',
          route: '/IDVerification'
        },
        {
          name: 'Security',
          route: '/EstablishMFA'
        }
      ],
      newProvider: [
        {
          name: 'Identity Verification',
          route: ''
        },
        {
          name: 'Security',
          route: '/ProviderEstablishMFA'
        }
      ],
      existingUserLogin: [
        {
          name: 'Security',
          route: '/MFA'
        }
      ],
      existingProviderLogin: [
        {
          name: 'Security',
          route: '/FacilityMFA'
        }
      ],
      providerAccount: [
        {
          name: 'Main Menu',
          route: '/AccountHome'
        },
        {
          name: 'Manage Linked Health Systems',
          route: '/AddFacility'
        },
        {
          name: 'Help',
          route: '/Help'
        },
        {
          name: 'User Profile',
          route: '/Profile'
        }
      ],
      patientAccount: [
        {
          name: 'Home',
          route: '/AccountHome',
          icon: 'fa fa-home fa-fw'
        },
        {
          name: 'Manage Linked Health Systems',
          route: '/AddFacility',
          icon: 'fa fa-hospital-o fa-fw'
        },
        {
          name: 'Profile',
          route: '/Profile',
          icon: 'fa fa-cog fa-fw'
        },
        {
          name: 'Help',
          route: '/Help',
          icon: 'fa fa-question-circle fa-fw'
        }
      ]
    }

  }

  render() {
    const navPath = this.props.navPath  ? this.props.navPath : ''
    const navList = this.state[navPath].map((item, index) =>
      <DropdownItem key={index} tag={Link} to={item.route}><i className={item.icon} />{" "}{item.name}</DropdownItem>
    )

    return (
            <DropdownMenu className="progressBar">
              {navList}
              <DropdownItem divider />
              <DropdownItem tag={Link} to={"/LoggedOut"}><i className="fa fa-sign-out fa-fw" />{" "}Logout</DropdownItem>
            </DropdownMenu>
    );
  }
}
ProgressBar.PropTypes = {
  navPath: PropTypes.string.isRequired
}

export default ProgressBar
