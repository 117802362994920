import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Jumbotron, Container, Row, Col, Button, Form, Input, Card, CardText, Badge } from 'reactstrap';

/**
 * Patient Main Menu (part of #17)
 */
class PatientMainMenu extends Component {

    render() {
        const { relyingParties, handleSSOClick } = this.props
        const facilityList = relyingParties.length > 0 ? relyingParties.map((facility) =>
            <div key={facility.facility_id} style={{display: 'inline-block'}}>
                <span>
                    <h5><Badge color='success' size='lg' className={facility.relying_party_info} pill>{facility.facility_name}</Badge></h5>
                </span>
            </div>
        ) : <Card outline color="primary">
                <CardText>No Health Systems are linked.</CardText>
            </Card>
        const facilityIt = 
            <span key='title'>
                <span><b>Welcome to iAgree</b></span>
            </span>

    return (
            <Jumbotron>
                <Container>
                    {this.props.newUser &&
                        <h3 className="newUser">Congratulations! You have successfully enrolled in iAgree.</h3>
                    }
                    <h1 className="text-primary">{facilityIt}</h1>
                    <hr className="my-2" />
                    <Row>
                    <Col>
                        <p className="topFont">Your iAgree account lets you manage how you share your health data for research projects.</p>
                        <br />
                        {/*{facilityList}*/}
                        <Button color='danger' size='lg' onClick={() => { handleSSOClick() }}>Manage my data sharing preferences</Button>
                        <br /><br /><br />
                        <p>Your iAgree account is linked to your medical records at: {facilityList}</p>
                        <br />
                        <p className="botFont">Your data sharing preferences will only apply for Health systems linked to your iAgree account.<br/>
                        Want to link your iAgree account to your medical records at other health systems?  <a href="/SelectFacility2">Click here</a></p>
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col>
                        <Form id='ssoForm' target={this.props.targetWindow} name="acsForm" style={{ display: 'none' }} action={this.props.formAction} method="POST">
                        <Input type="hidden" name="SAMLResponse" value={this.props.SAMLResponse} />
                    </Form>
                    </Col>
                </Row>
                </Container>
            </Jumbotron>
        )
    }

}

PatientMainMenu.PropTypes = {
    relyingParties: PropTypes.array.isRequired
}

export default PatientMainMenu