import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { withRouter } from 'react-router-dom'

import { doUnifiedFacilityLogin } from '../actions/Authenticate'
import ProviderLoginForm from '../components/ProviderLoginForm'
import './ProviderLogin.css'

/**
 * ProviderLogin (#8) - handles the Provider AD authentication
 */
class ProviderUnifiedLogin extends Component {
    /**
    * Constructor for class
    * @param {object} props 
    * 
    */
    constructor(props) {
        super(props)

        /**
         * @property {string} email          - email address for login
         * @property {string} password       - password entered by user
         * @property {string} errorMessage   - error message string
         * @property {string} nextPage       - which page to go to next
         */
        this.state = {
            email: '',
            password: '',
            errorMessage: '',
            nextPage: ''
        }
        this.doLogin = this.doLogin.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    /**
     * Before re-rendering screen, check authentication status and process accordingly
     * @param {object} nextProps    - properties to be applied to the view
     */
    componentWillReceiveProps = (nextProps) => {
        const { Authenticate } = nextProps
        let nextPage = ''
        let { isErr } = Authenticate
        if (Authenticate.provider !== undefined) {
            switch (isErr) {
                case true:
                    let errMsg = ''
                        switch (Authenticate.provider.status) {
                            case "0":
                                errMsg = 'Unknown Error'
                                break
                            case "2":
                                errMsg = 'Username/Password Incorrect'
                                break
                            case "3":
                                errMsg = 'Password Expired'
                                break
                            case "4":
                                errMsg = 'Account Deactivated'
                                break
                            case "5":
                                errMsg = 'Username/Password Incorrect'
                                break
                            case "6":
                                errMsg = 'Account Expired'
                                break
                            case "12":
                                errMsg = 'Maximum Login Attempts Exceeded'
                                break
                            default:
                                errMsg = 'Username/Password Incorrect'
                                break
                        }
                        this.setState({ errorMessage: errMsg })

                case false:
                    if (Authenticate.sfidentity !== undefined && Authenticate.provider.user !== undefined) {
                        if (Authenticate.sfidentity === 404) {
                            nextPage = '/ProviderIDVerification'
                        } else if (Authenticate.sfidentity.user_single_file_identity !== undefined) {
                            const sfi = Authenticate.sfidentity.user_single_file_identity
                            if (sfi.sf_mfa_status_value === 'Active') {
                                if (sfi.sf_mfa_type_value === 'DUO') {
                                    nextPage = '/FacilityMFA'
                                } else {
                                    nextPage = '/MFA'
                                }
                            } else if (sfi.sf_mfa_status_value === 'locked out') {

                            } else {
                                nextPage = '/ProviderIDVerification'
                            }
                        }
                    }
                    break
                default:
                    nextPage = ''
                    break
            }
        }
        if (nextPage !== '') {
            this.setState({ nextPage: nextPage })
        }
    }

    /**
     * Process AD Authentication
     * @param {object} e    - login button
     */
    doLogin = e => {
        e.preventDefault();
        const { dispatch } = this.props
        dispatch(doUnifiedFacilityLogin({
            userName: this.state.email,
            password: this.state.password
        }))
    }

    /**
     * Update local state with username/password as they are entered
     * @param {object} e    - Form field being updated
     */
    handleChange = e => {
        if (e.target.value !== '') {
            let payload = {}
            payload[e.target.name] = e.target.value
            this.setState(payload)
        }
    }

    render() {
        const { Authenticate } = this.props
        const facility = Authenticate.facility !== undefined ? Authenticate.facility.idp_friendly_name : 'ERR'
        return (
            <span>
                {this.state.nextPage !== '' &&
                    <Redirect push to={this.state.nextPage} />
                }
                <ProviderLoginForm errorMessage={this.state.errorMessage} facility={facility} Authenticate={Authenticate} handleChange={this.handleChange} doLogin={this.doLogin} />
            </span>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate
})

export default withRouter(connect(mapStateToProps)(ProviderUnifiedLogin))
