import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import ProgressBar from '../../components/nav/ProgressBar'
import iAgreeImg from '../../images/iAgree.png'
//import { Link } from 'react-router-dom';
import { Container, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavLink, NavItem, NavDropdown, DropdownToggle } from 'reactstrap'
import './Main.css'

class Header extends Component {

  /**
   * Constructor for class
   * @param {object} props 
   * 
   */
  constructor(props) {
    super(props);

    /**
     * @property {boolean} logout          - true when logout clicked
     */
    this.state = {
      accountType: '',
      isOpen: false,
      dd1: false
    }

    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { Authenticate } = nextProps
      if (Authenticate.AuthSuccess !== undefined) {
        this.setState({  
          accountType: Authenticate.AuthSuccess.accountType
        });
      }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  dropdownToggle() {
    this.setState({
      dd1: !this.state.dd1
    });
  }

  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    const { Authenticate } = this.props
    const navPath = (this.state.accountType === 'provider') ? 'providerAccount' : 'patientAccount'
    const headerClass = Authenticate.AuthSuccess !== undefined ? 'HeaderAdmin' : 'Header'
    const hideHeader = window.location.pathname==='/' ||
                       window.location.pathname==='/AccountHome' ||
                       window.location.pathname==='/About' ||
                       window.location.pathname==='/Faq' ||
                       window.location.pathname==='/Contact' ||
                       window.location.pathname==='/Terms' ||
                       window.location.pathname==='/Privacy' ||
                       window.location.pathname==='/Profile' ||
                       window.location.pathname==='/AddFacility' ||
                       window.location.pathname==='/CloseAccount' ||
                       window.location.pathname==='/Help' ? {display: 'block'} : {display: 'none'}

    return (
      <div className={headerClass} style={hideHeader}>
      <Container>
        <Navbar className="header" inverse toggleable>
          {!Authenticate.AuthSuccess && <NavbarBrand href="/"><img src={iAgreeImg} height="120" width="auto" /></NavbarBrand>}
          {Authenticate.AuthSuccess && <NavbarBrand href="/AccountHome"><img src={iAgreeImg} height="100" width="auto" /></NavbarBrand>}
          <NavbarToggler right onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            {this.state.logout && <Redirect push to='/LoggedOut' />}
            <Nav className="ml-auto" pills>
              <NavItem>
                <NavLink href="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/About">About</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/Faq">FAQ</NavLink>
              </NavItem>
              {!Authenticate.AuthSuccess && 
                <NavItem>
                  <NavLink href="/Contact">Contact</NavLink>
                </NavItem>
              }
              
              {
                Authenticate.AuthSuccess &&  
                <NavDropdown isOpen={this.state.dd1} toggle={this.dropdownToggle}>
                  <DropdownToggle nav caret>
                                    
                  <i className="fa fa-user-circle" />{" "}{Authenticate.sfidentity ? (Authenticate.sfidentity.user_single_file_identity.sf_user ? Authenticate.sfidentity.user_single_file_identity.sf_user : Authenticate.sfidentity.user_single_file_identity.sf_auth_username) : ""}
                  </DropdownToggle>
                  {Authenticate.AuthSuccess && <ProgressBar navPath={navPath} />}
                </NavDropdown> }

              {/*
                Authenticate.AuthSuccess &&
                <Nav className="me-auto" navbar>
                <i className="fa fa-user-circle" />{" "}{Authenticate.sfidentity ? (Authenticate.sfidentity.user_single_file_identity.sf_user ? Authenticate.sfidentity.user_single_file_identity.sf_user : Authenticate.sfidentity.user_single_file_identity.sf_auth_username) : ""}
                <NavItem>
                    <NavLink href="/AddFacility">
                      Manage Linked Health Systems
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink href="/Profile">
                      Profile
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink href="/Help">
                      Help
                    </NavLink>
                  </NavItem>
                </Nav>
              */}  
            </Nav>
          </Collapse>
        </Navbar>
      </Container>  
      </div>
    )
  }
}

/**
 * @type {Object}
 * @property {object} Authenticate - Authenticate store
 */

Header.PropTypes = {
  Authenticate: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(Header)