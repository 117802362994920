import React, { Component } from 'react'
import { Row, Col, Button, Card, CardHeader, CardText } from 'reactstrap';

/**
 * Patient Main Menu (part of #20)
 */
class PatientHelpContent extends Component {
    render() {
        const { facilities } = this.props
        const facilityList = facilities.length > 0 ? facilities.map((facility) =>
            <div key={facility.facility_id}>
                <Card className="text-center">
                    <CardHeader>{facility.facility_name}</CardHeader>
                    <hr className="my-1" />
                    <CardText>
                        <i className="fa fa-phone" />{" "}323-866-8981 or {" "}<i className="fa fa-envelope" />{" "}<Button outline color="primary" size="sm" href="mailto:Cedars.SingleFile@cshs.org">Cedars.SingleFile@cshs.org</Button>
                    </CardText>
                </Card>
            </div>
        ) : ''

        return (
            <Row>
                <Col>
                    <h1 color="text-primary">Help</h1>
                    <br />
                    <hr className="my-2" />
                    <p>Documentation</p>
                    <p>&lt;User Documentation Link TBD&gt;</p>

                    <br />
                    <hr className="my-0" />
                    <p>For additional assistance, please email <Button outline color="primary" size="sm" href="mailto:info@iagree2share.org">info@iagree2share.org</Button></p>
                </Col>
            </Row>
        )
    }

}


export default PatientHelpContent

