/**
 * catchall for system-wide constants, such as the URL to use for the proxy
 */
export const REST_URL = ''

/*function getURL() {
    if (process.env.NODE_ENV === 'production' || (location && location.hostname !== 'localhost')) {
        return 'dw65512b09.csmc.edu'
} else {
    return 'localhost'
}
}*/
