import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducer from '../reducers';
import DevTools from '../components/DevTools.js'
import { loadState } from './localStorage'

export default function configureStore(initialState) {
  const persistedState = loadState() // load copy of redux store from localStorage
  const finalCreateStore = compose(
    applyMiddleware(thunkMiddleware),
    //DevTools.instrument() ? DevTools.instrument(): f => f
  )(createStore);

  const store = finalCreateStore(reducer, persistedState);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers');
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}