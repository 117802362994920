import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import ProgressBar from '../components/nav/ProgressBar'
import EstablishMFAForm from '../components/EstablishMFAForm'
import { clearRelyingParties, checkRelyingParties, createSingleFileIdentity, createSingleFileAuthentication, createSingleFileMFA, createSingleFileRelyingParty } from '../actions/Authenticate'
//import { doCreateAuthChallenge, fetchAuthChallenge, patientfetchAuthChallengeStatus, clearMFA } from '../actions/AuthChallenge'
import { doCreateAuthChallenge, patientfetchAuthChallengeStatus, clearMFA } from '../actions/AuthChallenge'
import '../components/global/Main.css'

/**
 * Establish MFA (#13) - Set and validate MFA method for account
 */
class EstablishMFA extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props)
    /**
    * @type {object}
    * @property {boolean} phone phone checkbox
    * @property {boolean} googleauth googleauth checkbox
    * @property {string} phoneNo phone number entered on form
    * @property {object} phoneOptions types of MFA to use with phone
    * @property {object} methodSuccess document successful MFA registrations(s)
    * @property {string} inProgress documents which MFA method is being validated
    * @property {string} verificationCode the verification code being compared against
    * @property {string} errorMessage error message to display on UI
    * @property {string} validateMessage MFA validation message to display on UI
    * @property {boolean} stepComplete true when user has completed verification and proceed to next screen
    */
    this.state = {
      phone: false,
      googleauth: false,
      phoneNo: '',
      phoneErr: false,
      phoneOptions: {
        sms: false,
        call: false,
        push: false
      },
      methodSuccess: {
        sms: false,
        call: false,
        push: false,
        token: false,
        google: false
      },
      validateMFA: false,
      insertUserStep: '',
      inProgress: '',
      verificationCode: '',
      errorMessage: '',
      validateMessage: '',
      stepComplete: false
    }
    this.checkRelyingParties = this.checkRelyingParties.bind(this)
    this.verifyRelyingParties = this.verifyRelyingParties.bind(this)
    this.setAuthMFA = this.setAuthMFA.bind(this)
    this.validateSuccess = this.validateSuccess.bind(this)
    this.handleMFAOptions = this.handleMFAOptions.bind(this)
    this.handlePhoneInput = this.handlePhoneInput.bind(this)
    this.handleMFAPhoneOptions = this.handleMFAPhoneOptions.bind(this)
    this.validateMFA = this.validateMFA.bind(this)
    this.resendMFA = this.resendMFA.bind(this)
    this.onMFACodeChange = this.onMFACodeChange.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleContinue = this.handleContinue.bind(this)
  }

  /**
   * clear out previous check for relying parties
   */
  componentWillMount = () => {
    const { dispatch } = this.props
    dispatch(clearRelyingParties())
  }


  /**
   * process error conditions before render
   * @param {object} nextProps
   */
  componentWillReceiveProps(nextProps) {
    const { dispatch, AuthChallenge } = nextProps
    let errorMsg = ''

    if (AuthChallenge.authCreate !== undefined) {
      if (AuthChallenge.authCreate.success === 'false') {
        errorMsg = AuthChallenge.authCreate.message
      }
    }


    if (AuthChallenge.authData !== undefined) {
      if (AuthChallenge.authData.user_auth_factor_challenge_status !== undefined) {
        if (AuthChallenge.authData.user_auth_factor_challenge_status.success === 'false') {
          errorMsg = AuthChallenge.authData.user_auth_factor_challenge_status.message
        }
      }

      if (AuthChallenge.authData.user_auth_factor_validate !== undefined) {
        if (AuthChallenge.authData.user_auth_factor_validate.success === 'true') {
          const results = AuthChallenge.authData.user_auth_factor_validate
          let successUpdate = this.state
          successUpdate.methodSuccess[results.authFactorType] = true
          successUpdate.inProgress = ''
          this.setState(successUpdate)
          this.setState({
            insertUserStep: 'checkRelyingParty',
            errorMessage: ''
          })
          dispatch(clearMFA())
        }
      }
    }

    if (this.state.errorMessage !== errorMsg)
      this.setState({ errorMessage: errorMsg })
  }

  componentWillUpdate = (nextProps, nextState) => {
    // loop through the insertUserStep possibilities
    if (nextState.insertUserStep === 'checkRelyingParty') {
      console.log('checkRelyingParty')
      this.checkRelyingParties(nextProps)
    }

    if (nextState.insertUserStep === 'verifyRelyingParty') {
      console.log('verifyRelyingParty')
      this.verifyRelyingParties(nextProps)
    }

    if (nextState.insertUserStep === 'setAuthMFA') {
      console.log('setAuthMFA')
      this.setAuthMFA(nextProps)
    }
    if (nextState.insertUserStep === 'validateSuccess') {
      console.log('validateSuccess')
      this.validateSuccess(nextProps)
    }

  }


  /**
   * If MFA method set, then validate with user
   * Also check if validation was performed and was successful.
   */
  componentDidUpdate = (prevProps, prevState) => {
    const { dispatch } = this.props
    const { inProgress, phoneNo } = this.state
    // if (inProgress !== '' && inProgress !== prevState.inProgress && inProgress !== 'passcode' && this.state.validateMFA === true) {
    //   console.log('componentDidUpdate')
    //   console.log(this.props.AuthChallenge)
    //   console.log(this.props.Authenticate)
    //   dispatch(doCreateAuthChallenge({
    //     authFactorType: inProgress,
    //     mfaType: 'singlefile',
    //     countryCode: '1',
    //     phoneNumber: phoneNo,
    //     email: this.props.Authenticate.user_profile.email
    // }))
    // }
  }

  /**
   * resend MFA verification code
   */
  resendMFA = () => {
    const { dispatch } = this.props
    const { inProgress, phoneNo } = this.state
    dispatch(doCreateAuthChallenge({
      authFactorType: inProgress,
      mfaType: 'singlefile',
      countryCode: '1',
      phoneNumber: phoneNo,
      email: this.props.Authenticate.user_profile.email
    })).then(response => {
        this.setState({ errorMessage: 'New MFA code sent' })
      });
  }

  /**
   * Initiate a check to see if a relying party record exists
   */
  checkRelyingParties = (nextProps) => {
    const { dispatch, Authenticate } = nextProps
    dispatch(checkRelyingParties({
      relyingPartyId: Authenticate.facility.idp_id,
      relyingPartyAccessInfo: Authenticate.user_profile.email,
      PT: 'true'
    }))
    this.setState({ insertUserStep: 'verifyRelyingParty' })
  }

  /**
   * if record exists, prompt to add to existing account, otherwise proceed with creating a new Single-FILE identity
   */
  verifyRelyingParties = (nextProps) => {
    const { dispatch, Authenticate } = nextProps
    const relyingParties = Authenticate.relying_parties
    if (Authenticate.isFetching === false) {
      if (Authenticate.isErr === true) {
        if (Authenticate.relying_parties.status === 404) {
          dispatch(createSingleFileIdentity({
            sf_user_identity_caller: "WEBPORTAL",
            sf_user_identity_mfa_preference: 'Single File Provided',
            sf_user_identity_email: Authenticate.user_email.email,
            sf_user_identity_type: 'Patient'
          }))
          this.setState({ insertUserStep: 'setAuthMFA' })
        }
      } else if (relyingParties[0] !== undefined) {
        this.setState({ insertUserStep: 'userExistsConfirm' })
      }
    }
  }

  /**
   * if SF Identity was created, continue with setting Auth and MFA
   */
  setAuthMFA = (nextProps) => {
    const { dispatch, Authenticate, AuthChallenge } = nextProps
    if (Authenticate.sfidentity !== undefined) {
      if (Authenticate.sfidentity.user_single_file_identity !== undefined) {
        dispatch(createSingleFileAuthentication({
          auth_username: Authenticate.user_profile.email,
          idp_info: Authenticate.user_profile.authType,
          ui_id: Authenticate.sfidentity.user_single_file_identity.sf_user_identity_id
        }))

        dispatch(createSingleFileMFA({
          ui_id: Authenticate.sfidentity.user_single_file_identity.sf_user_identity_id,
          mfa_type: 'TWILIO',
          mfa_type_id: AuthChallenge.authCreate.user,
          auth_id: null
        }))

        dispatch(createSingleFileRelyingParty({
          user_identity_id: Authenticate.sfidentity.user_single_file_identity.sf_user_identity_id,
          relying_party_info: Authenticate.facility.idp_info,
          relying_party_access_info: null
        }))
        if (Authenticate.isErr === false) {
          this.setState({
            insertUserStep: 'validateSuccess'
          })
        }
      }
    }
  }

  validateSuccess = (nextProps) => {
    this.setState({
      insertUserStep: '',
      stepComplete: true
    })
  }

  /**
   * Update the local state to match top level checkbox selection
   * @param {object} e  - checkbox clicked
   */
  handleMFAOptions = e => {
    let MFAOptions = {
      phone: false,
      googleauth: false
    }
    MFAOptions[e.target.value] = (e.target.checked)
    let newVal = Object.assign({}, this.state, MFAOptions)
    this.setState(newVal)
  }

  /**
   * Update local state with phone number, also do a checkbox check in case we can proceed. 
   * if everything is good, set the inProgress field to match which validation method to process
   * @param {object} e  - the phone number field being update
   */
  handlePhoneInput = e => {
    const formPhone = e.target.value
    const phoneNo = formPhone.includes('_') ? '' : formPhone
    const phoneErr = (phoneNo === '') ? true : false
    const checkedValues = [].filter.call(document.getElementsByName('phoneOptions'), (c) => c.checked).map(c => c.value);
    const inProgress = (phoneErr === false && checkedValues.length > 0) ? checkedValues[0] : ''
    this.setState({ ...this.state, phoneNo: phoneNo, phoneErr: phoneErr, inProgress: inProgress })
  }

  /**
   * Update the checked box for phone option. Clear other entries (only one at a time)
   * @param {object} e  - Checkbox clicked
   */
  handleMFAPhoneOptions = e => {
    const selectedOption = e.target.value
    console.log(selectedOption)
    let phoneErr = true
    let { phoneOptions, phoneNo } = this.state
    for (let key of Object.keys(phoneOptions)) {
      phoneOptions[key] = false
    }
    phoneOptions[selectedOption] = e.target.checked
    let inProgress = ''
    if (phoneNo !== '') {
      phoneErr = false
      inProgress = selectedOption
    }
    this.setState({ ...this.state, phoneErr: phoneErr, phoneOptions: phoneOptions, inProgress: inProgress })
  }

  /**
   * Move to the next step -- will proceed if MFA registration was successfully completed.
   */
  handleContinue = e => {
    // console.log('handle Continue')
    // const { methodSuccess } = this.state
    // const testSuccess = Object.keys(methodSuccess).find(key => methodSuccess[key] === true) !== undefined ? true : false
    // if (testSuccess === true) {
    //   console.log('setting step checkRelyingParty')
    //   this.setState({
    //     insertUserStep: 'checkRelyingParty',
    //     errorMessage: ''
    //   })
    // } else {
    //   this.setState({ errorMessage: 'Please enroll in a second factor of authentication before continuing.' })
    // }

    const { dispatch } = this.props
    const { inProgress, phoneNo } = this.state

    if (inProgress === '') {
      this.setState({ errorMessage: 'Please enroll in a second factor of authentication before continuing.' })
    } else {
      this.setState({ validateMFA: true })
      if (inProgress !== '') {
        console.log('handle continue')
        console.log(this.props.AuthChallenge)
        console.log(this.props.Authenticate)
        dispatch(doCreateAuthChallenge({
          authFactorType: inProgress,
          mfaType: 'singlefile',
          countryCode: '1',
          phoneNumber: phoneNo,
          email: this.props.Authenticate.user_profile.email
        }))
      }
    }
  }

  /**
   * Validate supplied MFA code (valid for SMS and passcode)
   */
  validateMFA = (e) => {
    e.preventDefault();
    const { dispatch, AuthChallenge } = this.props
    const { inProgress, verificationCode } = this.state
    const payload = {
      authFactorType: inProgress,
      mfaType: 'singlefile',
      verificationCode: verificationCode,
      //user: AuthChallenge.authCreate.user,
      user: AuthChallenge.authData.user_auth_factor_challenge_status.user
      //verify: true
    }
    dispatch(patientfetchAuthChallengeStatus(payload))
  }

  /**
   * Update local state with MFA Verification Code
   * @param {object} e  - text field containing verification code entered by user
   */
  onMFACodeChange = e => {
    this.setState({ 'verificationCode': e.target.value })
  }

  /**
   * Close the modal window handling MFA verification
   */
  closeModal = () => {
    console.log('closeModal')
    const { inProgress } = this.state
    let resetOption = {
      phoneOptions: {},
      inProgress: ''
    }
    resetOption.phoneOptions[inProgress] = false
    this.setState(resetOption)
  }

  render() {
    const err = this.props.AuthChallenge.isErr ? "Identity Verification failed." : this.state.errorMessage
    const insertUserErr = this.props.Authenticate.isErr ? "error" : ''
    const { stepComplete } = this.state
    return (
      <div className="EstablishMFA">
        {stepComplete ? (
          <Redirect push to="/AccountHome" />
        ) : (
            <div>
            <ProgressBar navPath='newUser'/>
            <EstablishMFAForm
                errorMessage={err}
                insertUserError={insertUserErr}
                IDVerification={this.props.IDVerification}
                Authenticate={this.props.Authenticate}
                AuthChallenge={this.props.AuthChallenge}
                handleClick={this.handleMFAOptions}
                closeModal={this.closeModal}
                handlePhoneInput={this.handlePhoneInput}
                validateMFA={this.validateMFA}
                resendMFA={this.resendMFA}
                onMFACodeChange={this.onMFACodeChange}
                handleOptionsClick={this.handleMFAPhoneOptions}
                formState={this.state}
                handleContinue={this.handleContinue} />
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  Authenticate: state.Authenticate,
  AuthChallenge: state.AuthChallenge,
  IDVerification: state.IDVerification //used to display phone number
})

export default connect(mapStateToProps)(EstablishMFA)