import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import Loader from './common/Loader'
import { Jumbotron, Container, Row, Col, Button, Alert } from 'reactstrap';
import './global/Main.css';

/**
 * Content page for Establish Facility MFA (15C)
 */
class EstablishProviderMFAContent extends Component {
    render() {
        const {isFetching} = this.props.Authenticate
        
        return (
            <Jumbotron>
                <Container>
                    <Row>
                        <Col>
                            {isFetching && <Loader msg='Creating User Account' />}
                            {this.props.err && <Alert color="danger">{this.props.err}</Alert>}
                            <h6>Single-FILE will use your existing multifactor authentication at your facility for Single-FILE.</h6>
                            <br />
                            <h6>You will be prompted for multifactor authentication every time you log into Single-FILE.</h6>
                            <hr className="my-4" />
                            <h6>Press Continue to complete your enrollment, or press Back to return to the previous screen.</h6>
                            <br />
                            <Button id="backBtn" size="lg" color="info" onClick={() => {this.props.onButtonClick('back')}}><i className="fa fa-angle-double-left" />{" "}Back </Button>
                            &ensp;
                            <Button id="continueBtn" size="lg" color="primary" onClick={() => {this.props.onButtonClick('continue')}}>Continue{" "}<i className="fa fa-angle-double-right" /></Button>
                        </Col>        
                    </Row>
                </Container>
            </Jumbotron>
        )
    }

}

/**
 * @type {Object}
 * @property {object} Authenticate     - Authenticate store
 * @property {func} onButtonClick      - handler for page buttons
 * @property {string} err              - error message
 */
EstablishProviderMFAContent.PropTypes = {
    Authenticate: PropTypes.object,
    onButtonClick: PropTypes.func.isRequired,
    err: PropTypes.string
}

export default EstablishProviderMFAContent;