//#10
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from './common/Loader';
import { Jumbotron, Container, Row, Col, Input, Button, Alert } from 'reactstrap';

class OTPVerifyForm extends Component {
    constructor(props) {
        super(props);
        this.handleBackClick = this.handleBackClick.bind(this);
    }
    handleBackClick(){
        window.history.go(0);
    }

    render() {
        const { errorMessage } = this.props
        const { isFetching } = this.props.IDVerification
        return (
            <Jumbotron className="otpVerify">
                <Container>
                    <Row>
                        <Col>
                            {isFetching &&
                                <Loader />
                            }
                            {errorMessage &&
                                <Alert color="danger">{errorMessage}</Alert>
                            }
                            <h4 className="text-center">Enter the 7-digit one-time passcode you received and confirm your identity.</h4>
                            <hr className="my-2" />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md={{ size: 8, offset: 2 }}>    
                            <label htmlFor="otpverify">One-Time Passcode: </label>{" "}
                            <Input id="otpverify" placeholder="Passcode" onChange={this.props.onChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>        
                            <p>If you did not receive the one-time passcode, <a href="#" onClick={this.props.resendMFA}>click here</a> to resend the one-time passcode.</p>
                            <Button id="backBtn" size="lg" color="secondary" onClick={this.handleBackClick}><i className="fa fa-angle-double-left" />{" "}Back </Button>  
                            <Button id="idpSubmit" color="primary" size="lg" onClick={this.props.onOTPValidate}>Continue</Button>
                        </Col>    
                    </Row>
                </Container>
            </Jumbotron>
        );
    }
}

export default OTPVerifyForm;
