import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { doUnifiedFacilityLogin } from '../actions/Authenticate';
import ProviderLoginForm from '../components/ProviderLoginForm';
import ProviderLoginRegForm from '../components/ProviderLoginRegForm';
import { doLogoutUser, SFLogin, SFSignup, getFBProfile, getGoogleProfile, fetchIDPList, checkPatientRelyingParties, setUserDetails } from '../actions/Authenticate'
import { SocialIcon } from 'react-social-icons';
import LoginModal from "react-login-modal-sm";
import { Jumbotron, Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Collapse, Card, Form, FormGroup, FormText, Label, Input, InputGroup, InputGroupAddon, TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, CardText } from 'reactstrap';
//import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput, MDBBtn, MDBIcon, MDBModalFooter } from 'mdbreact';
import '../components/global/Main.css';

/**
 * Home Container - Screen #1
 * Login Buttons for Patients and Providers
 */
class Home extends Component {

  /**
   * On page load, automatically logout user and clear the Redux store.
   * We may change this up going forward, but for now it allows you to start fresh when hitting the home page 
   * (instead of having the previously stored values get refreshed into the Redux store).
   */
  constructor(props) {
    super(props);
    this.state = {
      /**
       * @property {string} email          - email address for login
       * @property {string} password       - password entered by user
       * @property {string} errorMessage   - error message string
       * @property {string} nextPage       - which page to go to next
       */
      user: {
        id: '',
        status: '',
        name: '',
        email: '',
        emailVerify: '',
        password: '',
        passwordVerify: '',
        authType: '',
        OIDC: {}
      },
      patientRedirect: false,
      method: 'login',
      defaultEmail: '',
      email: '',
      password: '',
      errorMessage: '',
      nextPage: '',

      modal: false,
      modal1: false,
      showModal: false,
      collapseMyChart: false,
      collapseSingle: false
    };

    this.doLogin = this.doLogin.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.togglePatient = this.togglePatient.bind(this);
    this.toggleProvider = this.toggleProvider.bind(this);
    this.toggleProviderReg = this.toggleProviderReg.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleMyChart = this.toggleMyChart.bind(this);
    this.toggleSingle = this.toggleSingle.bind(this);
    this.onFacebookLogin = this.onFacebookLogin.bind(this);
    this.onGoogleLogin = this.onGoogleLogin.bind(this);
    this.FBSuccess = this.FBSuccess.bind(this);
    this.GoogleSuccess = this.GoogleSuccess.bind(this);

  }

  togglePatient() {
    this.setState({
      modalPatient: !this.state.modalPatient
    });
  }

  toggleProvider() {
    this.setState({
      modalProvider: !this.state.modalProvider
    });
  }

  toggleProviderReg() {
    this.setState({
      modalProviderReg: !this.state.modalProviderReg
    });
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  };

  toggleMyChart() {
    this.setState({
      collapseMyChart: !this.state.collapseMyChart,
      collapseSingle: false
    });
  }

  toggleSingle() {
    this.setState({
      collapseSingle: !this.state.collapseSingle,
      collapseMyChart: false
    });
  }

  toggleMethod(e) {
    e.preventDefault();
    if (this.state.method === 'signup') {
      this.setState({
        method: 'login'
      });
    } else {
      this.setState({
        method: 'signup'
      });
    }
  }

  FBSuccess = (response) => {
    const { dispatch } = this.props
    let errMsg = ''
    dispatch(getFBProfile(response)).then(response => {
      if (this.props.Authenticate.sfidentity === 404 || this.props.Authenticate.sfidentity === 500) {
        alert('Account Error: Contact iAgree');
      } else {
        this.setState({
          success : this.props.Authenticate.sfidentity.user_single_file_identity ? this.props.Authenticate.sfidentity.user_single_file_identity.success : false,
          user: {
            status: this.props.Authenticate.sfidentity.user_single_file_identity ? this.props.Authenticate.sfidentity.user_single_file_identity.sf_auth_status_value : "No Account",
            email: this.props.Authenticate.sfidentity.user_single_file_identity ? this.props.Authenticate.sfidentity.user_single_file_identity.sf_user : "No Account",
            authType: 'OIDC Facebook'
          }
        });

        const oidc = this.props.Authenticate.idpList.find(x => x.idp_info === this.state.user.authType)
        let user = this.state.user
        user.OIDC = oidc
        dispatch(setUserDetails(user))

        if (this.state.success === true && this.state.method === 'signup') {
          this.props.history.push('/email')
        } else if (this.state.success === false && this.state.method === 'signup') {
          alert('Account already exists. Please sign in');
        } else if (this.state.success === true && this.state.method === 'login') {
          this.props.history.push('/mfa')
        } else if (this.state.success === false && this.state.method === 'login') {
          alert('Incorrect Username/Password');
          errMsg = 'Username/Password Incorrect'
        } else {
          alert('Account Error: Contact iAgree');
        }
        this.setState({ errorMessage: errMsg });
      }
    }, err => {
      alert('Account Error: Contact iAgree');
      console.log(err)
    })
  }

  GoogleSuccess = (response) => {
    const { dispatch } = this.props
    let errMsg = ''
    dispatch(getGoogleProfile(response)).then(response => {
      if (this.props.Authenticate.sfidentity === 404 || this.props.Authenticate.sfidentity === 500) {
        alert('Account Error: Contact iAgree');
      } else {
        this.setState({
          success : this.props.Authenticate.sfidentity.user_single_file_identity ? this.props.Authenticate.sfidentity.user_single_file_identity.success : false,
          user: {
            status: this.props.Authenticate.sfidentity.user_single_file_identity ? this.props.Authenticate.sfidentity.user_single_file_identity.sf_auth_status_value : "No Account",
            email: this.props.Authenticate.sfidentity.user_single_file_identity ? this.props.Authenticate.sfidentity.user_single_file_identity.sf_user : "No Account",
            authType: 'OIDC Google'
          }
        });

        const oidc = this.props.Authenticate.idpList.find(x => x.idp_info === this.state.user.authType)
        let user = this.state.user 
        user.OIDC = oidc
        dispatch(setUserDetails(user))

        if (this.state.success === true && this.state.method === 'signup') {
          this.props.history.push('/email')
        } else if (this.state.success === false && this.state.method === 'signup') {
          alert('Account already exists. Please sign in');
        } else if (this.state.success === true && this.state.method === 'login') {
          this.props.history.push('/mfa')
        } else if (this.state.success === false && this.state.method === 'login') {
          alert('Incorrect Username/Password');
          errMsg = 'Username/Password Incorrect'
        } else {
          alert('Account Error: Contact iAgree');
        }
        errMsg = 'Username/Password Incorrect'
      }
    }, err => {
      alert('Account Error: Contact iAgree');
      console.log(err)
    })
  }

  componentWillMount = () => {
      const { dispatch } = this.props
      dispatch(fetchIDPList({idpType: 'third party'}))
      this.state.user.status = ''
  }


  componentDidMount = () => {
    //document.addEventListener('FBObjectReady', this.initializeFacebookLogin);

    const { dispatch } = this.props
    dispatch(doLogoutUser())

    this.initFBLogin();
    this.initGoogleLogin();

  }

  componentDidUpdate = () => {
      const { Authenticate, dispatch } = this.props
      // if (Authenticate.isErr) {
      //     if (Authenticate.relying_parties.status === 404) {
      //         const oidc = Authenticate.idpList.find(x => x.idp_info === this.state.user.authType)
      //         let user = this.state.user
      //         user.OIDC = oidc
      //         dispatch(setUserDetails(user))
      //         this.setState({ nextStep: '/Email' })
      //     } else if (Authenticate.relying_parties.status === 200) {
      //         if (this.state.userExists === false) {
      //             this.setState({ userExists: true })
      //         }
      //     }
      // }
  }

  // componentWillUnmount = () => {
  //   document.removeEventListener('FBObjectReady', this.initializeFacebookLogin);
  // }

  /**
   * Before re-rendering screen, check authentication status and process accordingly
   * @param {object} nextProps    - properties to be applied to the view
   */
  componentWillReceiveProps = (nextProps) => {
    const { Authenticate } = nextProps
    let nextPage = ''
    let { isErr } = Authenticate
    if (Authenticate.provider !== undefined) {
      switch (isErr) {
        case true:
          let errMsg = ''
          switch (Authenticate.provider.status) {
            case "0":
              errMsg = 'Unknown Error'
              break
            case "2":
              errMsg = 'Username/Password Incorrect'
              break
            case "3":
              errMsg = 'Password Expired'
              break
            case "4":
              errMsg = 'Account Deactivated'
              break
            case "5":
              errMsg = 'Username/Password Incorrect'
              break
            case "6":
              errMsg = 'Account Expired'
              break
            case "7":
              errMsg = Authenticate.statusText
              break
            case "12":
              errMsg = 'Maximum Login Attempts Exceeded'
              break
            case "12":
              errMsg = 'Maximum Login Attempts Exceeded'
              break
            default:
              errMsg = 'Authentication Error Code: ' + Authenticate.status
              break
          }
          if (Authenticate.status === 401) {
            errMsg = 'Username/Password Incorrect'
          } else if (Authenticate.sfidentity !== undefined && Authenticate.sfidentity.status === 404) {
            errMsg = 'Username Not Found'
          }
          this.setState({ errorMessage: errMsg })
          break

        case false:
          if (Authenticate.sfidentity !== undefined && Authenticate.provider.user !== undefined) {
            // if (Authenticate.sfidentity === 404) {
            //   nextPage = '/ProviderIDVerification'
            // } else 
            if (Authenticate.sfidentity.user_single_file_identity !== undefined) {
              const sfi = Authenticate.sfidentity.user_single_file_identity
              if (sfi.sf_mfa_status_value === 'Active') {
                if (sfi.sf_mfa_type_value === 'DUO') {
                  nextPage = '/FacilityMFA'
                } else {
                  nextPage = '/MFA'
                }
              } else if (sfi.sf_mfa_status_value === 'locked out') {

              } else {
                nextPage = '/ProviderIDVerification'
              }
            }
          }
          break
        default:
          nextPage = ''
          break
      }
    }
    if (nextPage !== '') {
      this.setState({ nextPage: nextPage })
    }
  }

  /**
   * Process AD Authentication
   * @param {object} e    - login button
   */
  doLogin = e => {
    e.preventDefault();
    const { dispatch } = this.props
    dispatch(doUnifiedFacilityLogin({
      userName: this.state.email,
      password: this.state.password
    }))
  }

  /**
   * Update local state with username/password as they are entered
   * @param {object} e    - Form field being updated
   */
  handleChange = e => {
    if (e.target.value !== '') {
      let payload = {}
      payload[e.target.name] = e.target.value
      this.setState(payload)
    }
  }

  /**
   *
   * @constructor
   */
  initFBLogin() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId      : '194828327724010',
        cookie     : true,  // enable cookies to allow the server to access the session
        xfbml      : true,  // parse social plugins on this page
        version    : 'v5.0'
      });

      this.FB = window.FB;
      // Get state of person visiting this page
      window.FB.getLoginStatus(function(response) {
        //this.statusChangeCallback(response, false);
      }.bind(this));
    }.bind(this);

    // Load the SDK asynchronously
    (function(d, s, id, t) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        t.FB = window.FB;
        window.FB.getLoginStatus(function(response) {
          //t.statusChangeCallback(response, false);
        });
        return;
      }
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk', this));

  
  }

  /**
   *
   * @constructor
   */
  initGoogleLogin() {

    (() => {
      let e = document.createElement("script");
      e.type = "text/javascript";
      e.async = true;
      e.onload = () => {

        window.gapi.load('auth2', () => {

          window.gapi.auth2.init({
            client_id: '785357464736-5d613unogv6f76n3jrme09ts6ccqjgr6.apps.googleusercontent.com',
            scope: "profile"
            //scope: "https://www.googleapis.com/auth/plus.login"
          }).then(

            res => {
              // Listen for sign-in state changes.
              res.isSignedIn.listen(this.updateSigninStatus);
              // Handle the initial sign-in state.
              //this.updateSigninStatus(res.isSignedIn.get())
            },
            err => console.log('error')
          )
          this.gapi = window.gapi;
        })

      };

      e.src = "https://apis.google.com/js/platform.js";
      let t = document.getElementsByTagName("script")[0];
      t.parentNode.insertBefore(e, t);
    })();

  }

  /*GOOGLE FUNCTIONS*/
  updateSigninStatus = (isSignedIn) => {
    // When signin status changes, this function is called.
    // If the signin status is changed to signedIn, we make an API call.
    if (isSignedIn) {
      this.makeApiCall(this.state.method);
    }
  }

  onGoogleLogin = (event) => {
    event.preventDefault();
    this.googleLoginHandler();
  }

  onGoogleSignup = (event) => {
    this.googleLoginHandler();
  }

  googleLoginHandler = () => {
    // Ideally the button should only show up after gapi.client.init finishes, so that this
    // handler won't be called before OAuth is initialized.
    var GoogleAuth = this.gapi.auth2.getAuthInstance();

    if (GoogleAuth.isSignedIn.get()) {
      //this.makeApiCall(method);

      var options = new this.gapi.auth2.SigninOptionsBuilder();
      //options.setPrompt('select_account');
      options.setPrompt('consent');

      GoogleAuth.signIn(options).then(res => this.makeApiCall(this.state.method), err => console.log('error'))
    }
    else {
      GoogleAuth.signIn()
        .catch(function (err) {
          console.log('error')
        });
      //signin is handled by updateSigninStatus event listener
    }
  }

  handleSignOutClick = (event) => {
    this.gapi.auth2.getAuthInstance().signOut();
  }

  makeApiCall = (method) => {

    // Make an API call to Google's People API, and print the user's given name.
    var user = this.gapi.auth2.getAuthInstance().currentUser.get();
    var id_token = user.getAuthResponse().id_token;
    this.GoogleSuccess({'id_token': id_token, 'method': method});
    //this.props.history.push('/establishmfa')

  }

  /*FACEBOOK FUNCTIONS*/
  // This is called with the results from from FB.getLoginStatus().
  statusChangeCallback = (response) => {
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().
    if (response.status === 'connected') {
      // Logged into your app and Facebook.
      this.FBSuccess(response);
      //this.props.history.push('/establishmfa')

    } else if (response.status === 'not_authorized') {
      // The person is logged into Facebook, but not your app.
      console.log('not authorized')
    } else {
      // The person is not logged into Facebook, so we're not sure if
      // they are logged into this app or not.
      console.log('else')
    }
  }

  /**
   * Check login status and call login api if user is not logged in
   */
  onFacebookLogin = (event) => {
    event.preventDefault();
    this.facebookLoginHandler();
  }

  onFacebookSignup = (event) => {
    this.facebookLoginHandler();
  }

  /**
   * Handle login response
   */
  facebookLoginHandler = () => {
    if (!this.FB) {
      this.initFBLogin()
    }

    this.FB.getLoginStatus(response => {
      if (response.status === 'connected') {
        this.FB.login(response => {
          response.method = this.state.method;
          this.statusChangeCallback(response);
        }, {auth_type: 'reauthenticate', scope: 'email, public_profile'}); // check authResponse.reauthorize_required_in field
        //response.method = method;
        //this.statusChangeCallback(response);
        
        //this.props.history.push('/email')
      } else {
        this.FB.login(response => {
          response.method = this.state.method;
          this.statusChangeCallback(response);
        }, {auth_type: 'reauthenticate', scope: 'email, public_profile'});
      }
    }, );
  }

  onEmailLogin = (e) => {
    let email = this.state.email;
    let pw = this.state.password;

    var emailRegexCheck = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if (!emailRegexCheck.test(email)) {
      alert('The email address you supplied is not valid');
    } else {

      const { dispatch } = this.props
      dispatch(SFLogin({'email': email, 'password': pw})).then(response => {
        if (this.props.Authenticate.sfidentity === 404) {
          alert('Account Error: Contact iAgree');
        }
        this.setState({
          success : this.props.Authenticate.sfidentity.user_single_file_identity ? this.props.Authenticate.sfidentity.user_single_file_identity.success : false,
          errorMessage : this.props.Authenticate.sfidentity.user_single_file_identity ? this.props.Authenticate.sfidentity.user_single_file_identity.message : 'Incorrect Username/Password',
          user: {
            email: email,
            //password: pw,
            authType: 'Singlefile',
            status: this.props.Authenticate.sfidentity.user_single_file_identity ? this.props.Authenticate.sfidentity.user_single_file_identity.sf_auth_status_value : "No Account"
          }
        });
        let user = this.state.user
        user.OIDC = {idp_info: "Singlefile"}
        dispatch(setUserDetails(user))
        if (this.state.success === true) {
          this.props.history.push('/mfa')
        } else if (this.state.success === false) {
          alert(this.state.errorMessage);
        } else {
          alert('Account Error: Contact iAgree');
        }
      }, err => {
        alert('Account Error: Contact iAgree');
        console.log(err)
      })
    }
  }

  onEmailSignup = (e) => {
    let email = this.state.email;
    let pw = this.state.password;
    let confirm = this.state.confirm;

    var emailRegexCheck = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if (!emailRegexCheck.test(email)) {
      alert('The email address you supplied is not valid');
    } else if (pw !== confirm) {
      alert('The password does not match');
    } else if (pw.length < 8) {
      alert('The password must be at least 8 characters');
    } else {

      const { dispatch } = this.props
      dispatch(SFSignup({'email': email, 'password': pw})).then(response => {
        // if (this.props.Authenticate.sfidentity === 404) {
        //   alert('Account Error: Contact Single-FILE');
        // }
        this.setState({
          success : this.props.Authenticate.sfidentity.user_single_file_identity ? this.props.Authenticate.sfidentity.user_single_file_identity.success : false,
          user: {
            email: email,
            password: pw,
            authType: 'singlefile',
            status: this.props.Authenticate.sfidentity.user_single_file_identity ? this.props.Authenticate.sfidentity.user_single_file_identity.sf_auth_status_value : "No Account"
          }
        });
        let user = this.state.user
        user.OIDC = {idp_info: "Singlefile"}
        dispatch(setUserDetails(user))
        if (this.state.success === true) {
          this.props.history.push('/email')
        } else if (this.state.success === false) {
          alert('Account already exists. Please sign in');
        } else {
          alert('Account Error: Contact iAgree');
        } 
      }, err => {
        alert('Account Error: Contact iAgree');
        console.log(err)
      })
    }

  }

  onForgotPassword  = (email) => {
    this.props.history.push('/ForgotPassword')
  }


  render() {
    const { Authenticate, history } = this.props
    const facility = Authenticate.facility !== undefined ? Authenticate.facility.idp_friendly_name : 'ERR'
    const customEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))/;
    const customUsernameRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))/;
    const customPasswordRegex = /^[a-zA-Z0-9_@!#()]{1,}/;
    return (


      <Jumbotron>
        <Container>
          {/*<Row>
            {/*<Col className="text-center">
            <Col sm={{ size: 4, push: 1 }}>
              {/*<h1>iAgree</h1>
              <p className="subtxt">With iAgree you can manage how healthcare systems share your medical record data with researchers.</p>
            </Col>
          </Row>*/}

          <Row>
            <Col className="text-center txtS" sm={{ size: 11, push: 2  }}>
              <h1 className="subh1txt">iAgree</h1>
              {/*<hr className="homeHR" />*/}
              <p className="subtxt">With iAgree you can manage how health systems share your medical record data with researchers.</p>
            </Col>
          </Row>
          <br />

          {/*<hr className="homeHR" />*/}

          <Row>
            {/* <Col sm={{ size: 8, push: 2 }}> */}
            <Col>
              {/*<p>The mission of Single-FILE is to demonstrate how a single login ID can be used to access multiple electronic health record systems in a safe and secure manner.</p>*/}
              {/*<p>A MULTI-CENTER, NETWORKED PATIENT CONSENT STUDY - R01HG011066 </p>*/}              
            </Col>
          </Row>

          <Row>
            <Col sm={{ size: 4, push: 1 }}>
            { this.state.method === 'login' ? (
              <Form color="danger" autocomplete="off">
                <FormGroup controlId="formBasicEmail">
                  <Label for="email">Email:</Label>
                  <Input type="email" placeholder="lg" bsSize="lg" name="email" id="email" placeholder="myEmail@email.com" onChange={e => this.handleChange(e)}/>
                </FormGroup>

                <FormGroup controlId="formBasicPassword">
                  <Label for="password">Password:</Label>
                  <Input type="password" placeholder="lg" bsSize="lg" name="password" id="password" placeholder="**********" onChange={e => this.handleChange(e)}/>
                </FormGroup>
                <br />
                <Button color="primary" size="lg" block onClick={e => this.onEmailLogin(e)}> 
                  <i className="fa fa-sign-in fa-fw"></i>{" "} Log In
                </Button>

                <a href="#"  onClick={e => this.toggleMethod(e)}><p className="text-right">Forgot your password?</p></a>

                <p className="text-center orP"><font color="red">OR CONTINUE LOG IN WITH</font></p>
                <Row className="text-center">
                    <Col>
                        <SocialIcon className="googleIcon" onClick={this.onGoogleLogin} url="/" network="google" />{" "}
                        <SocialIcon className="fbIcon" onClick={this.onFacebookLogin} url="/" network="facebook" />
                        {/* "}<SocialIcon className="twitterIcon" network="twitter" />
                        {/*<SocialIcon network="linkedin" />*/}                                   
                    </Col>
                 </Row>
                 <hr />
                 <p className="text-center" color="#5CB85C"><font color="#5CB85C">Don't have an iAgree account?</font>
                 {/* <a href="#" onClick={e => this.toggleMethod(e)}>Sign up now!</a> */}
                 <Button color="success" size="lg" className="margin10" onClick={e => this.toggleMethod(e)} block> 
                  <i className="fa fa-sign-in fa-fw"></i>{" "} Sign Up Now
                 </Button></p>
                
              </Form>
              ) : (
              <Form color="danger">
                <FormGroup controlId="formBasicEmail">
                  <Label for="email">Email:</Label>
                  <Input type="email" placeholder="lg" bsSize="lg" name="email" id="email" placeholder="myEmail@email.com" onChange={e => this.handleChange(e)}/>
                </FormGroup>

                <FormGroup controlId="formBasicPassword">
                  <Label for="password">Password:</Label>
                  <Input type="password" placeholder="lg" bsSize="lg" name="password" id="password" placeholder="**********" onChange={e => this.handleChange(e)}/>
                </FormGroup>

                <FormGroup controlId="formBasicPassword">
                  <Label for="password">Re-type Password:</Label>
                  <Input type="password" placeholder="lg" bsSize="lg" name="confirm" id="confirm" placeholder="**********" onChange={e => this.handleChange(e)}/>
                </FormGroup>
                <br />
                <Button color="success" size="lg" block onClick={e => this.onEmailSignup(e)}> 
                  <i className="fa fa-sign-in fa-fw"></i>{" "}
                  Sign Up
                </Button>

                <br />
                <p className="text-center orP"><font color="red">OR CONTINUE SIGN UP WITH</font></p>
                <Row className="text-center">
                    <Col> 
                        <SocialIcon className="googleIcon" onClick={this.onGoogleLogin} url="/" network="google" />{" "}
                        <SocialIcon className="fbIcon" onClick={this.onFacebookLogin} url="/" network="facebook" />
                        {/*<SocialIcon className="twitterIcon" network="twitter" /-->{/*<SocialIcon network="linkedin" />*/}                                   
                    </Col>
                 </Row>
                 <hr />

                 {/*<ProviderLoginRegForm errorMessage={this.state.errorMessage} facility={facility} Authenticate={Authenticate} handleChange={this.handleChange} doLogin={this.doLogin} />*/}

                 <p className="text-center"><font color="#0275D8">Already have an iAgree account?</font>
                 {/* <a href="#" onClick={e => this.toggleMethod(e)}>Log In</a> */}
                 <Button color="primary" size="lg" className="margin10" onClick={e => this.toggleMethod(e)} block> 
                   <i className="fa fa-sign-in fa-fw"></i>{" "} Log In
                 </Button></p>
                
              </Form>
              )}
              <p className="text-center csTxt">&copy;{' '}{(new Date().getFullYear())}{' '}iAgree
              <br />
              <a href="/Terms">Terms and Conditions</a>|<a href="/Privacy">Statement of Privacy</a>
              </p>
            </Col>
          </Row>

          {/*<Row>
            <Col>
              <div className="text-center">
                <div>
                  <Button outline color="danger" onClick={this.toggleModal}>{/*<i className="fa fa-square-o fa-hover-hidden fa-fw fa-2x"></i><i className="fa fa-check-square-o fa-hover-show fa-fw fa-2x"></i>{" "}<i className="fa fa-user fa-3x" />{" "}<span>Get Started</span></Button>*/}

                  {/*<Button outline color="primary" onClick={this.toggleProvider}><i className="fa fa-square-o fa-hover-hidden fa-fw fa-2x"></i><i className="fa fa-check-square-o fa-hover-show fa-fw fa-2x"></i>{" "}<i className="fa fa-user-md fa-3x" />{" "}<span>Provider Portal</span></Button>*/}

                  {/*<h4 className="text-center"> -- or -- </h4>
                  <Button><SocialIcon network="google" />{" "}<SocialIcon network="facebook" />{" "}<SocialIcon network="twitter" /></Button>
                  {/*<p><SocialIcon network="google" />{" "}<SocialIcon network="facebook" />{" "}<SocialIcon network="twitter" /></p>*/}

                  {/*<br />
                  <Button onClick={this.toggleProvider}>Log in</Button>{" "}
                  <Button onClick={this.toggleProviderReg}>Sign Up</Button>

                  <Modal isOpen={this.state.modalProvider} toggle={this.toggleProvider} className={this.props.className}>
                    <ModalHeader toggle={this.toggleProvider} className="providerMHead">Log In to iAgree</ModalHeader>
                    <ModalBody className="providerMBody">
                      <span>
                        {this.state.nextPage !== '' &&
                          <Redirect push to={this.state.nextPage} />
                        }
                        <ProviderLoginForm errorMessage={this.state.errorMessage} facility={facility} Authenticate={Authenticate} handleChange={this.handleChange} doLogin={this.doLogin} />
                      </span>
                    </ModalBody>
                    <br />
                    <ModalFooter className="providerMFooter">
                      --<Button color="primary" onClick={this.toggleProvider} block><i className="fa fa-sign-in fa-fw"></i>Login</Button>
                    </ModalFooter>
                  </Modal>

                  <Modal isOpen={this.state.modalProviderReg} toggle={this.toggleProviderReg} className={this.props.className}>
                    <ModalHeader toggle={this.toggleProviderReg} className="providerMHead">Sign up to iAgree</ModalHeader>
                    <ModalBody className="providerMBody">
                      <span>
                        {this.state.nextPage !== '' &&
                          <Redirect push to={this.state.nextPage} />
                        }
                        <ProviderLoginRegForm errorMessage={this.state.errorMessage} facility={facility} Authenticate={Authenticate} handleChange={this.handleChange} doLogin={this.doLogin} />
                      </span>
                    </ModalBody>
                    <br />
                    <ModalFooter className="providerMFooter">
                      --<Button color="primary" onClick={this.toggleProvider} block><i className="fa fa-sign-in fa-fw"></i>Login</Button>
                    </ModalFooter>
                  </Modal> */}

                {/*</div>
                <LoginModal
                  showModal={this.state.showModal}
                  toggleModal={this.toggleModal}
                  onLoginFacebook={this.onFacebookLogin}
                  onLoginGoogle={this.onGoogleLogin}
                  onLoginEmail={this.onEmailLogin}
                  onSignupFacebook={this.onFacebookSignup}
                  onSignupGoogle={this.onGoogleSignup}
                  onSignupEmail={this.onEmailSignup}
                  onForgotPassword={this.onForgotPassword}
                  emailRegex={customEmailRegex}
                  usernameRegex={customUsernameRegex}
                  passwordRegex={customPasswordRegex}
                  labels={{
                      loginTitle: "Log in",
                      signupTitle: "Create new user",
                      forgotTitle: "Reset password",
                      loginFacebookButton: "Log in with Facebook",
                      loginGoogleButton: "Log in with Google",
                      loginEmailButton: "Log in with iAgree",
                      signupFacebookButton: "Sign up with Facebook",
                      signupGoogleButton: "Sign up with Google",
                      signupEmailButton: "Sign up with iAgree",
                      forgotButton: "Send new password",
                      loginEmailPlaceholder: "Type Email",
                      loginPasswordPlaceholder: "Type Password",
                      signupUsernamePlaceholder: "Confirm Email",
                      signupLink: "Create new user?",
                      loginLink: "Already a user?",
                      forgotLink: "",
                      orLabel: "or"
                  }}
                />
              </div>
            </Col>
          </Row>*/}
        </Container>
      </Jumbotron>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  Authenticate: state.Authenticate
})


export default withRouter(connect(mapStateToProps)(Home))