import { combineReducers } from 'redux'
import Authenticate from './Authenticate'
import AuthChallenge from './AuthChallenge'
import IDVerification from './IDVerification'
import SSO from './SSO'

const appReducer = combineReducers({
    Authenticate: Authenticate,
    AuthChallenge: AuthChallenge,
    IDVerification: IDVerification,
    SSO: SSO
})

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined
      }

      return appReducer(state,action)
}
export default rootReducer
