import * as types from '../actions/ActionTypes'

export default function doAuth(state = [], action) {
    switch (action.type) {
        case types.SET_SESSION_EXPIRY:
            return Object.assign({}, state, {
                expiry: action.expiry
            })
        case types.CLEAR_SESSION_EXPIRY:
            return Object.assign({}, state, {
                expiry: {}
            })
        case types.AUTH_SF_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                userID: action.userID
            })
        case types.AUTH_SF_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false
            })
        case types.AUTH_SF_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true
            })
        case types.IDP_LIST_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                idpList: action.idpList
            })
        case types.IDP_LIST_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                idpList: action.idpList.identity_providers.idps
            })
        case types.IDP_LIST_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                idpList: action.payload
            })
        case types.USER_FAC_RP_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                ufrp: action.user_facility_relying_parties
            })
        case types.USER_FAC_RP_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                ufrp: action.payload.user_facility_relying_parties.relying_parties
            })
        case types.USER_FAC_RP_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                ufrpErr: action
            })
        case types.USER_ACCT_CHECK_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                relying_parties: action.payload
            })
        case types.USER_ACCT_CHECK_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                relying_parties: action.user_relying_parties
            })
        case types.USER_ACCT_CHECK_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                relying_parties: {
                    status: action.payload.status,
                    statusText: action.payload.statusText
                }
            })
        case types.USER_ACCT_CHECK_CLEAR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                relying_parties: undefined
            })
        case types.USER_ACCT_SETPROFILE:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                user_profile: action.payload
            })
        case types.USER_ACCT_SETEMAIL:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                user_email: action.payload
            })

        case types.USER_ACCT_UPDATEEMAIL_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                updateEmailReq: action.payload,
                updateEmailErr: undefined,
                updateEmail: undefined
            })
        case types.USER_ACCT_UPDATEEMAIL_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                updateEmailReq: undefined,
                updateEmailErr: undefined,
                updateEmail: 'success'
            })
        case types.USER_ACCT_UPDATEEMAIL_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                updateEmailErr: {
                    status: action.payload.status,
                    message: action.payload.data.message
                },
                updateEmail: undefined
            })
        case types.MYCHART_SET_FACILITY:
            return Object.assign({}, state, {
                facility: action.facility
            })
        case types.AUTH_MYCHART_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false
            })
        case types.AUTH_MYCHART_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                patient: action.payload.patient
            })
        case types.AUTH_MYCHART_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                patient: {
                    status: action.payload.status,
                    message: action.payload.data.message
                }
            })
        case types.AUTH_MYCHART_CLEAR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                patient: true
            })
        case types.AUTH_MYCHARTVALIDATE_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                mychartVerify: action.payload
            })
        case types.AUTH_MYCHARTVALIDATE_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                mychartVerify: action.payload.MyChartContext
            })
        case types.AUTH_MYCHARTVALIDATE_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                mychartVerify: action.payload.MyChartContext
            })
        case types.AUTH_SF_IDENTITY_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                sfidentity: action.payload
            })
        case types.AUTH_SF_IDENTITY_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                sfidentity: action.payload
            })
        case types.AUTH_SF_IDENTITY_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                sfidentity: action.payload
            })
        case types.INSERT_SF_IDENTITY_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                sfidentity: action.payload
            })
        case types.INSERT_SF_IDENTITY_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                sfidentity: action.payload
            })
        case types.INSERT_SF_IDENTITY_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                sfidentity: action.payload
            })
        case types.INSERT_SF_AUTH_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                sfauth: action.payload
            })
        case types.INSERT_SF_AUTH_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                sfauth: action.payload
            })
        case types.INSERT_SF_AUTH_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                userInsertErr: {
                    status: action.payload.status,
                    statusText: action.payload.statusText
                },
                sfauth: action.payload
            })
        case types.INSERT_SF_MFA_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                sfmfa: action.payload
            })
        case types.INSERT_SF_MFA_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                sfmfa: action.payload
            })
        case types.INSERT_SF_MFA_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                userInsertErr: {
                    status: action.payload.status,
                    statusText: action.payload.statusText
                },
                sfmfa: action.payload
            })
        case types.CHECK_RP_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                relying_parties: action.payload
            })
        case types.CHECK_RP_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                relying_parties: action.payload.user_relying_parties.relying_parties
            })
        case types.CHECK_RP_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                relying_parties: {
                    status: action.payload.status,
                    statusText: action.payload.statusText
                }
            })
        case types.USER_AVAIL_RP_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                availRp: action.payload
            })
        case types.USER_AVAIL_RP_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                availRp: action.payload.user_available_relying_parties.relying_parties
            })
        case types.USER_AVAIL_RP_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                availRp: {
                    status: action.payload.status,
                    statusText: action.payload.statusText
                }
            })
        case types.USER_REMOVE_RP_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                removeRP: action.payload
            })
        case types.USER_REMOVE_RP_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                removeRP: true
            })
        case types.USER_REMOVE_RP_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                removeRP: {
                    status: action.payload.status,
                    statusText: action.payload.statusText
                }
            })
        case types.USER_REMOVE_RP_CLR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                removeRP: undefined
            })
        case types.USER_ADD_RP_REQ:
            return Object.assign({}, state, {
                addRP: action.payload
            })
        case types.USER_ADD_RP_RECV:
            return Object.assign({}, state, {
                addRP: true
            })
        case types.INSERT_SF_RP_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                sfrp: action.payload
            })
        case types.INSERT_SF_RP_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                sfrp: action.payload
            })
        case types.INSERT_SF_RP_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                userInsertErr: {
                    status: action.payload.status,
                    statusText: action.payload.statusText
                },
                sfrp: action.payload
            })
        case types.AUTH_PATIENT_SEARCH_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
            })
        case types.AUTH_PATIENT_SEARCH_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                patient: action.payload.patient
            })
        case types.AUTH_PATIENT_SEARCH_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                patient: {
                    status: action.payload.status,
                    message: action.payload.data.message
                }
            })
        case types.OAUTH2_PATIENT_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                patient: action.payload.patient
            })
        case types.OAUTH2_PATIENT_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                patient: {
                    status: action.payload.status,
                    message: action.payload.data.message
                }
            })
        case types.AUTH_WPR_SEARCH_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                wpr: action.payload
            })
        case types.AUTH_WPR_SEARCH_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                wpr: action.payload.patient
            })
        case types.AUTH_WPR_SEARCH_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                wpr: action.payload
            })
        case types.PROVIDER_SET_FACILITY:
            return Object.assign({}, state, {
                facility: action.facility
            })
        case types.AUTH_PROVIDER_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                provider: action.payload
            })
        case types.AUTH_PROVIDER_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                provider: action.payload.user_facility_auth_context,
                xtoken: action.payload.xtoken
            })
        case types.AUTH_PROVIDER_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                status: action.payload.response.status,
                statusText: action.payload.response.statusText
            })
        case types.AUTH_PROVIDERVERIFY_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                providerVerify: action.payload
            })
        case types.AUTH_PROVIDERVERIFY_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                providerVerify: action.payload.user_facility_auth_context
            })
        case types.AUTH_PROVIDERVERIFY_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                providerVerify: action.payload
            })
        case types.AUTH_PROVIDERVERIFY_CLR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                providerVerify: undefined
            })
        case types.SET_AUTH_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                AuthSuccess: action.payload
            })
        case types.LOGOUT_USER:
            return {}
        case types.CLOSE_ACCOUNT_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false,
                CloseAccount: action.payload
            })
        case types.CLOSE_ACCOUNT_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                CloseAccount: ""
            })
        case types.CLOSE_ACCOUNT_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                CloseAccount: action.payload.response
            })

        default:
            return state
    }
}