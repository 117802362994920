//#17
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import axios from 'axios'
import { REST_URL } from '../constants/Config'
import { checkRelyingParties, checkPatientRelyingParties, SetAuthSuccess } from '../actions/Authenticate'
import { createSSOContext, createPatientSSOContext } from '../actions/SSO.js'
//import ProgressBar from '../components/nav/ProgressBar'
import ProviderMainMenu from '../components/ProviderMainMenu'
import PatientMainMenu from '../components/PatientMainMenu'
import '../components/global/Main.css'

/**
 * Account Home - Screen 17
 */
class AccountHome extends Component {
  /**
   * Constructor for class
   * @param {object} props 
   * 
   */
  constructor(props) {
    super(props)
    this.state = {
      accountType: '',
      formAction: '',
      rpId: '',
      SAMLResponse: '',
      targetWindow:'',
      loggedOut: false,
      ssoDone: false
    }

    this.handleSSOClick = this.handleSSOClick.bind(this)
  }

  /**
   * determine what kind of user
   */
  componentWillMount = () => {
    const { dispatch, Authenticate } = this.props
    let processToken = false
    if (Authenticate.AuthSuccess !== undefined) {
      processToken = Authenticate.AuthSuccess.ProcessToken
      this.setState({
        accountType: Authenticate.AuthSuccess.accountType
      })
    } else {
      this.setState({ loggedOut: true })
    }
    if (Authenticate.sfidentity !== undefined) {
      dispatch(checkRelyingParties({
      }))
      if (processToken === true) {
        const { AuthSuccess } = Authenticate
        dispatch(SetAuthSuccess(Object.assign({}, AuthSuccess, {
          ProcessToken: false
        })))
      }
    }
  }

  componentDidUpdate = () => {
    const { SSO, Authenticate } = this.props
    if (SSO.createSSO !== undefined && this.state.rpId !== '' && (SSO.isFetching === false && SSO.isErr === false)) {
      if (this.state.SAMLResponse !== SSO.createSSO) {
        const formAction = (this.state.accountType === 'provider' || this.state.accountType === 'patient') ? SSO.createSSO.ssoLink : Authenticate.relying_parties.find(x => x.relying_party_id === this.state.rpId).relying_party_connectivity
        this.setState({ SAMLResponse: SSO.createSSO.saml, formAction: formAction })
      }
    }

    if (this.state.SAMLResponse !== '' && this.state.formAction !== '' && this.state.ssoDone !== true) {
      this.setState({ ssoDone: true })
      document.getElementById('ssoForm').submit()
      this.setState({
        formAction: '',
        SAMLResponse: '',
        rpId: '',
        ssoDone: false
      })
    }

  }

  /**
   *  @param {string} rpId  - relying party ID
   */
  handleSSOClick = () => {
    const { dispatch, Authenticate } = this.props
    // this.setState({ rpId: rpId, targetWindow: 'ssoWindow'+rpId})
    // if (this.state.accountType === 'provider') {
    //   window.open('about:blank', 'ssoWindow'+rpId)
    //   dispatch(createSSOContext({
    //     rpId: rpId
    //   }))
    // } else if (this.state.accountType === 'patient') {
      //window.open('about:blank', 'ssoWindow'+rpId)
        dispatch(createPatientSSOContext({
          uiId: Authenticate.sfidentity.user_single_file_identity.sf_user_identity_id
        }))
    //}
  }





  render() {
   // const navPath = (this.state.accountType === 'provider') ? 'providerAccount' : 'patientAccount'
    const relyingParties = this.props.Authenticate !== undefined ? this.props.Authenticate.relying_parties !== undefined ? this.props.Authenticate.relying_parties : [] : []
    return (
      <div className='AccountHome'>
        {/*<ProgressBar navPath={navPath} />*/}
        {this.state.loggedOut === true ?
          <Redirect push to="/" />
        :
        this.state.accountType === 'provider' ? (
          <ProviderMainMenu
            newUser={this.props.Authenticate.AuthSuccess.newUser}
            relyingParties={relyingParties}
            handleSSOClick={this.handleSSOClick}
            targetWindow={this.state.targetWindow}
            SAMLResponse={this.state.SAMLResponse}
            formAction={this.state.formAction}
          />
        ) : (
            <PatientMainMenu
              newUser={this.props.Authenticate.AuthSuccess.newUser}
              relyingParties={relyingParties}
              handleSSOClick={this.handleSSOClick}
              targetWindow={this.state.targetWindow}
              SAMLResponse={this.state.SAMLResponse}
              formAction={this.state.formAction}
            />
          )
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  Authenticate: state.Authenticate,
  SSO: state.SSO
})

export default connect(mapStateToProps)(AccountHome)