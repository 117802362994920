//#10
import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import InputField from '../components/common/InputField'
import RadioField from '../components/common/RadioField'
import { Jumbotron, Container, Col, Row, Button, Alert, Card, CardText } from 'reactstrap';
import './global/Main.css'

class EmailForm extends Component {
    render() {
        const { pathway, defaultEmail } = this.props
        const defEmailStr = 'Use ' + defaultEmail + ' as my contact email address for iAgree'
        const justEmailStr = defaultEmail 

        return (   
           <div className="EmailForm">
            <Jumbotron>
                <Container>
                    <h1 className="text-center">iAgree Contact Email</h1>
                    <hr className="my-3" />
                    <p>Please select an email address for iAgree notifications.</p>
                    <Row>
                        <Col sm="6">
                        <Card className="email1" outline color="secondary">
                            <CardText>
                                {(pathway === 'patient' || pathway === 'patientfacility') && ''}
                                {defaultEmail &&
                                    <RadioField fieldName="defEmlorAlt" fieldValue={defaultEmail} fieldLabel={justEmailStr} fieldID="defEml" handleChange={this.props.onRadioUpdate} checked="1" />
                                }
                            </CardText>
                        </Card>    
                        </Col>
                        <Col sm="6">  
                        <Card className="email1" outline color="secondary">  
                            <CardText >
                                <RadioField fieldName="defEmlorAlt" fieldValue="alternate" fieldLabel="Use another email address" fieldID="altEml" handleChange={this.props.onRadioUpdate} checked="0" />
                            </CardText>    
                            <CardText>
                                <InputField fieldName="email" fieldID="email" fieldLabel="Email address" fieldPlaceholder="Email address" fieldClassname='fa fa-envelope-o fa-fw' handleChange={this.props.updateEmail} />
                                <br />
                                <InputField fieldName="emailVerify" fieldID="emailVerify" fieldLabel="Verify email address" fieldPlaceholder="Verify email address" fieldClassname='fa fa-envelope fa-fw' handleChange={this.props.updateEmail} />

                                { this.props.err && <Alert color="danger">{this.props.err}</Alert> }
                            </CardText>
                        </Card>                                  
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button id="emailSubmit" type="submit" size="lg" color="primary" onClick={this.props.submitEmail}>Continue{" "}<i className="fa fa-angle-double-right" /></Button>                          
                        </Col>    
                    </Row>    
                </Container>
             </Jumbotron>   
            </div>      
        );
    }

}

/**
 * @type {Object}
 * @property {object} Authenticate      - Authenticate store (required)
 * @property {string} defaultEmail      - default iAgree email pulled from user account info
 * @property {string} pathway           - type of user account being registered
 * @property {function} onRadioUpdate   - handler for click of radio button (required)
 * @property {function} updateEmail     - handler for entering email in fields (required)
 * @property {string} err               - error message
 * @property {function} submitEmail     - handler for clicking Continue button (required)
 */

EmailForm.PropTypes = {
    defaultEmail: PropTypes.string.isRequired,
    pathway: PropTypes.string.isRequired,
    onRadioUpdate: PropTypes.func.isRequired,
    updateEmail: PropTypes.func.isRequired,
    err: PropTypes.string,
    submitEmail: PropTypes.func.isRequired
}

export default EmailForm;