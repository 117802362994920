import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from './common/Loader';
import InputField from '../components/common/InputField'
import { Jumbotron, Container, Row, Col, Button, Form, FormGroup, Label, Input, Badge, Card, CardText, Alert } from 'reactstrap';
/**
 * ID Verification - Personal Info Container (#10) - 
 * Fields to enter SSN, DOB, Zip, MRN to verify identity
 */
class IDVCode extends Component {
    constructor(props) {
        super(props);
        this.handleBackClick = this.handleBackClick.bind(this);
    }
    handleBackClick(){
        this.props.Authenticate.isErr = false;
        this.props.Authenticate.isFetching = false;
        window.history.go(0);
    }
    render() {
        const errMsg = this.props.Authenticate.isErr;
        var errorMessage = '';
        if (errMsg) {
            errorMessage = this.props.Authenticate.patient ? this.props.Authenticate.patient.message : 'Failed to add patient record. Please contact Single-FILE.';
        }
        const isFetching = this.props.Authenticate.isFetching
        return (
            <Jumbotron className="IDVCode">
                <Container>
                    <Row>
                        <Col>

                            {isFetching && <Loader msg="Looking up patient information..." /> }

                            <h1 className="text-left">Patient Identity Verification <Badge color="success" pill>Step 3/4</Badge></h1>
                            <hr className="my-2" />
                            <p>In order to verify access to your patient record, please enter the activation code you received, the last four digits of your SSN, and your date of birth.</p>

                            <form>
                                <Row form>    
                                    <Col md={5}>
                                        <p>Activation Code:</p>
                                        <FormGroup style={{display: 'inline-flex'}}>
                                          <Input autoComplete="off" type="text" name="activCode1" id="activCode1" placeholder="xxxxx" style={{ width: '75px', height: "33px", fontSize: ".8rem" }} onChange={this.props.onChange}/><i className="fa fa-minus"></i>
                                          <Input autoComplete="off" type="text" name="activCode2" id="activCode2" placeholder="xxxxx" style={{ width: '75px', height: "33px", fontSize: ".8rem" }} onChange={this.props.onChange}/><i className="fa fa-minus"></i>
                                          <Input autoComplete="off" type="text" name="activCode3" id="activCode3" placeholder="xxxxx" style={{ width: '75px', height: "33px", fontSize: ".8rem" }} onChange={this.props.onChange}/>
                                        </FormGroup>
                                    </Col>
                                </Row> 
                                <Row form>    
                                    <Col md={5}>
                                        <FormGroup>
                                          <Label for="activCode">Social Security Number (Last Four Digits):</Label>
                                          <Input autoComplete="off" type="number" name="ssn" id="ssn" placeholder="xxxx" style={{ width: '75px', fontSize: ".8rem", height: "33px"  }} onChange={this.props.onChange}/>
                                        </FormGroup>
                                    </Col>
                                </Row>       
                                <Row form>  
                                    <Col md={3}>
                                        <FormGroup>
                                          <Label for="dob">Date of Birth:</Label>
                                          <Input autoComplete="off" type="date" name="dob" id="dob" placeholder="Date of Birth" style={{fontSize: ".8rem", height: "33px" }} onChange={this.props.onChange}/>
                                        </FormGroup>
                                    </Col>    
                                </Row>
                                <Row>
                                    <Col>
                                        {errMsg && <Alert color="danger">{errorMessage}</Alert>}
                                    </Col>
                                </Row>
                                <Row from>    
                                    <Col>    
                                        <Button id="backBtn" size="lg" color="info" onClick={this.handleBackClick}><i className="fa fa-angle-double-left" />{" "}Back </Button>    
                                        <Button color="primary" size="lg" id="idpSubmit" onClick={this.props.onSubmit}>Continue{" "}<i className="fa fa-angle-double-right" /></Button>
                                    </Col>
                                </Row>     
                            </form>
                        </Col>
                    </Row>     
                </Container>
            </Jumbotron>
        );
    }
}

IDVCode.PropTypes = {
    Authenticate: PropTypes.object.isRequired,
    ssn: PropTypes.string.isRequired,
    zip: PropTypes.string,
    dob: PropTypes.string,
    activation: PropTypes.string,
    cshsmrn: PropTypes.string,
    crimrn: PropTypes.string,
    errorMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
}

export default IDVCode;
