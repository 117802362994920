import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import './Loader.css'

/**
 * Loader - common component displays loading icon w/optional message when service calls are being made
 */
class Loader extends Component {

    render() {
        return (
            <div>
                <div className="loading">
                </div>
                {this.props.msg &&
                    <div className="loadingMsg">{this.props.msg}</div>
                }
            </div>
        )
    }

}

Loader.PropTypes = {
    msg: PropTypes.string
}

export default Loader
