import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Row, Col, InputGroup, InputGroupAddon } from 'reactstrap';

class InputField extends Component {
    constructor(props) {
        super(props);
        this.state = { showWarning: true }
    }

    render() {
        const { fieldName, fieldValue, fieldID, /*fieldLabel,*/ fieldType, fieldPlaceholder, fieldClassname, handleChange } = this.props
        const fType = fieldType !== '' ? fieldType : 'text'
        //console.log(JSON.stringify(this.props))
        return (
            <Row>
                <Col>
                    <InputGroup>
                        <InputGroupAddon>
                            <i className={fieldClassname} />
                        </InputGroupAddon>    
                        <Input className='formInput' name={fieldName} value={fieldValue} id={fieldID} type={fType} onBlur={this.props.handleBlur} placeholder={fieldPlaceholder} onChange={handleChange} />
                    </InputGroup>
                </Col>
            </Row>
        );
    }

}

InputField.PropTypes = {
    fieldName: PropTypes.string.isRequired,
    fieldValue: PropTypes.string,
    fieldID: PropTypes.string.isRequired,
    //fieldLabel: PropTypes.string,
    fieldType: PropTypes.string,
    fieldPlaceholder: PropTypes.string,
    fieldClassname: PropTypes.string,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func
}

export default InputField
