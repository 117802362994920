import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types';
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import InputField from '../components/common/InputField'
import { Jumbotron, Container, Row, Col, Button, Form, FormGroup, Label, Input, Badge, Card, CardText, Alert } from 'reactstrap';
/**
 * ID Verification - Personal Info Container (#10.F) - 
 * Fields to enter Healthcare facility, SSN, and DOB
 */

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Jumbotron className="ForgotPassword">
                <Container>
                    <Row>
                        <Col>
                            <h1 className="text-center">Password Reset</h1>
                            <hr className="my-2" />
                            <p>Please select the Healthcare Organization you last visited.</p>
                        </Col>
                    </Row>
                    <Row>    
                        <Col>
                            <AvForm>
                                <AvRadioGroup name="checkHeathcare" label="Healthcare Organizations" validate={{
                                            required: {value: true, errorMessage: 'Select at least one of Healthcare Organization'}
                                          }} >
                                      <AvRadio label="Cedars-Sinai" value="1" />{/*<img className="CSselect" src={require("../images/CSlogo.png")} />*/}
                                      <AvRadio label="California Rehabilitation Institute" value="2" />{/*<img className="CRIselect" src={require("../images/CRI-logo.png")} />*/}
                                </AvRadioGroup>
                                <AvField name="numberSSN" label="Social Security Number (Last Four Digits)" type="number" validate={{
                                            required: {value: true},
                                            minLength: {value: 4, errorMessage: 'SSN must be at least 4 digits long'},
                                            maxLength: {value: 4, errorMessage: 'SSN must be not exceeded 4 digits long'}
                                          }} />
                                <AvField name="dateDOB" label="Date of Birth" type="date" validate={{date: {format: 'MM/DD/YYYY'}}} placeholder="MM/DD/YYYY" validate={{required: {value: true}}} />
                                <Button color="danger" size="lg">Submit</Button>
                            </AvForm>
                        </Col>
                    </Row>     
                </Container>
            </Jumbotron>
        );
    }
}

export default ForgotPassword;
