import React, { Component } from 'react'
import { Jumbotron, Container, Row, Col, Button } from 'reactstrap';
import { PropTypes } from 'prop-types'


/**
 * Profile Account Editor content (#21)
 */
class ProfileAccountForm extends Component {
    render() {
        return (
            <Jumbotron>
                <Container>
                    <Row>
                        <Col>
                            <h3>Account</h3>
                            <hr className="my-0" />
                            <p style={{marginTop: '6px'}}>iAgree Account ID: {this.props.sfId}</p>
                            <hr className="my-0" />
                            <br />
                            <p><b>Update iAgree Contact Email Address</b></p>
                            <hr className="my-0" />
                            <label htmlFor='emailchange'>Email</label><br/><input value={this.props.email} id="emailchange" name="emailchange" onChange={this.props.updateEmail} /><br/>
                            <Button id="updateEmailBtn" size="sm" color="warning"
                                onClick={this.props.changeEmail}>Update Contact Email
                            </Button>
                            <br/>
                            <p><b>Login Accounts Associated with iAgree</b></p>
                            <hr className="my-0" />
                            <Button id="continueBtn" size="lg" color="warning"
                            onClick={this.props.closeModal}>Close Window
                            </Button>

                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        )
    }

}

ProfileAccountForm.PropTypes = {
    sfId: PropTypes.string.isRequired,
    email: PropTypes.string.string,
    updateEmail: PropTypes.func.isRequired,
    changeEmail: PropTypes.func.isRequired
}

export default ProfileAccountForm

