import React, { Component } from 'react';
var zxcvbn = require('zxcvbn');
import './PasswordStrengthMeter.css';

class PasswordStrengthMeter extends Component {

  createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return 'Very Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  }

  render() {
    const { password } = this.props
    const testedResult = zxcvbn(password);

    return (
      <div className="password-strength-meter">
        <progress
          className={`password-strength-meter-progress strength-${this.createPasswordLabel(testedResult)}`}
          value={testedResult.score}
          max="4"
        />
        <label className="password-strength-meter-label">
          <strong>Password strength: </strong> 
          <span className={`font-${this.createPasswordLabel(testedResult)}`}>{this.createPasswordLabel(testedResult)}</span>
        </label>
      </div>
    );
  }
}

export default PasswordStrengthMeter;