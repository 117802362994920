import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { clearRelyingParties, checkRelyingParties, createPatientIdentity, createSingleFileAuthentication, createSingleFileMFA, createSingleFileRelyingParty, SetAuthSuccess } from '../actions/Authenticate'
import { patientfetchAuthChallenge, patientfetchAuthChallengeStatus, clearMFA } from '../actions/AuthChallenge'
//import ProgressBar from '../components/nav/ProgressBar'
import ProgressBar from '../components/nav/ProgressBar';
import OTPVerifyForm from '../components/OTPVerifyForm';

/**
 * OTPVerify (#12) - Process the OTP verification to validate a new user's identity.
 */
class OTPVerify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otpCode: '',
            errorMessage: '',
            insertUserStep: ''
        }
        this.handleOTPValidate = this.handleOTPValidate.bind(this)
        this.resendMFA = this.resendMFA.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const { dispatch, IDVerification, AuthChallenge } = nextProps
        let errorMsg = ''
        if (IDVerification.otpValidate !== undefined) {
            if (IDVerification.otpValidate.success === 'false') {
                this.setState({ errorMessage: 'The one-time passcode did not match.' })
            }
        }

        if (AuthChallenge.authCreate !== undefined) {
            if (AuthChallenge.authCreate.success === 'false') {
                errorMsg = AuthChallenge.authCreate.message
            }
        }

        if (AuthChallenge.authData !== undefined) {
            if (AuthChallenge.authData.user_auth_factor_validate !== undefined) {
                if (AuthChallenge.authData.user_auth_factor_validate.success === 'false') {
                    errorMsg = AuthChallenge.authData.user_auth_factor_validate.message
                }
            }

            if (AuthChallenge.authData.user_auth_factor_validate !== undefined) {
                if (AuthChallenge.authData.user_auth_factor_validate.success === 'true') {
                    const results = AuthChallenge.authData.user_auth_factor_validate
                    this.setState({
                        insertUserStep: 'checkRelyingParty',
                        errorMessage: ''
                    })
                    dispatch(clearMFA())
                }
            }
        }

        if (this.state.errorMessage !== errorMsg)
          this.setState({ errorMessage: errorMsg })
    }

    componentWillUpdate = (nextProps, nextState) => {
        // loop through the insertUserStep possibilities
        if (nextState.insertUserStep === 'checkRelyingParty') {
          this.checkRelyingParties(nextProps)
        }
        if (nextState.insertUserStep === 'verifyRelyingParty') {
          this.verifyRelyingParties(nextProps)
        }
        if (nextState.insertUserStep === 'validateSuccess') {
          this.validateSuccess(nextProps)
        }
    }

  /**
   * resend MFA verification code
   */
  resendMFA() {
    const { dispatch, AuthChallenge } = this.props
    const payload = {
      userId: AuthChallenge.authCreate.user,
      mfaType: 'singlefile',
      authFactorType: AuthChallenge.authCreate.authFactorType
    }
    AuthChallenge.authData = undefined
    dispatch(patientfetchAuthChallenge(payload))
        .then(response => {
            if (AuthChallenge.authCreate.success === 'true') {
                this.setState({ errorMessage : 'New one-time passcode sent' })
            } else {
                this.setState({ errorMessage : 'The one-time passcode could not be sent.' })
            }
        });
    }

   /**
    * Initiate a check to see if a relying party record exists
    */
    checkRelyingParties = (nextProps) => {
        const { dispatch, Authenticate, AuthChallenge } = nextProps
        let processToken = true
        if (AuthChallenge.authData.user_auth_factor_validate !== undefined) {
            processToken = !AuthChallenge.authData.user_auth_factor_validate.success
        }
        dispatch(createPatientIdentity({
            password: Authenticate.user_profile.password,
            mfa_type: 'TWILIO',
            mfa_type_id: AuthChallenge.authData.user_auth_factor_validate.user,
            idpId: Authenticate.facility.idp_id,
            addRP: Authenticate.addRP ? Authenticate.addRP.idp_id : undefined
        }))

        .then(response => {
            this.setState({ insertUserStep: '' })

            if (this.props.Authenticate.isFetching === false && this.props.Authenticate.isErr === false) {
                this.validateSuccess()
            } else {
                this.setState({ 
                    insertUserStep: '',
                    errorMessage: 'Error occurred while creating user account'
                })
            }
        }, err => {
            this.setState({ insertUserStep: '',
                errorMessage: 'Error occurred while creating user account'
            })
        })
        this.setState({ insertUserStep: '' })
    }

    /**
     * if record exists, prompt to add to existing account, otherwise proceed with creating a new Single-FILE identity
     */
    verifyRelyingParties = (nextProps) => {
        const { dispatch, Authenticate, AuthChallenge } = nextProps
        const relyingParties = Authenticate.relying_parties
        this.setState({
            insertUserStep: ''
        })
        if (Authenticate.isFetching === false) {
            if (Authenticate.isErr === true) {
                if (Authenticate.relying_parties.status === 404) {
                    let tempQuery = {
                        password: Authenticate.user_profile.password,
                        mfa_type: 'TWILIO',
                        mfa_type_id: AuthChallenge.authCreate.user,
                        idpId: Authenticate.facility.idp_id,
                        addRP: Authenticate.addRP.idp_id
                    }
                    dispatch(createPatientIdentity({
                        password: Authenticate.user_profile.password,
                        mfa_type: 'TWILIO',
                        mfa_type_id: AuthChallenge.authCreate.user,
                        idpId: Authenticate.facility.idp_id,
                        addRP: Authenticate.addRP.idp_id
                    }))
                    .then(response => {
                        this.setState({
                                insertUserStep: ''
                            })
                        if (this.props.Authenticate.isFetching === false && this.props.Authenticate.isErr === false) {
                            this.validateSuccess()
                        } else {
                            this.setState({
                                insertUserStep: ''
                            })
                            this.setState({ errorMessage: 'Error occurred while creating user account'})
                        }
                    });
                } else if (relyingParties[0] !== undefined) {
                    this.setState({ insertUserStep: 'userExistsConfirm' })
                }
            }
        }
    }

    validateSuccess = () => {
        const { dispatch } = this.props
        dispatch(SetAuthSuccess({
            accountType: 'patient',
            newUser: true,
            ProcessToken: true
        }))
        this.setState({
            insertUserStep: '',
            stepComplete: true,
            nextStep: '/SelectFacility2'
        })
    }

    updateOTPVerifyField = e => {
        return this.setState({ otpCode: e.target.value })
    }

    handleOTPValidate = otp => {
        const { dispatch, Authenticate, AuthChallenge, IDVerification } = this.props
        dispatch(patientfetchAuthChallengeStatus({
            authFactorType: AuthChallenge.authCreate.authFactorType,
            verificationCode: this.state.otpCode,
            user: AuthChallenge.authCreate.user,
            mfaType: 'singlefile',
            patientID: Authenticate.patient.patientID,
            patientIDType: Authenticate.patient.patientIDType,
            status: this.props.Authenticate.user_profile.status,
            idp_id: this.props.Authenticate.facility.idp_id
        }))
    }

    render() {
        const { IDVerification, AuthChallenge } = this.props
        return (

                <div>
                    {this.props.Authenticate.patient === undefined &&
                        <Redirect push to="/IDVerification" />
                    }
                    {this.state.stepComplete === true ? (
                        <div>
                            <Redirect push to="/SelectFacility2" />
                        </div>
                    ) : (
                            <div>
                                <ProgressBar navPath='newUser'/>
                                <OTPVerifyForm
                                    IDVerification={this.props.IDVerification}
                                    errorMessage={this.state.errorMessage}
                                    onChange={this.updateOTPVerifyField}
                                    onOTPValidate={this.handleOTPValidate}
                                    resendMFA={this.resendMFA} />
                            </div>
                        )}
                </div>    
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    AuthChallenge: state.AuthChallenge,
    Authenticate: state.Authenticate,
    IDVerification: state.IDVerification
})

export default connect(mapStateToProps)(OTPVerify)
