//#5
import React, { Component } from 'react';
import Loader from './common/Loader';
import InputField from '../components/common/InputField'
import { Jumbotron, Container, Row, Col, Form, Button, Alert, Badge } from 'reactstrap'
import '../components/global/Main.css'


class MyChartLoginForm extends Component {
    constructor(props) {
        super(props);
        this.handleBackClick = this.handleBackClick.bind(this);
    }
    handleBackClick(){
        this.props.Authenticate.isErr = false;
        this.props.Authenticate.isFetching = false;
        window.history.go(0);
    }
    render() {
        const errMsg = this.props.Authenticate.isErr;
        var errorMessage = '';
        if (errMsg) {
            errorMessage = this.props.Authenticate.patient ? this.props.Authenticate.patient.message : 'Failed to add patient record. Please contact Single-FILE.';
        }
        const { facility } = this.props
        const { isFetching } = this.props.Authenticate
        return (
            <Jumbotron className="IDMyChart">
                <Container>
                    <Row>
                        <Col>
                            {isFetching && <Loader msg="Logging you in..." /> }

                            <h1 className="text-left">Patient Identity Verification <Badge color="success" pill>Step 3/4</Badge></h1>
                            <hr className="my-2" />
                            <p><b>Healthcare Organization: {" "}<span>{this.props.Authenticate.facility.idp_friendly_name}</span></b></p>
                            <br />
                            <Row>     
                                <Col sm="12" md={{ size: 8, offset: 2 }}>
                                    <Form onSubmit={this.props.doLogin}>
                                        <InputField fieldName='email' fieldId='email' fieldLabel='Email address: ' handleChange={this.props.handleChange} fieldClassname='fa fa-envelope-o fa-fw' fieldPlaceholder={this.props.Authenticate.facility.idp_info + ' Username'} /><br />
                                        <InputField fieldName='password' fieldId='password' fieldType='password' fieldLabel='Password: ' handleChange={this.props.handleChange} fieldClassname='fa fa-key fa-fw' fieldPlaceholder={this.props.Authenticate.facility.idp_info + ' Password'} /><br />
                                        
                                        {errMsg && <Alert color="danger">{errorMessage}</Alert>}

                                        <Button id="backBtn" size="lg" color="info" onClick={this.handleBackClick}><i className="fa fa-angle-double-left" />{" "}Back </Button>  
                                        <Button color="primary" size="lg" type='submit' onClick={this.props.doLogin}>Sign In</Button>
                                    </Form>
                                </Col>    
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        );
    }
}

export default MyChartLoginForm;
