import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { LogoutUser } from '../actions/Authenticate'
import { Jumbotron, Container, Row, Col } from 'reactstrap'


/**
 * Close Account Complete Screen - Screen 23
 */
class AccountClosed extends Component {

    constructor(props) {
        super(props)
        this.state = {
            timer: null,
            counter: 15
        }

        this.tick = this.tick.bind(this)
    }

    /**
     * logout
     */
    componentWillMount = () => {
        const { dispatch } = this.props
        dispatch(LogoutUser())
    }

    componentDidMount = () => {
        let timer = setInterval(this.tick, 1000)
        this.setState({ timer })
    }

    componentWillUnmount = () => {
        clearInterval(this.state.timer)
    }

    tick = () => {
        this.setState({
            counter: this.state.counter - 1
        })
    }



    render() {
        return (
           <Jumbotron className="AccountClosed">
                <Container>
                    {this.state.counter == 0 && <Redirect push to='/' />}
                    <Row>
                        <Col> 
                            <h1 className="text-danger text-center">Account Closed</h1>
                            <br />
                            <hr className="homeHR" />
                            <br />
                            <p className="text-center">You have successfully closed your account. You will receive an email confirmation as well.</p>

                            <p className="text-center">If you decide to reactivate your iAgree account at some point in the future, you will be able to re-register through this website.</p>

                            <p className="text-center">You will be redirected to the iAgree home page in <font color="danger">{this.state.counter}</font> second.</p>
                        </Col>
                    </Row> 
                </Container>
            </Jumbotron>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(AccountClosed)