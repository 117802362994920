import React, { Component } from 'react'
import Loader from './common/Loader'
import InputField from './common/InputField'
import { Jumbotron, Container, Col, Row, Button, Modal, Alert } from 'reactstrap';

import RadioField from './common/RadioField'
import FacilityMFAValidateForm from './FacilityMFAValidateForm'


/**
 * ProviderAddFacilityLoginForm - Provider AD login as first step to adding new facility to their account (#19B)
 */
class ProviderAddFacilityLoginForm extends Component {

    render() {
        const { errorMessage, facility, availRP, doLogin, closeModal, handleLoginChange, addFacilityStep, doMFA, mfaOptions, validateMFA, validateMFACode, cancelAdd } = this.props
        const { isFetching } = this.props.Authenticate
        const facName = availRP.find(x => x.idp_id === facility).facility_name
        var devices = mfaOptions.map((device, index) => {
            var d = device.device
            var options = device.capabilities.map((item) => {
                return (
                    <RadioField key={item.key} fieldName='duo' fieldValue={item.mfaFieldValue} fieldLabel={item.mfaFieldLabel} fieldID={item.mfaFieldValue} handleChange={this.props.handleMFASelection(d)} />
                )
            });
            return (
                <div>
                    <br></br>
                    <p key={index}>{device.number}</p>
                    {options}
                </div>
            )
        });
        return (
            <Jumbotron>
                <Container>
                    <Row>
                        <Col>
                            {isFetching &&
                                <Loader msg='Verifying Credentials...' />
                            }
                            <h4>You can add a facility using your existing medical facility account.</h4>
                            <br />
                            <div className="ProviderLoginOption">
                                <div className='optionLabel'>Selected Facility: {facName}</div>
                            </div>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {addFacilityStep === 'login' &&
                                <form onSubmit={doLogin}>
                                    <InputField fieldName='email' fieldId='email' fieldLabel='Email Address: ' fieldPlaceholder='Email address' fieldClassname='fa fa-envelope-o fa-fw' handleChange={handleLoginChange} /><br />
                                    <InputField fieldName='password' fieldId='password' fieldType='password' fieldLabel='Password: ' fieldPlaceholder='Password' fieldClassname='fa fa-key fa-fw' handleChange={handleLoginChange} /><br />

                                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                                    
                                    <Button color='primary' size='lg' type='submit' onClick={doLogin}>Sign In</Button>{" "}<Button color='secondary' size='lg'  onClick={cancelAdd}>Cancel</Button>
                                </form>
                            }
                            {addFacilityStep === 'mfa' &&
                                <div>
                                    <form>
                                        <p>For your security, please select a method of second factor authentication.</p>
                                        {devices}
                                        <Button color="primary" size="lg" id="mfaSubmit" type="submit" onClick={doMFA}>Continue »</Button> <Button color='secondary' size='lg' onClick={cancelAdd}>Cancel</Button>
                                    </form>

                                    <Modal isOpen={validateMFACode === true} onRequestClose={closeModal} contentLabel='Verify Selection'>
                                        <div>
                                            <FacilityMFAValidateForm formState={this.props}
                                                errorMessage={errorMessage}
                                                validateMFA={validateMFA}
                                                resetMFA={this.props.resetMFA}
                                                AuthChallenge={this.props.AuthChallenge}
                                                onMFACodeChange={this.props.onMFACodeChange}
                                            />
                                        </div>
                                    </Modal>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>

        )
    }
}

export default ProviderAddFacilityLoginForm
