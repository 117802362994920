//#13
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import InputElement from 'react-input-mask';
import Modal from 'react-modal'

import MFAForm from './MFAForm'
import Loader from './common/Loader';
import CheckboxField from './common/CheckboxField'
import { Jumbotron, Container, Button, Alert} from 'reactstrap';
import './MFAForm.css';

class EstablishMFAForm extends Component {
    render() {
        const { formState } = this.props
        const { isFetching } = this.props.AuthChallenge
        const  isAuthFetching = this.props.Authenticate.isFetching

        return (
            <Jumbotron>
                <Container className="mfa">
                <h2 className="text-center">Second Factor Authentication</h2>
                <hr className="my-2" />
                {(this.props.AuthChallenge.isFetching || isAuthFetching) &&
                    <Loader />
                }
                {this.props.insertUserErr !== '' &&
                    <span className='err'>{this.props.insertUserErr}</span>
                }             
                <p>To provide an extra layer of security for your account, you are required to enroll a second factor of authentication. Please select one or more options.</p>
                <div>
                    <CheckboxField fieldName='mfaOptions' handleClick={this.props.handleClick} fieldValue='phone' fieldLabel='Use Phone Number' fieldID='phone' isChecked={formState.phone} /><br />
                    {formState.phone === true &&
                        <div className='phoneOptions'>
                            Enter Phone Number: <InputElement onChange={this.props.handlePhoneInput} className='phoneMask' mask="(999) 999-9999" /> {formState.phoneErr === true && <span className='err'>phone number required</span>}<br />
                            Select one or more methods of authentication:<br />
                            {formState.methodSuccess.sms === true ? (
                                <span>
                                    &#10004; SMS Text Message Verification<br />
                                </span>
                            ) : (
                                    <span>
                                        <CheckboxField fieldName='phoneOptions' handleClick={this.props.handleOptionsClick} fieldValue='sms' fieldLabel='SMS Text Message Verification' fieldID='sms' isChecked={formState.phoneOptions.sms} /><br />
                                    </span>
                                )}
                            {formState.methodSuccess.call === true ? (
                                <span>
                                    &#10004; Phone Call and Passcode Verification<br />
                                </span>
                            ) : (
                                    <span>
                                        <CheckboxField fieldName='phoneOptions' handleClick={this.props.handleOptionsClick} fieldValue='call' fieldLabel='Phone Call and Passcode Verification' fieldID='call' isChecked={formState.phoneOptions.call} /><br />
                                    </span>
                                )}

                            {formState.methodSuccess.push === true ? (
                                <span>
                                    &#10004; Authy OneTouch App (Push Notifications and Token Codes)<br />
                                </span>
                            ) : (
                                    <span>
                                        <CheckboxField fieldName='phoneOptions' handleClick={this.props.handleOptionsClick} fieldValue='push' fieldLabel='Authy OneTouch App (Push Notifications and Token Codes)' fieldID='push' isChecked={formState.phoneOptions.push} /><br />
                                     </span>
                                )}
                        </div>
                    }
                    <CheckboxField fieldName='mfaOptions' handleClick={this.props.handleClick} fieldValue='googleauth' fieldLabel='Google Authenticator' fieldID='googleauth' isChecked={formState.googleauth} /><br />

                </div>
                <br/> 
                <div>
                    { this.props.errorMessage !== '' && <Alert color="danger">{this.props.errorMessage}</Alert> }  
                    <Button id="mfaSetSubmit" size="lg" color="primary" onClick={this.props.handleContinue}>Continue{" "}<i className="fa fa-angle-double-right" /></Button>
                </div>

                <Modal isOpen={formState.validateMFA === true} onRequestClose={this.props.closeModal} contentLabel='Verify Selection'>
                    <div>
                        <MFAForm formState={formState} errorMessage={this.props.errorMessage} resendMFA={this.props.resendMFA} onMFACodeChange={this.props.onMFACodeChange} validateMFA={this.props.validateMFA} />
                    </div>
                </Modal>
                </Container>
            </Jumbotron>
        );
    }

}

EstablishMFAForm.PropTypes = {
    formState: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
    handOptionsClick: PropTypes.func.isRequired,
    validateMFA: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    closeModal: PropTypes.func,
    AuthChallenge: PropTypes.object,
    resendMFA: PropTypes.func.isRequired

}

export default EstablishMFAForm;
