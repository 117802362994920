import React, { Component } from 'react';
import { Jumbotron, Container, Row, Col } from 'reactstrap';
import '../components/global/AboutFaq.css'

/**
 * About
 */

class Faq extends Component {
  /**
   * Constructor for class
   * @param {object} props 
   * 
   */
    render() {
      return (
          <Jumbotron className="afc">
            <Container>
              <Row className="text-center">
                <Col>
                  <h2>Frequently Asked Questions</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p><b>Q. Which health care systems are participating in iAgree?</b><br />
                     <b>A. </b>The health care systems that currently participate in iAgree are:
                                <ul>
                                  <li>Cedars-Sinai Medical Center</li>
                                  <li>UCHealth University of Colorado Hospital</li>
                                  <li>University of California Davis Health</li>
                                  <li>University of California Irvine Health</li>
                                  <li>University of California San Diego Health</li>
                                  <li>University of California San Francisco Health</li>
                                  <li>University of Southern California</li>
                                </ul></p>
                  <p><b>Q. Can I use iAgree to make decisions about how to share my data across different health systems?</b><br />
                     <b>A. </b>Yes, once you make decisions about which types of data you would like to share with researchers and for which types of research projects, these settings will be saved for all of the participating institutions. If you change your mind, you can change your sharing preferences at any time.</p>
                  <p><b>Q. How long will iAgree be available?</b><br />
                     <b>A. </b>iAgree is a pilot project currently funded through 2021. Before the end of the project, the participating health care systems will evaluate the platform and will consider expanding it to other facilities.</p>
                  <p><b>Q. Does iAgree track my activity?</b><br />
                     <b>A. </b>Cookies are used so you do not have to re-register as a new user.</p>
                  <p><b>Q. What information does iAgree store about me?</b><br />
                     <b>A. </b>iAgree stores your email address and the names of the health care systems where you may have medical records.</p>
                  
                  {/*<p><b>Q. </b>Can I still log into my medical facility directly without Single-FILE?<br />
                     <b>A. </b>Yes, but you will need to use your facility’s credentials. </p>
                  <p><b>Q. </b>How long will Single-FILE be available?<br />
                     <b>A. </b>This is a pilot project that is currently funded through August 2019. Before the end of the project, an assessment will be made on<br /><span>continuing and possibly expanding Single-FILE to other facilities. You will be notified if any changes to the project occur.</span></p>
                  <p><b>Q. </b>What information about me does Single-FILE store?<br />
                     <b>A. </b>Single-FILE only stores your contact email address, medical facilities username, and list of associated facilities and their multi-factor<br /><span>authentication vendors that you will be accessing via Single-FILE. Single-FILE will not store your password.</span></p>
                  <p><b>Q. </b>Does Single-FILE track my activity?<br />
                     <b>A. </b>Cookies are used so you do not have to re-register as a new user. <br /><br />
                               <span>Single-FILE does not track your actions within the EHR. However, as part of the pilot which is currently funded through August 2019,</span><br /><span>the Single-FILE project team will ask each facility to produce EHR usage data regarding how access is being utilized.</span><br /><br />
                               <span>Your IP address, date and time of login, and accessed facilities are recorded to assist with the evaluation of the pilot.</span></p>*/}
                </Col>
              </Row>
            </Container>  
              <p className="text-center csTxt text-white">&copy;{' '}{(new Date().getFullYear())}{' '}iAgree{' '}
                <a href="/Terms" className="text-white">Terms and Conditions</a>|<a href="/Privacy" className="text-white">Statement of Privacy</a>
              </p>
          </Jumbotron>    
      )
    }
  }

export default Faq;
