import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import ProgressBar from '../components/nav/ProgressBar';
import IDVerificationForm from '../components/IDVerificationForm';
import IDVPersonalInfo from '../components/IDVPersonalInfo';
import IDVCode from '../components/IDVCode';
import MyChartLoginForm from '../components/MyChartLoginForm';
//import { doPatientSearch, receivePatient } from '../actions/IDVerification'
import { doMyChartLogin, doPatientSearch, PatientSearchReceive } from '../actions/Authenticate'

/**
 * ID Verification Container (#10) - 
 * Verifies user identity based on medical facility records
 */
class IDVerification extends Component {
    /**
     * Constructor for class
     * @param {object} props 
     */
    constructor(props) {
        super(props);
        /**
       * @property {string} displayCard        - which step in verification process to display
       * @property {object} personalInfo       - Form fields to track for verifying identity
       */
        this.state = {
            displayCard: "main",
            personalInfo: {
                ssn: '',
                zip: '',
                dob: '',
                cshsmrn: '',
                crimrn: ''
            },
            email: '',
            password: '',
            errorMessage: '',
            mychartAuthValid: ''
        }

        this.props.Authenticate.isErr = false;
        this.handlePatientLookup = this.handlePatientLookup.bind(this)
        this.handleDisplayCard = this.handleDisplayCard.bind(this)
        this.updateInfoField = this.updateInfoField.bind(this)
        this.handleMyChartChange = this.handleMyChartChange.bind(this)
        this.doMyChartLogin = this.doMyChartLogin.bind(this)
    }

    /**
     * Update local state when fields are updated
     * @param {object} e    - field being acted upon
     */
    updateInfoField = e => {
        const field = e.target.name
        const personalInfo = this.state.personalInfo
        personalInfo[field] = e.target.value
        return this.setState({ personalInfo: personalInfo })
    }

    /**
     * Lookup patient record based on data inputted
     * @param {object} personalinfo     - form data (unused)
     */
    handlePatientLookup = personalinfo => {
        const patient = this.state.personalInfo
        let patientQuery = {
            SSN: patient.ssn,
            idp_id: this.props.Authenticate.facility.idp_id,
            PatientIDType:"EPI",
            WebUserIDType:"WEBUSER",
            addRP: this.props.Authenticate.addRP ? this.props.Authenticate.addRP.idp_id : undefined
        }

        if (patient.cshsmrn !== '') {
            patientQuery.PatientID = patient.cshsmrn
        } else {
            patientQuery.DOB = patient.dob
            //patientQuery.PostalCode = patient.zip
            patientQuery.ActivationCode = patient.activCode1 + '-' + patient.activCode2 + '-' + patient.activCode3
        }
        this.props.dispatch(doPatientSearch(patientQuery))
    }

    /**
     * Updates which card is being displayed
     * @param {string} displaycard  - card to be displayed 
     */
    handleDisplayCard(displaycard) {
        this.setState({
            displayCard: displaycard
        });
    }

    handleMyChartChange = e => {
        if (e.target.value !== '') {
            let payload = {}
            payload[e.target.name] = e.target.value
            this.setState(payload)
        }
    }

    doMyChartLogin = (e) => {
        e.preventDefault();
        const { dispatch } = this.props
        dispatch(doMyChartLogin({
            WebAccountUsername: this.state.email,
            WebAccountPassword: this.state.password,
            idp_id: this.props.Authenticate.facility.idp_id,
            addRP: this.props.Authenticate.addRP ? this.props.Authenticate.addRP.idp_id : undefined
        }))
    }

    render() {
        const cardState = this.state.displayCard;
        const { IDVerification, Authenticate } = this.props
        const patientRetrieved = (Authenticate.patient !== undefined) ? Authenticate.patient.phoneNumbers ? true : false : false

        let screen = null;
        /**
         * Show different screens, input fields, the OTP code validation, and TBA MyChart-related items
         */
        switch (cardState) {
            case "personalinfo":
                screen = <IDVPersonalInfo Authenticate={this.props.Authenticate} IDVerification={this.props.IDVerification} onChange={this.updateInfoField} personalInfo={this.state.personalInfo} onSubmit={this.handlePatientLookup} />
                break
            case "code":
                screen = <IDVCode Authenticate={this.props.Authenticate} errorMessage={this.state.errorMessage} onUpdateDisplayCard={this.handleDisplayCard} IDVerification={this.props.IDVerification} onChange={this.updateInfoField} personalInfo={this.state.personalInfo} onSubmit={this.handlePatientLookup} />
                break
            case "mychart":
                const { Authenticate } = this.props
                const facility = Authenticate.facility !== undefined ? Authenticate.facility.name === 'csmc' ? 'Cedars-Sinai Medical Center' : Authenticate.facility.name = 'cri' ? 'California Rehabilitation Institute' : '' : ''
                screen = <MyChartLoginForm errorMessage={this.state.errorMessage} facility={facility} Authenticate={Authenticate} handleChange={this.handleMyChartChange} doLogin={this.doMyChartLogin} />
                break
            default:
                screen = <IDVerificationForm 
                            Authenticate={this.props.Authenticate}
                            onUpdateDisplayCard={this.handleDisplayCard} />
                break
        }
        return (

            <div>
                {patientRetrieved ? (
                    <div>
                        <Redirect push to="/OTP" />
                    </div>
                ) : (
                        <div>
                        <ProgressBar navPath='newUser'/>
                        {screen}
                        </div>
                    )}
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate,
    IDVerification: state.IDVerification
})



export default connect(mapStateToProps)(IDVerification)
