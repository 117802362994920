import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { SetAuthSuccess } from '../actions/Authenticate'
import { fetchMFAOptions, patientfetchAuthChallenge, patientfetchAuthChallengeStatus } from '../actions/AuthChallenge'
//import ProgressBar from '../components/nav/ProgressBar'
import PatientMFAForm from '../components/PatientMFAForm'
import MFAForm from '../components/MFAForm'
import '../components/global/Main.css'

/**
 * MFA Verification screen (#14) - 
 * Initiates the multi-factor authentication step so user can verify identity.
 */
class MFA extends Component {
  /**
   * Constructor for class
   * @param {object} props 
   * 
   */
  constructor(props) {
    super(props)
    /**
     * @property {string} inProgress        - set to MFA type while in progress
     * @property {string} verificationCode  - code entered by user
     * @property {string} errorMessage      - error message string
     * @property {boolean} stepComplete     - set to true when validation succeeds
     * @property {string} phoneNumber       - device number used for MFA
     */
    this.state = {
      inProgress: '',
      verificationCode: null,
      mfaType: '',
      errorMessage: '',
      stepComplete: false,
      phoneNumber: '',
      authFactorType: '',
      selectedMethod: 'SMS'
    }

    this.validateMFA = this.validateMFA.bind(this)
    this.handleSelection = this.handleSelection.bind(this)
    this.handleMFAChange = this.handleMFAChange.bind(this)
    this.resendMFA = this.resendMFA.bind(this)
  }

  /**
   * Following screen refresh, check if validation was successful, or if a new code needs to be generated.
   * @param {*} prevProps 
   * @param {*} prevState 
   */
  componentDidUpdate(prevProps, prevState) {
    const { dispatch, Authenticate, AuthChallenge } = this.props
    const { inProgress } = this.state

    //if we haven't set the phone number yet to local state, populate everything we need to send a challenge.
    if (this.state.phoneNumber === '' && AuthChallenge.mfaOptions !== undefined) {
      this.setState({
        phoneNumber: AuthChallenge.mfaOptions.status.phoneNumber,
        inProgress: AuthChallenge.mfaOptions.status.devices,
        mfaType: AuthChallenge.mfaOptions.status.detailedDevices.deviceType
      })
    }

    //if we haven't done the auth challenge yet, DO IT.
    if (inProgress !== '') {
      const payload = {
        userId: Authenticate.sfidentity.user_single_file_identity.sf_mfa_type_id,
        mfaType: 'singlefile',
        authFactorType: inProgress
      }
      dispatch(patientfetchAuthChallenge(payload))
      this.setState({ inProgress: '' })
    }

    //Couldn't send the challenge?
    if (AuthChallenge.isErr === true && AuthChallenge.errorMsg) {
      this.setState({ errorMessage: AuthChallenge.errorMsg })
    }

    //Did the Challenge work?
    if (AuthChallenge.authData !== undefined) {
      if (AuthChallenge.authData.user_auth_factor_validate) {
        if (AuthChallenge.authData.user_auth_factor_validate.success === 'true') {
          dispatch(SetAuthSuccess({
            accountType: 'patient',
            ProcessToken: false,  // check if new account or not
            newAccount: false
          }))
          this.setState({ stepComplete: true })
        }
      }
    }

  }

  handleSelection = param => e => {
    this.setState({ selectedMethod: e.target.value })
    //this.setState({device: param})
  }

  /**
   * Fetch the initial auth challenge
   */
  handleSubmit = () => {
    const { dispatch, Authenticate, AuthChallenge } = this.props
    const { selectedMethod } = this.state
    console.log(this.state)
    if (selectedMethod === '') {
      this.setState({ errorMessage: 'Please select a method before continuing.' })
    } else {
      this.setState({ errorMessage: '' })
      switch (selectedMethod) {
        case 'Push':
          this.setState({ loadMsg: 'Please check your registered mobile device for notification' })
          break
        case 'SMS':
          this.setState({ loadMsg: 'Sending text message to your device' })
          break
        case 'Call':
          this.setState({ loadMsg: 'Calling your registered phone number to complete verification' })
          break
        case 'Passcode':
          this.setState({ loadMsg: 'Please check the Authy app on your mobile device to get verification code' })
          break
        default:
          this.setState({ loadMsg: '' })
          break
      }

      if (selectedMethod === 'Passcode') {
        this.setState({ validateMFA: true })
      }
      if (selectedMethod !== 'Passcode') {
        const payload = {
          userId: Authenticate.sfidentity.user_single_file_identity.sf_mfa_type_id,
          mfaType: 'singlefile',
          authFactorType: selectedMethod
        }
        dispatch(patientfetchAuthChallenge(payload))
          .then(response => {
              if ((selectedMethod === 'SMS' || selectedMethod === 'Call') && this.props.AuthChallenge.authCreate.success === 'true') {
                  this.setState({ validateMFA: true })
              }
          });
      }
    }
  }

  /**
   * Initiate initial auth challenge
   */
  initiateAuthChallenge = () => {
    const { dispatch, Authenticate, AuthChallenge } = this.props
    const payload = {
      userId: Authenticate.sfidentity.user_single_file_identity.sf_mfa_type_id,
      mfaType: 'singlefile',
      authFactorType: this.state.selectedMethod
    }
    dispatch(patientfetchAuthChallenge(payload))
      .then(response => {
        if (AuthChallenge.authCreate.success === 'true') {
            this.setState({ errorMessage : 'New MFA code sent' })
        } else {
            this.setState({ errorMessage : 'The one-time passcode could not be sent.' })
        }
      });

  }

  /**
   * resend MFA verification code
   */
  resendMFA() {
    this.initiateAuthChallenge()
    this.setState({ errorMessage: 'New MFA code sent' })
  }

  /**
   * Check verification code match on button submit
   */
  validateMFA = e => {
    const { dispatch, Authenticate } = this.props
    const { selectedMethod } = this.state
    e.preventDefault();
    dispatch(patientfetchAuthChallengeStatus({
      authFactorType: selectedMethod,
      verificationCode: this.state.verificationCode,
      user: Authenticate.sfidentity.user_single_file_identity.sf_mfa_type_id,
      mfaType: 'singlefile', 
      status: this.props.Authenticate.user_profile.status
    }))
  }

  /**
   * Update container's state with verification code as it's being entered
   */
  handleMFAChange = e => {
    this.setState({ 'verificationCode': e.target.value })
  }


  render() {
    //const hasKey = (obj, key) => key in obj;
    const { AuthChallenge } = this.props
    const err = AuthChallenge.isErr ? this.state.errorMessage !== '' ? this.state.errorMessage : "Identity Verification failed." : ""
    return (
      <div className="MFA">
        {this.state.stepComplete === true &&
          <Redirect push to='/SelectFacility2' />
        }
        {this.props.AuthChallenge.authData === undefined **
          <Redirect push to='/' />
        }
        <PatientMFAForm
          formState={this.state}
          Authenticate={this.props.Authenticate}
          loadMsg={this.state.loadMsg}
          errorMessage={err}
          resendMFA={this.resendMFA}
          handleSelection={this.handleSelection}
          processSelection={this.handleSubmit}
          onMFACodeChange={this.handleMFAChange}
          validateMFA={this.validateMFA} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  Authenticate: state.Authenticate,
  AuthChallenge: state.AuthChallenge
})



export default connect(mapStateToProps)(MFA)
