import * as types from '../actions/ActionTypes'

export default function doIDVerification(state = [], action) {
    switch (action.type) {
        case types.PATIENT_LOOKUP:
         return Object.assign({}, state, {
            isFetching: true,
            isErr: false
        })
        case types.PATIENT_LOOKUP_RECV:
         return Object.assign({}, state, {
            isFetching: false,
            isErr: false,
            patient: action.patient
        })
        case types.PATIENT_LOOKUP_ERR:
         return Object.assign({}, state, {
            isFetching: false,
            isErr: true
        })
        case types.OTP_REQUEST:
         return Object.assign({}, state, {
            isFetching: true,
            isErr: false,
            otp: {},
            otpNumber: action.payload.phoneNumber,
            otpMethod: action.payload.verifyMethod
        })
        case types.OTP_RECV:
         return Object.assign({}, state, {
            isFetching: false,
            isErr: false,
            otp: action.data.identity_verification_status
        })
        case types.OTP_ERR:
         return Object.assign({}, state, {
            isFetching: false,
            isErr: true
        })
        case types.OTP_CLEAR:
         return Object.assign({}, state, {
            isFetching: false,
            isErr: false,
            otp:{}
        })
        case types.OTP_VERIFY_REQUEST:
         return Object.assign({}, state, {
            isFetching: true,
            isErr: false
        })
        case types.OTP_VERIFY_RECV:
         return Object.assign({}, state, {
            isFetching: false,
            isErr: false,
            otpValidate: action.data.identity_verification_status
        })
        case types.OTP_VERIFY_ERR:
         return Object.assign({}, state, {
            isFetching: false,
            isErr: true
        }) 
         default:
         return state;
    }
}