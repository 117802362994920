//#11
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
//import { requestOTP } from '../actions/IDVerification'
import { doCreateAuthChallenge, fetchAuthChallenge } from '../actions/AuthChallenge'
import ProgressBar from '../components/nav/ProgressBar'
import OTPForm from '../components/OTPForm'

class OTP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            otpMethod: '',
            mobilePhone: {
                display: '',
                idx: ''
            },
            voicePhone: {
                display: '',
                idx: ''
            },
            voiceWPhone: {
                display: '',
                idx: ''
            }

        };
        this.displayMobile = this.displayMobile.bind(this)
        this.displayVoice = this.displayVoice.bind(this)
        this.displayWVoice = this.displayWVoice.bind(this)
        this.handleOTPRequest = this.handleOTPRequest.bind(this)
    }

    componentDidMount() {
        this.setState({
            mobilePhone: this.displayMobile(),
            voicePhone: this.displayVoice(),
            voiceWPhone: this.displayWVoice()
        })
    }

    componentWillReceiveProps(nextProps) {
        const { AuthChallenge } = nextProps
        if (AuthChallenge.authCreate !== undefined) {
            if (AuthChallenge.authCreate.success === 'false') {
                this.setState({ errorMessage: 'The one-time passcode could not be sent.' })
            }
        // } else if (AuthChallenge.errorMsg === "Unknown Error") {
        //     this.setState({ errorMessage: 'The OTP code could not be sent.' })
        // }
        }
    }

    updateOTPField = e => {
        return this.setState({ otpMethod: e.target.value });
    }

    handleOTPRequest = otp => {
        const { dispatch, errorMessage } = this.props
        const otpMethod = this.state.otpMethod
        let payload = {}
        switch (otpMethod) {
            case 'smsMobile':
                payload = {
                    authFactorType: 'sms',
                    phoneIndex: this.state.mobilePhone.idx
                }
                break
            case 'callMobile':
                payload = {
                    authFactorType: 'call',
                    phoneIndex: this.state.mobilePhone.idx
                }
                break
            case 'smsHome':
                payload = {
                    authFactorType: 'sms',
                    phoneIndex: this.state.voicePhone.idx
                }
                break
            case 'callHome':
                payload = {
                    authFactorType: 'call',
                    phoneIndex: this.state.voicePhone.idx
                }
                break
            case 'smsWork':
                payload = {
                    authFactorType: 'sms',
                    phoneIndex: this.state.voiceWPhone.idx
                }
                break
            case 'callWork':
                payload = {
                    authFactorType: 'call',
                    phoneIndex: this.state.voiceWPhone.idx
                }
                break
            default:
                break
        }

        payload.email = this.props.Authenticate.user_profile.email;
        payload.mfaType = 'singlefile';

        dispatch(doCreateAuthChallenge(payload))
    }

    displayMobile() {
        let mobileNum, mobileIndex = ''
        const phoneNumbers = (this.props.Authenticate.patient !== undefined) ? this.props.Authenticate.patient.phoneNumbers : ''
        for (let [index, ph] of phoneNumbers.entries()) {
            if (ph.type === 'Mobile') {
                mobileNum = ph.number,
                mobileIndex = index
                break
            }
        }
        return {
            display: mobileNum,
            idx: mobileIndex
        }
    }

    displayVoice() {
        let voiceNum, voiceIndex = ''
        const phoneNumbers = (this.props.Authenticate.patient !== undefined) ? this.props.Authenticate.patient.phoneNumbers : ''
        for (let [index, ph] of phoneNumbers.entries()) {
            if (ph.type === 'Home Phone') {
                voiceNum = ph.number
                voiceIndex = index
                break
            }
        }
        return {
            display: voiceNum,
            idx: voiceIndex
        }
    }
    displayWVoice() {
        let voiceNum, voiceIndex = ''
        const phoneNumbers = (this.props.Authenticate.patient !== undefined) ? this.props.Authenticate.patient.phoneNumbers : ''
        for (let [index, ph] of phoneNumbers.entries()) {
            if (ph.type === 'Work Phone') {
                voiceNum = ph.number
                voiceIndex = index
                break
            }
        }
        return {
            display: voiceNum,
            idx: voiceIndex
        }
    }
    render() {
        const { IDVerification, AuthChallenge } = this.props
        const { errorMessage } = this.state
        const otpSuccess = (AuthChallenge.authCreate !== undefined) ? (AuthChallenge.authCreate.success === 'true' ? true : false) : false
        return (
            <div>
                {this.props.Authenticate.patient === undefined && // TODO {this.props.IDVerification.patient === undefined &&
                        <Redirect push to="/IDVerification" />
                }
                {otpSuccess ? (
                        <Redirect push to="/OTPVerify" />
                ) : (
                        <div>
                        <ProgressBar navPath='newUser'/>
                        <OTPForm Authenticate={this.props.Authenticate} IDVerification={this.props.IDVerification} onChange={this.updateOTPField} otpMethod={this.state.otpMethod}
                                patientMobile={this.state.mobilePhone} patientVoice={this.state.voicePhone} patientWVoice={this.state.voiceWPhone} resendMFA={this.resendMFA} errorMessage={this.state.errorMessage} onSubmit={this.handleOTPRequest} />
                        </div>
                    )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    AuthChallenge: state.AuthChallenge,
    Authenticate: state.Authenticate,
    IDVerification: state.IDVerification
})

export default connect(mapStateToProps)(OTP)
