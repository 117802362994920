import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import CheckboxField from './common/CheckboxField'
import { Jumbotron, Container, Button, Row, Col, Input, Alert } from 'reactstrap'

/**
 * Close Account Form (part of #23)
 */
class CloseAccountForm extends Component {
    render() {
        return (
            <Jumbotron className="AccountClosedForm">
                <Container>
                <Row>
                    <Col>   
                        <h3 className="text-danger">Close Your iAgree Account</h3>
                        <hr className="my-2" />
                        <p>When you close our iAgree account, no data will be shared for research going forward, but previously shared data will not be deleted.</p>
                        <br />
                        <p>Please provide a brief explanation of why you would like to close your account, click the checkbox, then click the <b>Close Account</b> button.</p>

                        {this.props.reasonErr && <Alert color="danger">{this.props.reasonErr}</Alert> }
                        
                        <Input type="textarea" rows="5" className='reasonfield' name='closeReason' onChange={this.props.handleReasonInput} />
                        
                        <CheckboxField
                            fieldName='closeAcct'
                            fieldValue='close'
                            fieldLabel='I wish to close my iAgree account.'
                            handleClick={this.props.handleCheckboxClick} />
                        
                        { this.props.checkErr && <Alert color="danger">{this.props.checkErr}</Alert> } 
                        <br />
                        <Button color="danger" onClick={this.props.closeClick}>Close Account</Button>
                    </Col>
                </Row>
                </Container>
            </Jumbotron>
        )
    }

}

/**
 * @type {Object}
 * @property {func} handleCheckboxClick    - handler for checkbox click (required)
 * @property {func} closeClick             - handler for Close Account button click (required)
 */

CloseAccountForm.PropTypes = {
    handleCheckboxClick: PropTypes.func.isRequired,
    closeClick: PropTypes.func.isRequired
}


export default CloseAccountForm

