import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { fetchIDPList, setFacility, facilityLogin, getUserAvailableRelyingParties } from '../actions/Authenticate'
import ProgressBar from '../components/nav/ProgressBar'
import SelectFacilityForm2 from '../components/SelectFacilityForm2'
import './SelectFacility2.css'

/**
 * Select Facility Container (#9)
 */
class SelectFacility2 extends Component {
    /**
     * Constructor for class
     * @param {object} props 
     * 
     */
    constructor(props) {
        super(props);
        
        /**
         * @property {string} selected          - selected radio option of facility
         * @property {boolean} errorMsg         - store error messages here
         * @property {string} nextStep          - when form complete, the next screen to go to
         * @property {string} pathway           - whether user is creating account or logging in
         */
        
        this.state = {
            selected: '', 
            errorMsg: '',
            nextStep: ''
        }
        this.onRadioUpdate = this.onRadioUpdate.bind(this)
        this.handleContinue = this.handleContinue.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.setFacilitiesLists = this.setFacilitiesLists.bind(this)
    }

    /**
     * Fetch the patient IDP list 
     */
    componentWillMount = () => {
        const { dispatch } = this.props
        dispatch(fetchIDPList({ idpType: 'patient' }))
        this.setFacilitiesLists()

        // const rp = this.props.Authenticate.availRp.map((item, key)=>{
        //     console.log(item, key)
        // })

        //this.setState({ selected: this.props.Authenticate.availRp[0].idp_id })
    }

    /**
     * update local state when a facility is selected
     * @param {object} e    - target clicked
     */
    onRadioUpdate = e => {
        this.setState({
            selected: e.target.value
        })
    }

    /**
     * update local state when a facility is/are checkboxed
     * @param {object} e    - target clicked
     */
    // onBoxUpdate = e => {
    //     this.setState({
    //         selected: e.target.value
    //     })
    // }

    /**
     * Update redux store with selected facility then proceed to next step
     * @param {object} e    - button clicked
     */
    handleContinue = e => {
        if (this.state.selected) {
            const { dispatch, Authenticate } = this.props
            const selectedFacility2 = Authenticate.availRp.find(x => x.facility_id === this.state.selected)
            const selectedIDP = Authenticate.idpList.find(x => x.idp_id === selectedFacility2.idp_id)
            dispatch(setFacility(selectedIDP))
            this.setState({ errMsg: '', nextStep: '/IDVerification' })
        } else {
            this.setState({ errMsg: 'Please make a selection before continuing.' })
        }
    }

    /**
     * Update redux store with selected facility then proceed to next step
     * @param {object} e    - button clicked
     */
    handleOauth2 = e => {
        if (this.state.selected) {
            //console.log('handleOauth2');
            const { dispatch, Authenticate } = this.props
            const selectedFacility2 = Authenticate.availRp.find(x => x.facility_id === this.state.selected)
            const selectedIDP = Authenticate.idpList.find(x => x.idp_id === selectedFacility2.idp_id)
            selectedIDP.addFacility = "True"
            dispatch(setFacility(selectedIDP))
            dispatch(facilityLogin(selectedIDP)) 
        } else {
            this.setState({ errMsg: 'Please make a selection before continuing.' })
        }
    }

    handleBack = selectedBtn => {
       if (selectedBtn === 'back') 
            this.props.history.push('/AccountHome')
    }

    
    //call availRp
    setFacilitiesLists = () => {
        const { dispatch } = this.props
        dispatch(getUserAvailableRelyingParties({ 
        }))
    }
    
    render() {
    const { Authenticate } = this.props
    const availableRP = Authenticate.availRp !== undefined ? Authenticate.availRp : []
    //console.log(this.state.selected)
        return (
            <div>
                {this.state.nextStep !== '' &&
                    <Redirect push to={this.state.nextStep} />
                }
                <ProgressBar navPath='newUser'/>
                <SelectFacilityForm2
                    Authenticate={this.props.Authenticate}
                    availRp={availableRP}
                    err={this.state.errMsg}
                    onRadioUpdate={this.onRadioUpdate}
                    handleContinue={this.handleContinue}
                    handleOauth2={this.handleOauth2}
                    handleBack={this.handleBack}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(SelectFacility2)