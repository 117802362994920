//#17
import React, { Component } from 'react';
//import { connect } from 'react-redux'
//import { Redirect } from 'react-router'
import { Jumbotron, Container, Row, Col } from 'reactstrap';
import '../components/global/Privacy.css'

/**
 * Statement of Privacy
 */
class Privacy extends Component {
  /**
   * Constructor for class
   * @param {object} props 
   * 
   */
    render() {
    return (
        <Jumbotron className="Privacy">
          <Container>
            <Row className="text-center">
              <Col>
                <h1>Statement of Privacy</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <br />
                <p>Protecting your private information is our priority. This Statement of Privacy applies to www.iagree2share.org and the iAgree project and governs data collection and usage. For the purposes of this Statement of Privacy, unless otherwise noted, all references to iAgree include iagree2share.org.</p>
                <p>iAgree is a demonstration project being developed by the iAgree consortium consisting of UC San Diego, UC Irvine, University of Southern California, University of Colorado, and Cedars-Sinai Medical Center.  The work is supported by an award from the National Institutes of Health (Grant Number R01HG011066).   The primary goal of this project is to develop a proof-of-concept system that allows patients to express their preferences on types of electronic health data they wish to share for research in a privacy preserving manner and give them the tools to manage their preferences.</p>
                
                <p><b>Collection of your Personal Information</b><br />iAgree not collect any personal information unless you voluntarily provide it, and the only information stored by iAgree is the minimum necessary information needed for you to establish an account in iAgree and to express your data sharing preferences.</p>
                <p><ol>
                      <li>iAgree will allow you to either (a)create an account specifically for this purpose with a unique login username/password that is managed within iAgree or (b) you can use a social identity such as Google or Facebook.  (For this project, Google and Facebook are the only options, although the architecture allows the addition of other identity providers as long as they conform the OpenID requirements).</li>
                      <br />
                      <li>iAgree will not record or store any data contained in your health provider(s) EHR system.</li>
                      <br />
                      <li>When you first register for an account, iAgree will ask you which health system data you wish to share your data and route you to that health systems’ patient portal.  If you successfully login to that patient portal, iAgree will be able to apply your data sharing preferences to that health system and filter the data extract accordingly.</li>
                      <br />
                      <li>As part of the registration process, iAgree will send you a one-time-passcode to the phone number contained in the health system’s electronic health system.  The passcode will be transmitted to a third party solely to send you a text message or voice call with a code that you key into iAgree to affirm your identity. That phone number will not be stored or retained by iAgree beyond the initial use.</li>
                      <br />
                      <li>The one-time-passcode verification process must be repeated for each health system that you want to have your data extracted for research.</li>
                          {/*<ol type="a">
                            <li>Email address you provide for us to contact you</li>
                            <li>Names of the healthcare provider organization(s) linked to your Single-FILE login ID</li>
                            <li>A unique code assigned to your account to prevent duplicate registrations to the same EHR system</li>
                            <li>The username of accounts you link to Single-FILE</li>
                          </ol>*/}
                    </ol></p>

                <p><b>Sharing Information with Third Parties</b><br />iAgree does not sell, rent or lease any information to third parties.</p>
                <p>iAgree may share summary (aggregate) data with the NIH and trusted partners assisting with the project to help with assessing the efficacy of the project and may also be used to send you follow up user experience surveys.  None of the summary date will contain any personal information.</p>
                
                <p><b>Tracking User Behavior</b><br />iAgree may keep track of  your usage solely to enhance the system.  The data will not be used for any other purpose.</p>

                <p><b>Automatically Collected Information</b><br />Information about your computer hardware and software may be automatically collected by iAgree.  This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the iAgree website.</p>
                
                <p><b>Use of Cookies</b><br />The iAgree website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. </p>
                <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page.</p>
                <p>You may accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of iAgree.</p>
                
                <p><b>Security of your Personal Information</b><br />All information transmitted via iAgree is protected through encryption via the Secure Sockets Layer (SSL) protocol.</p>
                <p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. While we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of all information and data exchanged between you and us through this Site cannot be guaranteed.</p>
                
                <p><b>Children Under Thirteen </b><br />iAgree does not knowingly allow use of this system by children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website. </p>
                
                <p><b>External Data Storage Sites</b><br />We may store your data on servers provided by third party hosting vendors with whom we have contracted.  We have executed the necessary agreements to ensure compliance with HIPAA and other federal and local laws.</p>
                
                <p><b>Changes to this Statement of Privacy</b><br />iAgree reserves the right to change this Statement of Privacy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Statement of Privacy; and (b) agreement to abide and be bound by that Statement. </p>
                
                <p><b>Contact Information</b><br />iAgree welcomes your questions or comments regarding this Statement of Privacy. If you believe that iAgree has not adhered to this Statement, please contact us at: </p>
                <p>iAgree contact information</p> 
                <p>Email Address: <a href="mailto:info@iagree2share.org?subject=iAgree to Share Question">info@iagree2share.org</a></p>
                <p>Telephone number:  323-682-8312</p>
                <p>Effective as of April 20, 2021</p>

                <p>Appendix A:  iAgree Overview</p>
                <p>The goals of iAgree are:
                   <ol>
                      <li>Provide a single federated identity that can be used to access multiple, independent electronic health record systems by means of a single sign-on.</li>
                      <li>Incorporate multi-factor authentication to enhance security and privacy.</li>
                      <li>Establish a robust identity verification process to assure appropriate access to electronic health record information.</li>
                      <li>Incorporate privacy-enhancing technology.</li>
                   </ol></p>
                <p>The specific use case for this pilot involves the transition of patients as they are discharged from UC San Diego, UC Irvine, University of Southern California, University of Colorado, and Cedars-Sinai Medical Center.  iAgree can be used by both patients (or their proxies) and by their healthcare providers.   As these patients make the transition from one facility to another, the ability to quickly access patient records at both facilities in a timely and convenient manner is essential to providing continuity of care.  For patients, the ability to gain a complete view of their electronic health record will enable greater participation and involvement in maintaining their health.</p>
                <p>As part of the project, statistics on usage will be collected to assess the utility and adaptation of iAgree in overcoming the problems associated patients and their healthcare providers trying to manage multiple login IDs at both institutions.</p>
                <p>The ONC will use the experience gained from iAgree to develop a best-practice guide for the use of a federated ID for access to multiple health care records systems.</p>
              </Col>
            </Row>
          </Container>  
        </Jumbotron>    )
  }
}

export default Privacy;