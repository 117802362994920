import * as types from './ActionTypes'
import axios from 'axios'
import { REST_URL } from '../constants/Config'
import qs from 'qs'
import { setHeader } from './Authenticate'
import { PubKey } from '../constants/PubKey'

export function MFAOptionsReq(request) {
    return {
        type: types.MFA_OPTIONS_REQ,
        payload: request
    }
}

export function MFAOptionsReceive(request) {
    return {
        type: types.MFA_OPTIONS_RECV,
        payload: request
    }
}

export function MFAOptionsError(request) {
    return {
        type: types.MFA_OPTIONS_ERR,
        payload: request
    }
}

export function FacilityMFAOptionsReq(request) {
    return {
        type: types.FACILITY_MFA_OPTIONS_REQ,
        payload: request
    }
}

export function FacilityMFAOptionsReceive(request) {
    return {
        type: types.FACILITY_MFA_OPTIONS_RECV,
        payload: request
    }
}

export function FacilityMFAOptionsError(request) {
    return {
        type: types.FACILITY_MFA_OPTIONS_ERR,
        payload: request
    }
}

export function createAuthChallenge(request) {
    return {
        type: types.AUTH_CREATE_REQ,
        payload: request
    }
}

export function createAuthChallengeReceive(request) {
    return {
        type: types.AUTH_CREATE_RECV,
        payload: request
    }
}

export function createAuthChallengeError(request) {
    return {
        type: types.AUTH_CREATE_ERR,
        payload: request
    }
}

export function reqUserAuthChallenge(request) {
    return {
        type: types.AUTH_CHALLENGE_REQ,
        payload: request
    }

}

export function receiveAuthChallengeData(request) {
    return {
        type: types.AUTH_CHALLENGE_RECV,
        user_auth_factor_challenge_status: request
    }

}
export function receiveAuthChallengeError(request) {
    return {
        type: types.AUTH_CHALLENGE_ERR,
        data: request
    }

}
export function validateAuthChallenge(request) {
    return {
        type: types.AUTH_CHALLENGE_VALIDATE,
        data: request
    }

}

export function receiveAuthChallengeStatus(request) {
    return {
        type: types.AUTH_CHALLENGE_STATUS,
        user_auth_factor_challenge_status: request
    }
}

export function receiveAuthChallengeStatusErr(request) {
    return {
        type: types.AUTH_CHALLENGE_STATUS_ERR,
        data: request
    }
}

export function clearMFA(request) {
    return {
        type: types.MFA_CLEAR,
        data: request
    }
}
export function clearOTP(request) {
    return {
        type: types.OTP_CLEAR,
        data: request
    }
}

export function clearMFAOptions() {
    return {
        type: types.MFA_OPTIONS_CLEAR,
        data: undefined
    }
}

/* called from OTP 
 *
 */
export function doCreateAuthChallenge(request) {
    return function (dispatch) {
        dispatch(createAuthChallenge(request))
        const stringReq = {
            authFactorType: request.authFactorType,
            mfaType: request.mfaType
        }
        const jsonReq = {
            countryCode: request.countryCode,
            phoneIndex: request.phoneIndex,
            email: request.email
        }
        const restCall = 'OTP.json'
        return axios.post(REST_URL + '/sf/rest/' + restCall + '?' + qs.stringify(request), request).then(function (response) {
            const RSALib = require('jsrsasign')
            var jwtKey = RSALib.KEYUTIL.getKey(PubKey)
            var isValid = RSALib.KJUR.jws.JWS.verify(response.data.userAuthFactorChallenge, jwtKey, ["RS256"])
            if (isValid) {
                const payload = parseJwt(response.data.userAuthFactorChallenge)
                if (payload.user_auth_factor_challenge_status.success === 'true') {
                    dispatch(setHeader(payload.xtoken))
                    dispatch(createAuthChallengeReceive(payload));
                } else {
                    dispatch(setHeader(payload.xtoken))
                    payload.user_auth_factor_challenge_status.message = 'The one-time passcode could not be sent.'
                    dispatch(createAuthChallengeError(payload));
                }

            }
        })
            .catch(function (error) {
                if (error.data) {
                    dispatch(setHeader(error.data.xtoken))
                    dispatch(createAuthChallengeError(error.data));
                }
            })
    }
}

export function fetchAuthChallenge(request) { // provider
    return function (dispatch, getState) {
        dispatch(reqUserAuthChallenge(request))
        const restCall = request.verify === true ? 'userAuthFactorChallengeValidate.json' : 'userAuthFactorChallenge.json' // TODO is 'userAuthFactorChallengeValidate.json' needed?

        if (request.verify !== undefined) {
            delete request.verify
        }
        return axios.post(REST_URL + '/sf/rest/' + restCall + '?' + qs.stringify(request), request).then(function (response) {
            const RSALib = require('jsrsasign')
            var jwtKey = RSALib.KEYUTIL.getKey(PubKey)
            var isValid = RSALib.KJUR.jws.JWS.verify(response.data.userAuthFactorChallenge, jwtKey, ["RS256"])
            if (isValid) {
                const payload = parseJwt(response.data.userAuthFactorChallenge)
                if (getState().Authenticate.provider !== undefined) { // provider
                    const samAccountName = getState().Authenticate.provider.user.sam_account_name
                    if (payload.user_auth_factor_challenge_status.success === 'true' /*&& payload.user_auth_factor_challenge_status.user === samAccountName*/) {
                        dispatch(setHeader(payload.xtoken))
                        dispatch(receiveAuthChallengeData(payload));
                    } else {
                        payload.user_auth_factor_challenge_status.success = 'false'
                        dispatch(setHeader(payload.xtoken))
                        dispatch(receiveAuthChallengeError(payload));
                    }
                } else {
                    dispatch(receiveAuthChallengeError({ error: 'fatal error' }));
                }
            }
            else {
                dispatch(receiveAuthChallengeError({ error: 'fatal error' }));
            }
        })
            .catch(function (error) {
                if (error.data) {
                    dispatch(setHeader(error.response.data.xtoken))
                    dispatch(receiveAuthChallengeError(error.response.data));
                }
                //dispatch(pushState(null,'/error'));
            })
    }
}

export function patientfetchAuthChallenge(request) {
    return function (dispatch, getState) {
        dispatch(reqUserAuthChallenge(request))
        const restCall = 'MFA.json' // TODO is 'userAuthFactorChallengeValidate.json' needed?

        if (request.verify !== undefined) {
            delete request.verify
        }
        return axios.post(REST_URL + '/sf/rest/' + restCall + '?' + qs.stringify(request), request).then(function (response) {
            const RSALib = require('jsrsasign')
            var jwtKey = RSALib.KEYUTIL.getKey(PubKey)
            var isValid = RSALib.KJUR.jws.JWS.verify(response.data.userAuthFactorChallenge, jwtKey, ["RS256"])
            if (isValid) {
                const payload = parseJwt(response.data.userAuthFactorChallenge)
                if (payload.user_auth_factor_challenge_status.success === 'true' ) {
                    dispatch(setHeader(payload.xtoken))
                    dispatch(createAuthChallengeReceive(payload));
                } else {
                    payload.user_auth_factor_challenge_status.message = 'The one-time passcode could not be sent.'
                    dispatch(setHeader(payload.xtoken))
                    dispatch(createAuthChallengeError(payload));
                }
            }
            else {
                dispatch(receiveAuthChallengeError({ error: 'fatal error' }));
            }
        })
            .catch(function (error) {
                if (error.data) {
                    dispatch(setHeader(error.response.data.xtoken))
                    dispatch(receiveAuthChallengeError(error.response.data));
                }
                //dispatch(pushState(null,'/error'));
            })
    }
}
export function patientfetchAuthChallengeStatus(request) {
    return function (dispatch, getState) {
        dispatch(reqUserAuthChallenge(request))
        const restCall = 'patientAuthFactorChallengeStatus.json'
        //const myArrStr = JSON.stringify(request)
        //console.log(JSON.stringify(myArrStr))
        if (request.verify !== undefined) {
            delete request.verify
        }
        return axios.post(REST_URL + '/sf/rest/' + restCall, request).then(function (response) {
            const RSALib = require('jsrsasign')
            var jwtKey = RSALib.KEYUTIL.getKey(PubKey)
            var isValid = RSALib.KJUR.jws.JWS.verify(response.data.userAuthFactorChallenge, jwtKey, ["RS256"])
            if (isValid) {
                const payload = parseJwt(response.data.userAuthFactorChallenge)

                //const samAccountName = getState().Authenticate.provider.user.sam_account_name
                //var newVal = (payload.user_auth_factor_challenge_status.success === 'true')
                //console.log('newVal ' + newVal + ' .')
                if (payload.user_auth_factor_challenge_status.success === 'true' /*&& payload.user_auth_factor_challenge_status.user === samAccountName*/) {
                    if (request.authFactorType === 'sms' || request.authFactorType === 'call') {
                        if (request.status === 'No Account') {
                        }
                    }
                    dispatch(setHeader(payload.xtoken))
                    dispatch(clearOTPCode())
                    dispatch(receiveAuthChallengeStatus(payload.user_auth_factor_challenge_status))
                } else {
                    payload.user_auth_factor_challenge_status.success = 'false'
                    dispatch(setHeader(payload.xtoken))
                    dispatch(receiveAuthChallengeStatusErr(payload));
                }
            }
            else {
                dispatch(receiveAuthChallengeStatusErr({ error: 'fatal error' }));
            }
        })
            .catch(function (error) {
                if (error.data) {
                    dispatch(setHeader(error.response.data.xtoken))
                    dispatch(receiveAuthChallengeStatusErr(error.response.data));
                }
                //dispatch(pushState(null,'/error'));
            })
    }
}

// /**
//  * Validate the Authentication Challenge with Single-FILE REST API
//  * @param {object} request 
//  */
// export function fetchAuthChallengeStatus(request) {
//     return function (dispatch, getState) {
//         dispatch(validateAuthChallenge(request))
//         return axios.get(REST_URL + '/sf/rest/userAuthFactorChallengeStatus.json?' + qs.stringify(request), request).then(function (response) {
//             if (response.data.user_auth_factor_challenge_status.success === 'true') {
//                 dispatch(setHeader(response.data.xtoken))
//                 dispatch(receiveAuthChallengeStatus(response.data.user_auth_factor_challenge_status))
//             } else {
//                 dispatch(setHeader(response.data.xtoken))
//                 dispatch(receiveAuthChallengeStatusErr(response.data))
//             }
//         })
//             .catch(function (error) {
//                 dispatch(setHeader(error.response.data.xtoken))
//                 if (error.data) {
//                     dispatch(receiveAuthChallengeStatusErr(error.response.data))
//                 }
//             })
//     }
// }

export function fetchMFAOptions(request) {
    return function (dispatch) {
        dispatch(MFAOptionsReq(request))
        return axios.get(REST_URL + '/sf/rest/userSingleFileMFAOptions.json?' + qs.stringify(request), qs.stringify(request)).then(function (response) {
            dispatch(setHeader(response.data.xtoken))
            dispatch(MFAOptionsReceive(response.data));
        })
            .catch(function (error) {
                dispatch(setHeader(error.response.data.xtoken))
                dispatch(MFAOptionsError(error.response.data));
            })
    }
}

export function fetchFacilityMFAOptions(request) {
    return function (dispatch, getState) {
        dispatch(FacilityMFAOptionsReq(request))
        const restCall = request.verify === true ? 'userFacilityMFAOptionsValidate.json' : 'userFacilityMFAOptions.json'
        if (request.verify !== undefined) {
            delete request.verify
        }
        return axios.get(REST_URL + '/sf/rest/' + restCall + '?' + qs.stringify(request), qs.stringify(request)).then(function (response) {
            dispatch(FacilityMFAOptionsReceive(response.data));
            dispatch(setHeader(response.data.xtoken))
        })
            .catch(function (error) {
                dispatch(FacilityMFAOptionsError(error.response.data));
                dispatch(setHeader(error.response.data.xtoken))
            })
    }
}


export function clearMFACode(request) {
    return dispatch => {
        dispatch(clearMFA(request))
    }

}

export function clearOTPCode(request) {
  return dispatch => {
    dispatch(clearOTP(request))
    }
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};
