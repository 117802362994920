import * as types from './ActionTypes'
import axios from 'axios'
import { REST_URL } from '../constants/Config'
import qs from 'qs'


export function setSessionExpiry(request) {
    return {
        type: types.SET_SESSION_EXPIRY,
        expiry: request
    }
}

export function createSSOContextReq(request) {
    return {
        type: types.CREATE_SSO_REQ,
        payload: request
    }
}

export function createSSOContextRecv(request) {
    return {
        type: types.CREATE_SSO_RECV,
        payload: request
    }
}

export function createSSOContextErr(request) {
    return {
        type: types.CREATE_SSO_ERR,
        payload: request
    }
}



/**
 * Create an SSO context for SSO to facility
 * @param {object} request  - user ID and RP ID 
 */
export function createSSOContext(request) {
    return function (dispatch, getState) {
        dispatch(createSSOContextReq(request))
        return axios.post(REST_URL + '/sf/rest/doSSO?' + qs.stringify(request)).then(function (response) {
        //  return axios.post(REST_URL + '/sf/rest/ssoContext?' + qs.stringify(request)).then(function (response) {
                dispatch(createSSOContextRecv(response))
        })
            .catch(function (error) {
                dispatch(createSSOContextErr(error.data))
            })
    }
}

/**
 * Create an SSO context for SSO to facility
 * @param {object} request  - user ID and RP ID 
 */
export function createPatientSSOContext(request) {
    return function (dispatch, getState) {
        dispatch(createSSOContextReq(request))
        return axios.post(REST_URL + '/sf/rest/doPatientSSO?' + qs.stringify(request)).then(function (response) {
            dispatch(setHeader(response.data.xtoken))
            let path = 'https://portal.icts.uci.edu/iagree_auth/default.aspx?access_token=' + response.data.consentToken
            window.location.href=path;

            //dispatch(createSSOContextRecv(response))
        })
            .catch(function (error) {
                dispatch(createSSOContextErr(error.data))
            })
    }
}

export function setHeader(xtoken) {
    return function (dispatch, getState) {
        if (xtoken !== undefined) {
            window.sessionStorage.setItem("xtoken", xtoken)
            axios.defaults.headers.common['X-XSRF-TOKEN'] = window.sessionStorage.getItem("xtoken") || ''
            let jwt = parseJwt(xtoken)
            const exp = getState().Authenticate.expiry === undefined ? 0 : getState().Authenticate.expiry.exp
            if (jwt.exp !== exp) {
                dispatch(setSessionExpiry({
                    exp: jwt.exp
                }
                ))
            }
        }
    }
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

