import React, { Component } from 'react'
import { Card, Button, CardHeader, CardText, Row, Col, Modal } from 'reactstrap';
import Loader from './common/Loader'

/**
 * Patient Add/Remove Facility Form (part of #19)
 */
class PatientAddFacilityForm extends Component {
    render() {
        const { relyingParties, availableRP, removeClick, addClick, addFacilityStep, loadMsg } = this.props
        const { isFetching } = this.props.AuthChallenge
        const facilityList = relyingParties.length > 0 ? relyingParties.map((facility) =>
            <Card outline color="danger" key={facility.facility_id}>
                <CardHeader onClick={() => { removeClick(facility.relying_party_id) }}>{facility.facility_name}</CardHeader>
                <hr className="my-1" />
                <Button color="danger" onClick={() => { removeClick(facility.relying_party_id, facility.facility_name) }}>Unlink</Button>
            </Card>
        ) : <Card outline color="primary">
                <CardText>No healthercare provider are registered.</CardText>
            </Card>

        const rpList = availableRP.length > 0 ? availableRP.map((facility) =>
            <Card outline color="primary" key={facility.idp_id}>
                <CardHeader onClick={() => { addClick(facility.idp_id) }}>{facility.facility_name}</CardHeader>
                <hr className="my-1" />
                <Button color="primary" onClick={() => { addClick(facility.idp_id) }}>Link</Button>
            </Card>
        ) : <Card outline color="primary">
                <CardText>No healthercare provider available.</CardText>
            </Card>
        return (
            <Row>
                <Col className="text-left txtS" sm={{ size: 12, push: 0 }} style={{marginTop: '-40px'}}>
                {/*<h1 className="subh1txt">iAgree</h1>*/}
                <hr className="homeHR" />
                <p className="subtxt" style={{marginTop: '-10px'}}>Your data sharing preferences will only apply for Health systems linked to your iAgree account.</p>
                <br /><br />
                </Col>

                <Col sm="6">
                    <h6>Unlink a Health System from your iAgree account.</h6>
                    <hr className="my-0" />
                    <br />
                    {facilityList}
                </Col>
                <Col sm="6">
                    <h6>Link a Health System to your iAgree account.</h6>
                    <hr className="my-0" />
                    <br />
                    {rpList}
                </Col>
                <Modal
                    isOpen={this.props.removeConfirm === true || this.props.addConfirm === true}
                    onRequestClose={this.props.closeModal}
                    contentLabel='Verify Selection'
                >
                    <div>
                        {isFetching && <Loader msg={loadMsg} />}
                        {this.props.removeConfirm &&
                            <div className="removeFacility" style={{ textAlign: 'center' }}>
                                <p><b>Are you sure you want to unlink <font className="text-danger">{this.props.facilityChangeName}</font> from your iAgree account?</b></p>
                                <Button color="danger" onClick={this.props.processRemove}>OK</Button> <Button onClick={this.props.closeModal}>Cancel</Button>
                            </div>
                        }
                    </div>

                </Modal>
            </Row>
        )
    }

}


export default PatientAddFacilityForm

