import React, { Component } from 'react'
import PropTypes from 'prop-types'

/**
 * Common form component to display checkboxes.
 */
class CheckboxField extends Component {
    constructor(props) {
        super(props);
        this.state = { showWarning: true }
    }

    render() {
        const { fieldName, fieldValue, fieldLabel, fieldID, handleClick, isChecked } = this.props
        const checked = isChecked
        return (
            <span>
                {checked !== undefined &&
                    <span>
                        <input type='checkbox' onChange={handleClick} className='formCheckbox' name={fieldName} value={fieldValue} id={fieldID} checked={checked} />  <label htmlFor={fieldID}>{fieldLabel}</label>
                    </span>
                }
                {checked === undefined &&
                    <span>
                        <input type='checkbox' onChange={handleClick} className='formCheckbox' name={fieldName} value={fieldValue} id={fieldID} /> <label htmlFor={fieldID}>{fieldLabel}</label>
                    </span>
                }
            </span>
        );
    }

}

/**
 * @type {Object}
 * @property {string} fieldname
 * @property {string} fieldValue
 * @property {string} fieldLabel
 * @property {function} handleClick
 * @property {boolean} isChecked
 */
CheckboxField.PropTypes = {
    fieldName: PropTypes.string.isRequired,
    fieldValue: PropTypes.string,
    fieldLabel: PropTypes.string,
    handleClick: PropTypes.func,
    isChecked: PropTypes.bool
}

export default CheckboxField
