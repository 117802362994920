import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from './common/Loader';
import InputField from '../components/common/InputField'
import { Jumbotron, Container, Row, Col, Button, Badge, Card, CardText, Alert, Label } from 'reactstrap';
import './global/Main.css'

/**
 * ID Verification - Personal Info Container (#10) - 
 * Fields to enter SSN, DOB, Zip, MRN to verify identity
 */
class IDVPersonalInfo extends Component {

    render() {
        const errMsg = this.props.IDVerification.isErr
        const isFetching = this.props.IDVerification.isFetching

        return (
        <Jumbotron className="idVPinfo">
        <Container>
          <Row>
            <Col>
                <div>
                    {isFetching && <Loader msg="Looking up patient information..." /> }
                    <div className="idp">
                     {errMsg && <p className='err'>Patient record not found.</p>}
                    <h1 className="text-left">Patient Identity Verification <Badge color="success" pill>Step 3/4</Badge></h1>
                    <hr className="my-2" />
                        <p>In order to verify access to your patient record, please provide the following information. This information is only used for verification of your identity, and is not stored in iAgree.</p>
                    </div>
                </div>
            </Col>  
          </Row>  

                <Row>
                    <Col sm="5">
                        <Card outline color="secondary">
                            <CardText>
                                <Label htmlFor="ssn">Last 5 Digits of SSN:</Label>
                                <InputField fieldName="ssn" fieldID="ssn" fieldValue={this.props.ssn} fieldLabel="Last 5 Digits of SSN" fieldPlaceholder="Last 5 Digits of SSN" fieldClassname='fa fa-id-card fa-fw' handleChange={this.props.updateEmail} />

                                <Label htmlFor="dob">Date of Birth:</Label>
                                <InputField fieldID="dob" fieldPlaceholder="MM/DD/YYYY" fieldClassname='fa fa-birthday-cake fa-fw' fieldName='dob' fieldValue={this.props.dob} handleChange={this.props.onChange} />

                                <Label htmlFor="zip">Zip Code:</Label>
                                <InputField fieldID="zip" fieldPlaceholder="Zip Code" fieldClassname='fa fa-home fa-fw' fieldName='zip' fieldValue={this.props.zip} handleChange={this.props.onChange} />
                            </CardText>
                        </Card>    
                    </Col>
                    <p>Or</p>
                    <Col sm="5">  
                    <Card outline color="secondary">  
                        <CardText>
                            <Label htmlFor="ssn">Last 5 Digits of SSN:</Label>
                            <InputField fieldName="ssn" fieldID="ssn" fieldValue={this.props.ssn} fieldLabel="Last 5 Digits of SSN" fieldPlaceholder="Last 5 Digits of SSN" fieldClassname='fa fa-id-card fa-fw' handleChange={this.props.updateEmail} />

                            <Label htmlFor="cshsmrn">Cedars-Sinai MRN:</Label>
                            <InputField fieldID="cshsmrn" fieldPlaceholder="MRN" fieldName='cshsmrn' fieldClassname='fa fa-medkit fa-fw' fieldValue={this.props.cshsmrn} handleChange={this.props.onChange} />
                        </CardText>  
                    </Card>                                  
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button id="idpSubmit" size="lg" color="primary" onClick={this.props.onSubmit}>Continue{" "}<i className="fa fa-angle-double-right" /></Button>                          
                    </Col>    
                </Row>
        </Container>
        </Jumbotron>  
        );
    }
}

IDVPersonalInfo.PropTypes = {
    Authenticate: PropTypes.object.isRequired,
    ssn: PropTypes.string.isRequired,
    zip: PropTypes.string,
    dob: PropTypes.string,
    cshsmrn: PropTypes.string,
    crimrn: PropTypes.string,
    errorMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
}
export default IDVPersonalInfo;
