import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container } from 'reactstrap';
import './Main.css';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  static contextTypes = {
    router: PropTypes.object
  };
  
             
  render() {
    const { Authenticate } = this.props
    {/* const footerloc = location.pathname.slice(1) */}
    {/*const footerClass = Authenticate.AuthSuccess !== undefined ? 'footerAdmin' : ((footerloc === "About" || footerloc === "Faq")?'About':'footer')*/}
    const footerClass = Authenticate.AuthSuccess !== undefined ? 'footerAdmin' : 'footer'
    const hideFooter = window.location.pathname==='/' ||
                       window.location.pathname==='/AccountHome' ||
                       window.location.pathname==='/About' ||
                       window.location.pathname==='/Faq' ||
                       window.location.pathname==='/Contact' ||
                       window.location.pathname==='/Terms' ||
                       window.location.pathname==='/Privacy' || 
                       window.location.pathname==='/Profile' ||
                       window.location.pathname==='/AddFacility' ||
                       window.location.pathname==='/CloseAccount' ||
                       window.location.pathname==='/Help' ? {display: 'block'} : {display: 'none'}
    //console.log(JSON.stringify(location))
    //console.log(JSON.stringify(footerClass))
    return (
        <footer className={footerClass} style={hideFooter}>
          {/*<Container>
            <span>&copy;{' '}{(new Date().getFullYear())}{' '}iAgree to Share{' '}
              <a href="/Terms" target="_blank">Terms and Conditions</a>{' '} 
              <a href="/Privacy" target="_blank">Statement of Privacy</a>
            </span>
          </Container>  */}
        </footer>
    );
  }
}

Footer.PropTypes = {
  Authenticate: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(Footer);
