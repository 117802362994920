//#10
import React, { Component } from 'react';
import { Jumbotron, Container, Row, Col, Button, Badge } from 'reactstrap';

class IDVerificationForm extends Component {
  constructor(props) {
    super(props);
    //this.handlePersonalInfoClick = this.handlePersonalInfoClick.bind(this);
    this.handleCodeClick = this.handleCodeClick.bind(this);
    this.handleMyChartLoginClick = this.handleMyChartLoginClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  /*handlePersonalInfoClick(e) {
    this.props.onUpdateDisplayCard('personalinfo');
  }*/

  handleCodeClick() {
    this.props.onUpdateDisplayCard('code');
  }

  handleMyChartLoginClick() {
    this.props.onUpdateDisplayCard('mychart');
  }

  handleBackClick(){
    window.history.go(-1);
  }

   render() {
    //console.log(this.props)
    return (
      <Jumbotron className="idVForm">
        <Container>
          <Row>
            <Col>
                <h1 className="text-left">Patient Identity Verification <Badge color="success" pill>Step 2/4</Badge></h1>
                <hr className="my-2" />
                <p>In order to verify access to your patient record, please select one of the methods below.</p>
            </Col> 

            {/* <Col className="idCol1">
              <p>Use my personal information to locate my patient record
                <span className="link">
                  <Button color="primary" size="lg"  onClick={this.handlePersonalInfoClick}>Continue</Button>
                </span>
              </p>
            </Col> */}

            <Col className="idCol2">

              <p style={{display : 'inline-block'}}>Use an activation code provided by <b>{this.props.Authenticate.facility.idp_friendly_name}</b></p>
              <span className="link">
                <Button className="pull-right" color="primary" size="lg" onClick={this.handleCodeClick}>Continue</Button>
              </span>

            </Col>
            <Col className="idCol3">
              <p style={{display : 'inline-block'}}>Use your <b>{this.props.Authenticate.facility.idp_info}</b> account</p>
              <span className="link">
                <Button className="pull-right" color="primary" size="lg"  onClick={this.handleMyChartLoginClick}>Continue</Button>
              </span>
              
            </Col>
            <Col>
              <Button id="backBtn" size="lg" color="info" onClick={this.handleBackClick}><i className="fa fa-angle-double-left" />{" "}Back </Button>
            </Col>
          </Row>
        </Container>     
     </Jumbotron>
    );
  }
}

export default IDVerificationForm;
