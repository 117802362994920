import * as types from '../actions/ActionTypes'

export default function doAuthChallenge(state = [], action) {
    switch (action.type) {
        case types.MFA_OPTIONS_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false
            })
        case types.MFA_OPTIONS_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                mfaOptions: action.payload.user_singlefile_mfa_options
            })
        case types.MFA_OPTIONS_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                errMsg: action.payload.user_singlefile_mfa_options.message,
                mfaOptions: action.payload.user_singlefile_mfa_options
            })
        case types.FACILITY_MFA_OPTIONS_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false
            })
        case types.FACILITY_MFA_OPTIONS_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                mfaOptions: action.payload.user_facility_mfa_options
            })
        case types.FACILITY_MFA_OPTIONS_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                errMsg: action.payload.user_facility_mfa_options.message,
                mfaOptions: action.payload.user_facility_mfa_options
            })
        case types.AUTH_CREATE_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false
            })
        case types.AUTH_CREATE_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                authCreate: action.payload.user_auth_factor_challenge_status
            })
        case types.AUTH_CREATE_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                errorMsg: action.payload.user_auth_factor_challenge_status ? action.payload.user_auth_factor_challenge_status.message : action.payload.message,
                authCreate: action.payload.user_auth_factor_challenge_status
            })
        case types.AUTH_CHALLENGE_REQ:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false
            })
        case types.AUTH_CHALLENGE_RECV:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                authData: action.user_auth_factor_challenge_status
            })
        case types.AUTH_CHALLENGE_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                errorMsg: action.data.user_auth_factor_challenge_status.message
            })
        case types.AUTH_CHALLENGE_VALIDATE:
            return Object.assign({}, state, {
                isFetching: true,
                isErr: false
            })
        case types.AUTH_CHALLENGE_STATUS:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                authData: {
                    user_auth_factor_validate: action.user_auth_factor_challenge_status
                }
            })
        case types.AUTH_CHALLENGE_STATUS_ERR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: true,
                authData: {
                    user_auth_factor_validate: action.data.user_auth_factor_challenge_status
                }
            })
        case types.OTP_CLEAR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                authCreate: undefined
            })
        case types.MFA_CLEAR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                authData: undefined
            })
        case types.MFA_OPTIONS_CLEAR:
            return Object.assign({}, state, {
                isFetching: false,
                isErr: false,
                mfaOptions: undefined
            })
        default:
            return state;
    }
}