import React, { Component } from 'react';
import RadioField from '../components/common/RadioField'
import Loader from './common/Loader';
import { Button, Row, Col, Alert } from 'reactstrap';

class SelectProviderFacilityForm extends Component {
    render() {
        const { isFetching } = this.props.Authenticate
        const facilityList = this.props.idpList.length > 0 ? this.props.idpList.map((facility) =>
        <div key={facility.idp_id}>
           <div>
           <RadioField fieldName="facility" fieldValue={facility.idp_info} fieldLabel={ facility.idp_friendly_name} 
           fieldID={'fac' + facility.idp_id} handleChange={this.props.onRadioUpdate} />
       </div>
        </div>
      ) : ''
        return (
            <span>
                { isFetching && <Loader msg="Loading facilities list" /> }
                <p>Next, please select a facility you want to access. You will be able to select additional facilities after you complete this step.</p>
                {/*<div className="facSearch">
                    <input placeholder="Filter facility by name" className='searchBox' />
                </div>*/}
                <br />
                <Row>
                    <Col> 
                        {facilityList} 
                        <br />
                        { this.props.err && <Alert color="danger">{this.props.err}</Alert> }
                    </Col>
                </Row>
                <Button color="warning" size="lg" id="facilitySelect" onClick={this.props.handleContinue}>Conti123nue{" "}<i className="fa fa-angle-double-right" /></Button>
            </span>

        );
    }

}


export default SelectProviderFacilityForm;
