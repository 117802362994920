//#3
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { fetchIDPList, setProviderFacility } from '../actions/Authenticate'
import SelectProviderFacilityForm from '../components/SelectProviderFacilityForm'
import { Jumbotron, Container, Col } from 'reactstrap';

/**
 * Select Provider Facility Container (#3)
 */
class ProviderSelectFacility extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: '',
            errMsg: '',
            nextStep: ''
        }
        this.onRadioUpdate = this.onRadioUpdate.bind(this)
        this.handleContinue = this.handleContinue.bind(this)
    }

    /**
     * Fetch the provider IDP list 
     */
    componentWillMount = () => {
        const { dispatch } = this.props
        dispatch(fetchIDPList({idpType: 'provider'}))
    }

    /**
     * Check for error condition loading the facilities list
     * @param {object} nextprops    - incoming props
     */
    componentWillReceiveProps = (nextprops) => {
        const { Authenticate } = nextprops
        if (Authenticate.isErr === true && Authenticate.idpList === undefined) {
            this.setState({errMsg: 'Cannot load facilities list at this time. Please try again shortly.'})
        }
    }
    /**
     * update local state when a facility is selected
     * @param {object} e    - target clicked
     */
    onRadioUpdate = e => {
        this.setState({
            selected: e.target.value
        })
    }

    /**
     * Update redux store with selected facility then proceed to next step
     * @param {object} e    - button clicked
     */
    handleContinue = e => {
        if (this.state.selected) {
            const { dispatch, Authenticate } = this.props
            const selectedFacility = Authenticate.idpList.find(x => x.idp_info === this.state.selected)
            dispatch(setProviderFacility(selectedFacility))
            this.setState({ errMsg: '', nextStep: '/ProviderLogin' })
        } else {
            this.setState({ errMsg: 'Please make a selection before continuing.' })
        }
    }
    render() {
        const idpList = this.props.Authenticate.idpList !== undefined ? this.props.Authenticate.idpList : []
        return (
            <Jumbotron>
                <Container>
                    <Col>
                    <h1 className="text-warning">Select Facility</h1>
                    <br />
                    <hr className="my-2" />
                    <div>
                        {this.state.nextStep !== '' &&
                        <Redirect push to={this.state.nextStep} />
                    }
                    <SelectProviderFacilityForm 
                        Authenticate={this.props.Authenticate} 
                        idpList={idpList}
                        err={this.state.errMsg} 
                        onRadioUpdate={this.onRadioUpdate} 
                        handleContinue={this.handleContinue} />
                    </div>
                    </Col>
                </Container>   
            </Jumbotron>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(ProviderSelectFacility)
