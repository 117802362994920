import * as types from './ActionTypes'
import axios from 'axios'
import { REST_URL } from '../constants/Config'
import qs from 'qs'


export function setSessionExpiry(request) {
    return {
        type: types.SET_SESSION_EXPIRY,
        expiry: request
    }
}

export function clearSessionExpiry(request) {
    return {
        type: types.CLEAR_SESSION_EXPIRY,
        expiry: request
    }
}

export function getIDPListReq(request) {
    return {
        type: types.IDP_LIST_REQ,
        idpList: request
    }
}

export function getIDPListReceive(request) {
    return {
        type: types.IDP_LIST_RECV,
        idpList: request
    }
}

export function getIDPListErr(request) {
    return {
        type: types.IDP_LIST_ERR,
        idpList: request
    }
}

export function getUserFacilityRelyingPartiesReq(request) {
    return {
        type: types.USER_FAC_RP_REQ,
        payload: request
    }
}

export function getUserFacilityRelyingPartiesReceive(request) {
    return {
        type: types.USER_FAC_RP_RECV,
        payload: request
    }
}

export function getUserFacilityRelyingPartiesError(request) {
    return {
        type: types.USER_FAC_RP_ERR,
        payload: request
    }
}



export function setFacility(request) {
    return {
        type: types.MYCHART_SET_FACILITY,
        facility: request
    }
}

export function userAccountCheckReq(request) {
    return {
        type: types.USER_ACCT_CHECK_REQ,
        payload: request
    }
}

export function userAccountCheckRecv(request) {
    return {
        type: types.USER_ACCT_CHECK_RECV,
        payload: request
    }
}

export function userAccountCheckErr(request) {
    return {
        type: types.USER_ACCT_CHECK_ERR,
        payload: request
    }
}

export function clearRelyingParties(request) {
    return {
        type: types.USER_ACCT_CHECK_CLEAR,
        data: request
    }
}

export function setUserDetails(request) {
    return {
        type: types.USER_ACCT_SETPROFILE,
        payload: request
    }
}

export function setUserEmail(request) {
    return {
        type: types.USER_ACCT_SETEMAIL,
        payload: request
    }
}

export function updateUserEmailReq(request) {
    return {
        type: types.USER_ACCT_UPDATEEMAIL_REQ,
        payload: request
    }
}

export function updateUserEmailReceive(request) {
    return {
        type: types.USER_ACCT_UPDATEEMAIL_RECV,
        payload: request
    }
}

export function updateUserEmailError(request) {
    return {
        type: types.USER_ACCT_UPDATEEMAIL_ERR,
        payload: request
    }
}

export function MyChartAuthReq(request) {
    return {
        type: types.AUTH_MYCHART_REQ,
        payload: request
    }
}

export function MyChartAuthReceive(request) {
    return {
        type: types.AUTH_MYCHART_RECV,
        payload: request
    }
}

export function MyChartAuthError(request) {
    return {
        type: types.AUTH_MYCHART_ERR,
        payload: request
    }
}

export function clearPhoneNumbers(request) {
    return {
        type: types.AUTH_MYCHART_CLEAR,
        data: request
    }
}

export function MyChartValidateReq(request) {
    return {
        type: types.AUTH_MYCHARTVALIDATE_REQ,
        payload: request
    }
}

export function MyChartValidateReceive(request) {
    return {
        type: types.AUTH_MYCHARTVALIDATE_RECV,
        payload: request
    }
}

export function MyChartValidateError(request) {
    return {
        type: types.AUTH_MYCHARTVALIDATE_ERR,
        payload: request
    }
}

export function SingleFileIdentityReq(request) {
    return {
        type: types.AUTH_SF_IDENTITY_REQ,
        payload: request
    }
}

export function SingleFileIdentityReceive(request) {
    return {
        type: types.AUTH_SF_IDENTITY_RECV,
        payload: request
    }
}

export function SingleFileIdentityError(request) {
    return {
        type: types.AUTH_SF_IDENTITY_ERR,
        payload: request
    }
}

export function InsertSingleFileIdentityReq(request) {
    return {
        type: types.INSERT_SF_IDENTITY_REQ,
        payload: request
    }
}

export function InsertSingleFileIdentityReceive(request) {
    return {
        type: types.INSERT_SF_IDENTITY_RECV,
        payload: request
    }
}

export function InsertSingleFileIdentityError(request) {
    return {
        type: types.INSERT_SF_IDENTITY_ERR,
        payload: request
    }
}

export function InsertSingleFileAuthenticationReq(request) {
    return {
        type: types.INSERT_SF_AUTH_REQ,
        payload: request
    }
}

export function InsertSingleFileAuthenticationReceive(request) {
    return {
        type: types.INSERT_SF_AUTH_RECV,
        payload: request
    }
}

export function InsertSingleFileAuthenticationError(request) {
    return {
        type: types.INSERT_SF_AUTH_ERR,
        payload: request
    }
}

export function InsertSingleFileMFAReq(request) {
    return {
        type: types.INSERT_SF_MFA_REQ,
        payload: request
    }
}

export function InsertSingleFileMFAReceive(request) {
    return {
        type: types.INSERT_SF_MFA_RECV,
        payload: request
    }
}

export function InsertSingleFileMFAError(request) {
    return {
        type: types.INSERT_SF_MFA_ERR,
        payload: request
    }
}

export function checkRelyingPartiesReq(request) {
    return {
        type: types.CHECK_RP_REQ,
        payload: request
    }
}

export function checkRelyingPartiesReceive(request) {
    return {
        type: types.CHECK_RP_RECV,
        payload: request
    }
}

export function checkRelyingPartiesError(request) {
    return {
        type: types.CHECK_RP_ERR,
        payload: request
    }
}

export function getUserAvailableRelyingPartiesReq(request) {
    return {
        type: types.USER_AVAIL_RP_REQ,
        payload: request
    }
}

export function getUserAvailableRelyingPartiesReceive(request) {
    return {
        type: types.USER_AVAIL_RP_RECV,
        payload: request
    }
}

export function getUserAvailableRelyingPartiesError(request) {
    return {
        type: types.USER_AVAIL_RP_ERR,
        payload: request
    }
}

export function removeUserRPReq(request) {
    return {
        type: types.USER_REMOVE_RP_REQ,
        payload: request
    }
}

export function removeUserRPReceive(request) {
    return {
        type: types.USER_REMOVE_RP_RECV,
        payload: request
    }
}

export function removeUserRPError(request) {
    return {
        type: types.USER_REMOVE_RP_ERR,
        payload: request
    }
}


export function clearRemoveRP() {
    return {
        type: types.USER_REMOVE_RP_CLR,
        payload: undefined
    }
}

export function addUserRPReq(request) {
    return {
        type: types.USER_ADD_RP_REQ,
        payload: request
    }
}

export function addUserRPReceive(request) {
    return {
        type: types.USER_ADD_RP_RECV,
        payload: request
    }
}

export function InsertSingleFileRPReq(request) {
    return {
        type: types.INSERT_SF_RP_REQ,
        payload: request
    }
}

export function InsertSingleFileRPReceive(request) {
    return {
        type: types.INSERT_SF_RP_RECV,
        payload: request
    }
}

export function InsertSingleFileRPError(request) {
    return {
        type: types.INSERT_SF_RP_ERR,
        payload: request
    }
}

export function PatientSearchReq(request) {
    return {
        type: types.AUTH_PATIENT_SEARCH_REQ,
        payload: request
    }
}

export function PatientSearchReceive(request) {
    return {
        type: types.AUTH_PATIENT_SEARCH_RECV,
        payload: request
    }
}

export function PatientSearchError(request) {
    return {
        type: types.AUTH_PATIENT_SEARCH_ERR,
        payload: request
    }
}

export function Oauth2PatientReceive(request) {
    return {
        type: types.OAUTH2_PATIENT_RECV,
        payload: request
    }
}

export function Oauth2PatientError(request) {
    return {
        type: types.OAUTH2_PATIENT_ERR,
        payload: request
    }
}

export function wprAccountSearchReq(request) {
    return {
        type: types.AUTH_WPR_SEARCH_REQ,
        payload: request
    }
}

export function wprAccountSearchReceive(request) {
    return {
        type: types.AUTH_WPR_SEARCH_RECV,
        payload: request
    }
}

export function wprAccountSearchError(request) {
    return {
        type: types.AUTH_WPR_SEARCH_ERR,
        payload: request
    }
}

export function setProviderFacility(request) {
    return {
        type: types.PROVIDER_SET_FACILITY,
        facility: request
    }
}

export function ProviderFacilityAuthReq(request) {
    return {
        type: types.AUTH_PROVIDER_REQ,
        payload: request
    }
}

export function ProviderFacilityAuthReceive(request) {
    return {
        type: types.AUTH_PROVIDER_RECV,
        payload: request
    }
}

export function ProviderFacilityAuthError(request) {
    return {
        type: types.AUTH_PROVIDER_ERR,
        payload: request
    }
}

export function ProviderFacilityVerificationReq(request) {
    return {
        type: types.AUTH_PROVIDERVERIFY_REQ,
        payload: request
    }
}

export function ProviderFacilityVerificationReceive(request) {
    return {
        type: types.AUTH_PROVIDERVERIFY_RECV,
        payload: request
    }
}

export function ProviderFacilityVerificationError(request) {
    return {
        type: types.AUTH_PROVIDERVERIFY_ERR,
        payload: request
    }
}

export function clearProviderVerify(request) {
    return {
        type: types.AUTH_PROVIDERVERIFY_CLR,
        payload: undefined
    }
}

export function SetAuthSuccess(request) {
    return {
        type: types.SET_AUTH_SUCCESS,
        payload: request
    }
}

export function LogoutUser(request) {
    return {
        type: types.USER_LOGOUT,
        payload: request
    }
}

export function CloseAccountReq(request) {
    return {
        type: types.CLOSE_ACCOUNT_REQ,
        payload: request
    }
}

export function CloseAccountReceive(request) {
    return {
        type: types.CLOSE_ACCOUNT_RECV,
        payload: request
    }
}

export function CloseAccountError(request) {
    return {
        type: types.CLOSE_ACCOUNT_ERR,
        payload: request
    }
}



/**
 * get the list of available IDPs for patient or facility
 * @param {object} request  - idpType 
 */
export function fetchIDPList(request) {
    return function (dispatch, getState) {
        request.idpStatus = 'active'
        dispatch(getIDPListReq(request))
        return axios.get(REST_URL + '/sf/rest/idp?' + qs.stringify(request), request).then(function (response) {
            const result = response.data
            result.idpKey = request.idpType
            dispatch(getIDPListReceive(result))
        })
            .catch(function (error) {
                dispatch(getIDPListErr(error.data))
            })
    }
}


export function doMyChartLogin(request) {
    return function (dispatch, getState) {

        const credentials = {
            WebAccountUsername: request.WebAccountUsername,
            WebAccountPassword: request.WebAccountPassword,
            idp_id: request.idp_id,
            addRP: request.addRP
        }
        dispatch(MyChartAuthReq(credentials))
        return axios.post(REST_URL + '/sf/rest/MyChartLogin.json', credentials).then(function (response) {
        //return axios.post(REST_URL + '/sf-identity/' + request.idpId + '/rest/myChartAuthContext.json', credentials).then(function (response) {
            dispatch(setHeader(response.data.xtoken));
            const result = response.data
            if (result.patient !== null) {
                dispatch(MyChartAuthReceive(result))
                const authState = getState().Authenticate
            }
        })
            .catch(function (error) {
                if (error.response.data.xtoken) {
                    dispatch(setHeader(error.response.data.xtoken));
                }
                dispatch(MyChartAuthError(error.response));
            })
    }
}

export function doMyChartVerification(request) {
    return function (dispatch, getState) {
        const credentials = {
            WebAccountUsername: request.WebAccountUsername,
            WebAccountPassword: request.WebAccountPassword
        }
        dispatch(MyChartValidateReq(request))
        return axios.post(REST_URL + '/sf-identity/' + request.idpId + '/rest/myChartAuthContext.json', credentials).then(function (response) {
            dispatch(MyChartValidateReceive(response.data))
        })
            .catch(function (error) {
                dispatch(MyChartAuthError(error.data));
            })
    }
}

/**
 * Get SF account profile for user
 * @param {object} request - JSON parameters to pass to service
 */
export function SFSignup(request) {
    return function (dispatch, getState) {
        dispatch(SingleFileIdentityReq(request))
        return axios.post(REST_URL + '/sf/rest/userSFSignup.json', request).then(function (response) {  //+ qs.stringify(request)
            
            dispatch(setHeader(response.data.xtoken));
            dispatch(SingleFileIdentityReceive(response.data));
        })
        .catch(function (error) {

            dispatch(setHeader(error.response.data.xtoken));
            dispatch(SingleFileIdentityError(error.response.status))
        })
         
    }
}

/**
 * Get SF account profile for user
 * @param {object} request - JSON parameters to pass to service
 */
export function SFLogin(request) {
    return function (dispatch, getState) {

        dispatch(SingleFileIdentityReq(request))
        return axios.post(REST_URL + '/sf/rest/userSFLogin.json', request).then(function (response) {  //+ qs.stringify(request)
            dispatch(setHeader(response.data.xtoken));
            dispatch(SingleFileIdentityReceive(response.data));

        })
        .catch(function (error) {

            dispatch(setHeader(error.response.data.xtoken));
            dispatch(SingleFileIdentityError(error.response.status))
            // dispatch(doPatientSearch({
            //     PatientID: userState.patientID
            // }))


        })
         
    }
}

/**
 * Get basic facebook profile information for user
 * @param {object} request - JSON parameters to pass to service
 */
export function getFBProfile(request) {
    return function (dispatch, getState) {
        return axios.get(REST_URL + '/sf/rest/userFBProfile.json?' + qs.stringify(request)).then(function (response) {

            dispatch(setHeader(response.data.xtoken));
            dispatch(SingleFileIdentityReceive(response.data));
        })
            .catch(function (error) {

                dispatch(setHeader(error.response.data.xtoken));
                dispatch(SingleFileIdentityError(error.response.status))
                    
            })
    }
}

/**
 * Get basic google profile information for user
 * @param {object} request - JSON parameters to pass to service
 */
export function getGoogleProfile(request) {
    return function (dispatch, getState) {
        return axios.get(REST_URL + '/sf/rest/userGoogleProfile.json?' + qs.stringify(request)).then(function (response) {

            dispatch(setHeader(response.data.xtoken));
            dispatch(SingleFileIdentityReceive(response.data));            

        })
        .catch(function (error) {

                dispatch(setHeader(error.response.data.xtoken));
                dispatch(SingleFileIdentityError(error.response.status))
                // dispatch(doPatientSearch({
                //     PatientID: userState.patientID
                // }))
              
        })
    }
}


/**
 * Look up registered relying parties for user
 * @param {object} request - JSON parameters to pass to service
 */
export function checkRelyingParties(request) {
    return function (dispatch, getState) {
        dispatch(checkRelyingPartiesReq(request))
        return axios.get(REST_URL + '/sf/rest/userRelyingParties.json?' + qs.stringify(request) + '&cs=' + Date.now()).then(function (response) {
            const result = response.data
            dispatch(setHeader(result.xtoken));
            dispatch(checkRelyingPartiesReceive(result))
        })
            .catch(function (error) {
                dispatch(checkRelyingPartiesError(error.response));
            })

    }
}

/**
 * Get available relying parties for user - only displays ones not registered to account
 * @param {object} request - JSON parameters to pass to service
 */
export function getUserAvailableRelyingParties(request) {
    return function (dispatch, getState) {
        dispatch(getUserAvailableRelyingPartiesReq(request))
        return axios.get(REST_URL + '/sf/rest/userAvailableRelyingParties.json?' + qs.stringify(request)).then(function (response) {
            const result = response.data
            dispatch(setHeader(result.xtoken))
            dispatch(getUserAvailableRelyingPartiesReceive(result))
        })
            .catch(function (error) {
                if (error.response.data.xtoken !== undefined) {
                    dispatch(setHeader(error.response.data.xtoken))
                }
                dispatch(getUserAvailableRelyingPartiesError(error))
            })
    }
}

/**
 * Add relying party for a Single-FILE user
 * @param {object} request   
 */
export function createSingleFileRelyingParty(request) {
    let newReq = {}
    const restCall = request.verify === true ? 'userRelyingPartiesValidate.json' : 'userRelyingParties.json'
    if (request.verify !== undefined) {
        delete request.verify
    }
    request.relying_party_access_status_value = 'active'
    newReq.user_relying_party_add = request
    return function (dispatch, getState) {
        dispatch(InsertSingleFileRPReq(newReq))
        return axios.post(REST_URL + '/sf/rest/' + restCall, newReq).then(function (response) {
            const result = response.data
            dispatch(setHeader(result.xtoken));
            dispatch(InsertSingleFileRPReceive(result));
        })
            .catch(function (error) {
                if (error.response !== undefined) {
                    dispatch(setHeader(error.response.data.xtoken));
                    dispatch(InsertSingleFileRPError(error.response));
                } else {
                    dispatch(InsertSingleFileRPError(error));
                }

            })
    }
}

export function createNewFacilityRecord(request) {
    return function (dispatch, getState) {
        const rpReq = {
            user_identity_id: request.user_identity_id,
            relying_party_info: request.relying_party_info,
            relying_party_access_info: request.relying_party_access_info,
            verify: true
        }
        dispatch(createSingleFileRelyingParty(rpReq)).then(function (rpResponse) {
            axios.post(REST_URL + '/sf/rest/userSingleFileAuthenticationValidate.json', {
                user_sf_authentication_add: {
                    auth_status: 'Active',
                    auth_username: request.relying_party_access_info,
                    idp_info: request.idpInfo
                }
            }).then(function (sfResponse) {
                dispatch(setHeader(sfResponse.data.xtoken)).then(function (response) {
                    axios.post(REST_URL + '/sf/rest/userSingleFileMFAEnrollmentValidate.json', {
                        user_sf_mfa_enrollment_add: {
                            auth_id: sfResponse.data.user_sf_authentication.auth_id,
                            mfa_status: 'Active',
                            mfa_type_id: request.sam,
                            mfa_type: 'DUO'
                        }
                    })
                }).then(function (mfaResponse) {
                    dispatch(setHeader(mfaResponse.data.xtoken))
                }).catch(function (sfErr) {
                    dispatch(setHeader(sfErr.response.data.xtoken));
                })
            }).catch(function (rpErr) {
                dispatch(setHeader(rpErr.response.data.xtoken));

            })
        }).catch(function (err) {
            dispatch(setHeader(err.response.data.xtoken));

        })
    }

}

/**
 * Remove relying party for a Single-FILE user
 * @param {object} request   
 */
export function removeRelyingParty(request) {
    return function (dispatch, getState) {
        dispatch(removeUserRPReq(request))
        //return axios.put(REST_URL + '/sf/rest/userRelyingParty/accessId/' + request.rpAccessID + '?accessStatus=Removed').then(function (response) {
        return axios.get(REST_URL + '/sf/rest/userRelyingPartyRemove/?' + qs.stringify(request)).then(function (response) {
            const result = response.data
            dispatch(setHeader(result.xtoken));
            dispatch(removeUserRPReceive(result));
        })
            .catch(function (error) {
                dispatch(setHeader(error.response.data.xtoken));
                dispatch(removeUserRPError(error.response));
            })
    }
}

/**
 * Check status of SF account
 * @param {object} request - JSON parameters to pass to service
 */
export function checkSingleFileIdentity(request) {
    return function (dispatch, getState) {

        dispatch(SingleFileIdentityReq(request))
        return axios.get(REST_URL + '/sf/rest/userSingleFileIdentities.json?' + qs.stringify(request), request).then(function (response) {
            const results = response.data.user_single_file_identities.single_file_identities
            let result = results.find(x => x.sf_auth_status_value === 'Active')
            if (result === undefined) {
                result = results.find(x => x.sf_auth_status_value === 'Disabled')
            }
            if (result === undefined) {
                result = results.find(x => x.sf_auth_status_value === 'Locked Out')
            }
            if (result === undefined) {
                result = {
                    status: 'no account'
                }
            }
            dispatch(setHeader(response.data.xtoken));
            dispatch(SingleFileIdentityReceive({
                user_single_file_identity: result
            }));

        })
            .catch(function (error) {
                const provider = getState().Authenticate.provider
                if (error.response.status === 404 && provider === undefined) {
                    const userState = getState().Authenticate.mychart.authenticateWebAccountResult.webUser
                    if (userState.isNonPatient === 'false') {
                        dispatch(setHeader(error.response.data.xtoken));
                        dispatch(SingleFileIdentityError(error.response.status))
                        dispatch(doPatientSearch({
                            PatientID: userState.patientID
                        }))
                    } else if (userState.isNonPatient === 'true') {
                        dispatch(setHeader(error.response.data.xtoken));
                        dispatch(SingleFileIdentityError(error.response.status))
                        dispatch(doPatientSearch({
                            id: userState.webAccountID,
                            id_type: 'WPRINTERNAL'
                        }))
                    } else {
                        dispatch(setHeader(error.response.data.xtoken));
                        dispatch(SingleFileIdentityError(error.response.status))
                    }
                } else {
                    dispatch(setHeader(error.response.data.xtoken));
                    dispatch(SingleFileIdentityError(error.response))
                }
            })
    }
}

export function createPatientIdentity(request) {
    return function (dispatch, getState) {
        return axios.post(REST_URL + '/sf/rest/userPatientAccount.json', request).then(function (response) {

            dispatch(setHeader(response.data.xtoken));
            dispatch(clearPhoneNumbers())
            dispatch(InsertSingleFileAuthenticationReceive(response.data.sfAuth));
            dispatch(InsertSingleFileMFAReceive(response.data.sfMFA));
            if (response.data.checkRP) {
                dispatch(checkRelyingPartiesReceive(response.data.checkRP));
            } if (response.data.getRP) {
                dispatch(getUserFacilityRelyingPartiesReceive(response.data.getRP));
            } if (response.data.setRP) {
                dispatch(InsertSingleFileRPReceive(response.data.setRP));
            }
        })
            .catch(function (error) {
                dispatch(setHeader(error.response.data.xtoken));
                dispatch(InsertSingleFileAuthenticationError(error.response.status));
            })
    }
}

export function createSingleFileIdentity(request) {
    let newReq = {}
    request.sf_user_identity_status = 'Active'
    newReq.user_sf_identity_add = request
    return function (dispatch, getState) {
        return axios.post(REST_URL + '/sf/rest/userProviderAccount.json', request).then(function (response) {
            dispatch(setHeader(response.data.xtoken));
            dispatch(InsertSingleFileIdentityReceive(response.data.sfIdentity));
            dispatch(InsertSingleFileAuthenticationReceive(response.data.sfAuth));
            dispatch(InsertSingleFileMFAReceive(response.data.sfMFA));
            dispatch(getUserFacilityRelyingPartiesReceive(response.data.getRP));
            dispatch(InsertSingleFileRPReceive(response.data.setRP));


        })
            .catch(function (error) {
                dispatch(setHeader(error.response.data.xtoken));
                dispatch(InsertSingleFileAuthenticationError(error.response.status));
            })
    }
}

export function updateUserProfile(request) {
    return function (dispatch, getState) {
        dispatch(updateUserEmailReq(request))
        return axios.put(REST_URL + '/sf/rest/userSingleFileIdentity/uiId/?' + qs.stringify(request)).then(function (response) {
            const result = response.data
            dispatch(setHeader(result.xtoken))
            dispatch(updateUserEmailReceive(result))
        })
            .catch(function (error) {
                dispatch(setHeader(error.response.data.xtoken));
                dispatch(updateUserEmailError(error.response))
            })
    }

}

export function createSingleFileAuthentication(request) {
    let newReq = {}
    request.auth_status = 'Active'
    newReq.user_sf_authentication_add = request
    return function (dispatch, getState) {
        dispatch(InsertSingleFileAuthenticationReq(newReq))
        return axios.post(REST_URL + '/sf/rest/userSingleFileAuthentication.json', newReq).then(function (response) {
            const result = response.data
            dispatch(setHeader(result.xtoken));
            dispatch(InsertSingleFileAuthenticationReceive(result));
        })
            .catch(function (error) {
                dispatch(setHeader(error.response.data.xtoken));
                dispatch(InsertSingleFileAuthenticationError(error.response.status));
            })
    }

}

export function createSingleFileMFA(request) {
    let newReq = {}
    request.mfa_status = 'Active'
    newReq.user_sf_mfa_enrollment_add = request
    return function (dispatch, getState) {
        dispatch(InsertSingleFileMFAReq(newReq))
        return axios.post(REST_URL + '/sf/rest/userSingleFileMFAEnrollment.json', newReq).then(function (response) {
            dispatch(setHeader(response.data.xtoken));
            dispatch(InsertSingleFileMFAReceive(response.data));
        })
            .catch(function (error) {
                dispatch(setHeader(error.response.data.xtoken));
                dispatch(InsertSingleFileMFAError(error.response.status));
            })
    }

}

export function doPatientSearch(request) {
    return function (dispatch, getState) {
        dispatch(PatientSearchReq(request))
        return axios.post(REST_URL + '/sf/rest/patientActivation.json', request).then(function (response) {
            dispatch(setHeader(response.data.xtoken));
            dispatch(PatientSearchReceive(response.data));
        })
            .catch(function (error) {
                if (error.response.data.xtoken) {
                    dispatch(setHeader(error.response.data.xtoken));
                }
                dispatch(PatientSearchError(error.response));
            })
    }
}

export function addPatientFacility(request) {
    return function (dispatch, getState) {
        dispatch(addUserRPReq(request))
        dispatch(setFacility(request))
    }
}

export function doWPRAccountSearch(request) {
    return function (dispatch, getState) {
        dispatch(wprAccountSearchReq(request))
        return axios.post(REST_URL + '/sf-identity/rest/wprAccountSearch.json', request).then(function (response) {
            dispatch(wprAccountSearchReceive(response.data));
        })
            .catch(function (error) {
                dispatch(wprAccountSearchError(error.data));
            })
    }
}

/* export function doFacilityLogin(request) {
    return function (dispatch, getState) {
        dispatch(ProviderFacilityAuthReq(request))
        const credentials = {
            userName: request.userName,
            password: request.password
        }
        return axios.post(REST_URL + '/sf/rest/facilityLogin/' + request.idpId, credentials).then(function (response) {
            // return axios.post(REST_URL + '/sf-identity/' + request.idpId + '/rest/userFacilityAuthContext.json', credentials).then(function (response) {
            dispatch(setHeader(response.data.xtoken));
            dispatch(ProviderFacilityAuthReceive(response.data));
        }).then((response) => {
            const authState = getState().Authenticate
            const status = authState.provider.auth_success
            if (status === 'true') {
                dispatch(setHeader(authState.xtoken));
                dispatch(checkSingleFileIdentity({
                    user: authState.provider.user.user_principal_name,
                    idp: authState.facility.idp_info
                }))
            } else {
                dispatch(setHeader(authState.xtoken));
                dispatch(ProviderFacilityAuthError(response.data));
            }
        })
            .catch(function (error) {
                dispatch(setHeader(error.response.data.xtoken));
                dispatch(ProviderFacilityAuthError(error));
            })
    }
} */

export function doUnifiedFacilityLogin(request) {
    return function (dispatch, getState) {
        dispatch(ProviderFacilityAuthReq(request))
        const credentials = {
            userName: request.userName,
            password: request.password
        }
        return axios.post(REST_URL + '/sf/rest/unifiedFacilityLogin', credentials).then(function (response) {
            // return axios.post(REST_URL + '/sf-identity/' + request.idpId + '/rest/userFacilityAuthContext.json', credentials).then(function (response) {
            dispatch(setHeader(response.data.xtoken));
            dispatch(ProviderFacilityAuthReceive(response.data));
        }).then((response) => {
            const authState = getState().Authenticate
            const status = authState.provider.auth_success
            if (status === 'true') {
                dispatch(setHeader(authState.xtoken));
                dispatch(checkSingleFileIdentity({
                    //   user: authState.provider.user.user_principal_name,
                    idp: authState.provider.idp.idp_info
                }))
            } else {
                dispatch(setHeader(authState.xtoken));
                var payload = {
                    response: {
                        status: 500,
                        statusText: authState.provider.errMessage
                    }
                }
                dispatch(ProviderFacilityAuthError(payload));
            }
        })
            .catch(function (error) {
                dispatch(setHeader(error.response.data.xtoken));
                dispatch(ProviderFacilityAuthError(error));
            })
    }
}

/**
 * Perform authentication to verify user credentials on new facility
 * @param {object} request 
 */
export function doFacilityAccountVerification(request) {
    return function (dispatch, getState) {
        const credentials = {
            userName: request.userName,
            password: request.password
        }
        dispatch(ProviderFacilityVerificationReq(request))
        //return axios.post(REST_URL + '/sf/rest/facilityLoginValidate', credentials).then(function (response) {
        return axios.post(REST_URL + '/sf/rest/facilityLoginValidate/' + request.idpId, credentials).then(function (response) {
            //  return axios.post(REST_URL + '/sf-identity/' + request.idpId + '/rest/userFacilityAuthContext.json', credentials).then(function (response) {
            dispatch(setHeader(response.data.xtoken))
            dispatch(ProviderFacilityVerificationReceive(response.data));
        })
            .catch(function (error) {
                dispatch(setHeader(error.response.data.xtoken));
                dispatch(ProviderFacilityVerificationError(error));
            })
    }

}

/**
 * TESTING OAUTH2 FOR IAGREE
 * Redirect patient to Oauth2 login form
 * @param {object} request 
 */
export function facilityLogin(request) {
    //const path = request.sf_fhir_authorize_url + '?response_type=code&client_id=' + request.sf_client_id + '&redirect_uri=' + window.location.origin + request.sf_redirect_uri;
    const path = request.sf_fhir_authorize_url + '?response_type=code&client_id=' + request.sf_client_id + '&redirect_uri=' + window.location.origin + request.sf_redirect_uri + '&aud=' + request.sf_fhir_base_url;
    return function (dispatch, getState) {    
        const idp = getState().Authenticate.idpList
        if (window){ 
            window.location.href=path;
            return
        }
    }
}

/**
 * TESTING OAUTH2 FOR IAGREE
 * Extract code from Oauth2 authentication to allow FHIR access to SingleFILE
 * @param {object} request 
 */
export function extractOauth2Code(request) {
    return function (dispatch, getState) {
        const token = {
            code: request.code,
            idp_id: request.idp_id,
            addFacility: request.addFacility,
            origin: window.location.origin

        }
        return axios.post(REST_URL + '/sf/rest/oauth2/', token).then(function (response) {
            response.data.addFacility = request.addFacility
            dispatch(setHeader(response.data.xtoken));
            dispatch(Oauth2PatientReceive(response.data));

        })
            .catch(function (error) {
                if (error.response) {
                    dispatch(setHeader(error.response.data.xtoken));
                    dispatch(Oauth2PatientError(error.response));
                }
            })
    }
}

/**
 * Set user account to inactive
 * @param {object} request 
 */
export function doCloseAccount(request) {
    return function (dispatch, getState) {
        dispatch(CloseAccountReq(request))
        return axios.post(REST_URL + '/sf/rest/closeAccount.json', request).then(function (response) {
            dispatch(setHeader(response.data.xtoken))
            dispatch(CloseAccountReceive(response.data))
        }).catch(function (error) {
            dispatch(setHeader(error.response.data.xtoken))
            dispatch(CloseAccountError(error.data))
        })
    }
}

/**
 * Set user account to inactive
 * @param {object} request 
 */
export function doLogoutUser(request) {
    return function (dispatch, getState) {
        dispatch(LogoutUser(request))
        return axios.get(REST_URL + '/sf/rest/Logout').then(function (response) {
            dispatch(clearSessionExpiry())
            window.sessionStorage.clear();
        }).catch(function (error) {
            //    console.log(error);
        })
    }
}

export function setHeader(xtoken) {
    return function (dispatch, getState) {
        if (xtoken !== undefined) {
            window.sessionStorage.setItem("xtoken", xtoken)
            axios.defaults.headers.common['X-XSRF-TOKEN'] = window.sessionStorage.getItem("xtoken") || ''
            let jwt = parseJwt(xtoken)
            const exp = getState().Authenticate.expiry === undefined ? 0 : getState().Authenticate.expiry.exp
            if (jwt.exp !== exp) {
                dispatch(setSessionExpiry({
                    exp: jwt.exp
                }
                ))
            }
        }
    }
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

