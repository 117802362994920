//#10
import React, { Component } from 'react';
import Loader from './common/Loader';
import { Jumbotron, Container, Row, Col, Form, Button, Alert } from 'reactstrap';

class OTPForm extends Component {
    render() {
        const { errorMessage } = this.props
        const { isFetching } = this.props.IDVerification
        return (
            <Jumbotron className="otp">
                <Container>
                    <Row>
                        <Col>
                            {isFetching && 
                                <Loader />
                            }                
                            {errorMessage &&
                                <Alert color="danger">{errorMessage}</Alert>
                            }

                            <h4>Your matching patient record has been found.</h4>

                            <hr className="my-2" />

                            <p>How would you like to receive a one-time passcode to confirm your identity?</p>
                        </Col>
                    </Row>       
                                {this.props.patientMobile.display &&
                                    <div>
                                        <div className="radioRow">
                                            <input type="radio" name="otpMethod" value="smsMobile" onChange={this.props.onChange} id="otpSMSMobile" /> <label htmlFor="otpSMSMobile">&emsp;Send a text message to {this.props.patientMobile.display}</label>
                                        </div>    
                                        <div className="radioRow">
                                            <input type="radio" name="otpMethod" value="callMobile" onChange={this.props.onChange} id="otpCallMobile" /> <label htmlFor="otpCallMobile">&emsp;Call {this.props.patientMobile.display} and play the one-time passcode</label>
                                        </div>
                                    </div>
                                }
                                {this.props.patientVoice.display &&
                                    <div>
                                        <div className="radioRow">
                                            <input type="radio" name="otpMethod" value="smsHome" onChange={this.props.onChange} id="otpSMSHome" /> <label htmlFor="otpSMSHome">&emsp;Send a text message to {this.props.patientVoice.display}</label><br />
                                        </div>
                                        <div className="radioRow">
                                            <input type="radio" name="otpMethod" value="callHome" onChange={this.props.onChange} id="otpCallHome" /> <label htmlFor="otpCallHome">&emsp;Call {this.props.patientVoice.display} and play the one-time passcode</label><br />
                                        </div>
                                    </div>
                                }
                                {this.props.patientWVoice.display &&
                                    <div>
                                        <div className="radioRow">
                                            <input type="radio" name="otpMethod" value="smsWork" onChange={this.props.onChange} id="otpSMSWork" /> <label htmlFor="otpSMSWork">&emsp;Send a text message to {this.props.patientWVoice.display}</label><br />
                                        </div>
                                        <div className="radioRow">
                                            <input type="radio" name="otpMethod" value="callWork" onChange={this.props.onChange} id="otpCallWork" /> <label htmlFor="otpCallWork">&emsp;Call {this.props.patientWVoice.display} and play the one-time passcode</label><br />
                                        </div>
                                    </div>
                                }
                    <Row>            
                        <Col>        
                            <Button color="success" size="lg" type='idpSubmit' onClick={this.props.onSubmit}>Send One-Time Passcode</Button>
                        </Col>    
                    </Row>
                </Container>
            </Jumbotron>
        );
    }

}

export default OTPForm;
