import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { doCloseAccount } from '../actions/Authenticate'
//import ProgressBar from '../components/nav/ProgressBar'
import CloseAccountForm from '../components/CloseAccountForm'
import { Jumbotron, Container, Row, Col } from 'reactstrap'
import '../components/global/Main.css'

/**
 * Close Account - Screen 23
 */
class CloseAccount extends Component {
    /**
     * Constructor for class
     * @param {object} props 
     * 
     */
    constructor(props) {
        super(props)
        this.state = {
            accountType: '',
            nextStep: '',
            reason: '',
            reasonErr: '',
            check: false,
            checkErr: ''
        }
        this.handleReasonInput = this.handleReasonInput.bind(this)
        this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
        this.closeClick = this.closeClick.bind(this)

    }

    /**
     * determine what kind of user
     */
    componentWillMount = () => {
        const { Authenticate } = this.props
        if (Authenticate.AuthSuccess !== undefined) {
            this.setState({ accountType: Authenticate.AuthSuccess.accountType })
        } else {
            this.setState({ loggedOut: true })
        }
    }

    componentDidUpdate = () => {
        const { Authenticate } = this.props
        if (Authenticate.CloseAccount !== undefined) {
            if (Authenticate.CloseAccount === '' && Authenticate.isFetching === false && Authenticate.isErr === false) {
                this.setState({ nextStep: '/AccountClosed' })
            }
        }
    }

    handleReasonInput = (e) => {
        this.setState({ reason: e.target.value })
    }

    handleCheckboxClick = (e) => {
        if (e.target.checked === true)
            this.setState({ check: true })
    }

    closeClick = (e) => {
        const { reason, check } = this.state
        const { dispatch, Authenticate } = this.props
        // if (reason === '') {
        //     this.setState({ reasonErr: 'A brief explanation is required.' })
        // }
        if (check === false) {
            this.setState({ checkErr: 'Please check the box to continue.' })
        }
        //if (reason !== '' && check === true) {
        if (check === true) {
            this.setState({
                //reasonErr: '',
                checkErr: ''
            })
            dispatch(doCloseAccount({
                reason: this.state.reason
            }))
        }
    }

    render() {
        //const navPath = (this.state.accountType === 'provider') ? 'providerAccount' : 'patientAccount'
        return (
            <div className='CloseAccount'>
                {/* <ProgressBar navPath={navPath} /> */}
                <Jumbotron>
                    <Container>
                        <Row>
                            <Col>
                                {this.state.loggedOut === true &&
                                    <Redirect push to="/" />
                                }

                                {this.state.nextStep !== '' &&
                                    <Redirect push to={this.state.nextStep} />
                                }

                                <CloseAccountForm
                                    handleReasonInput={this.handleReasonInput}
                                    handleCheckboxClick={this.handleCheckboxClick}
                                    closeClick={this.closeClick}
                                    reasonErr={this.state.reasonErr}
                                    checkErr={this.state.checkErr}
                                />
                            </Col>    
                        </Row>        
                    </Container>
                </Jumbotron>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(CloseAccount)