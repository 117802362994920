import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Col } from 'reactstrap';

class RadioField extends Component {

    render() {
        const { fieldName, fieldValue, fieldLabel, fieldID, handleChange, checked } = this.props
        return (
                <span className="cc-selector">
                    <Input type="radio" name={fieldName} value={fieldValue} id={fieldID} onChange={handleChange} defaultChecked={checked} /> 
                    <label className={'drinkcard-cc '+ fieldLabel} htmlFor={fieldID}></label>
                </span>
        );
    }

}

RadioField.PropTypes = {
    fieldName: PropTypes.string.isRequired,
    fieldValue: PropTypes.string,
    fieldLabel: PropTypes.string,
    checked: PropTypes.string,
    fieldID: PropTypes.string.isRequired,
}

export default RadioField
