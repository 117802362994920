//#10
import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Jumbotron, Container, Row, Col, Button, Alert, Input, Form } from 'reactstrap';

class MFAForm extends Component {
    render() {
        return (
            <Jumbotron>
                <Container className="mfaForm">
                    <h2 className="text-center">Passcode</h2>
                    <hr className="my-2" />
                    <Row>
                        <Col>
                            <p>Enter the 7-digit one-time passcode to verify your identity.</p>
                            <Form onSubmit={this.props.validateMFA}>
                                <Row> 
                                    <Col>                                   
                                        <label htmlFor="mfaverify">One-Time Passcode: </label>
                                    </Col>
                                    <Col>
                                        <Input id="mfaverify" autoComplete="off" type="number" onChange={this.props.onMFACodeChange} placeholder="Passcode" />                          
                                    </Col>
                                </Row>
                                <br />
                                {this.props.errorMessage !== '' && <Alert color="danger">{this.props.errorMessage}</Alert>}  
                                <br />
                                <p>If you did not receive the one-time passcode, <a href="#" onClick={this.props.resendMFA}>click here</a> to resend the one-time passcode.</p>

                                <Button color="primary" size="lg" id="mfaSubmit" type="submit" onClick={this.props.validateMFA}> Continue{" "}<i className="fa fa-angle-double-right" /></Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        );
    }

}

MFAForm.PropTypes = {
    formState: PropTypes.object.isRequired,
    validateMFA: PropTypes.func.isRequired,
    resendMFA: PropTypes.func.isRequired
}

export default MFAForm