//#2
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { fetchIDPList, setFacility } from '../actions/Authenticate'
import PatientAccessForm from '../components/PatientAccessForm'
import '../components/global/Main.css'

class Patient extends Component {
    constructor(props) {
        super(props)
        this.state = {
            facilitySet: false
        }
        this.setFacilityLogin = this.setFacilityLogin.bind(this)
    }

    componentWillMount = () => {
        const { dispatch } = this.props
        dispatch(fetchIDPList({idpType: 'patient'}))
    }

    setFacilityLogin = (facility) => {
        const { dispatch, Authenticate } = this.props
        const selectedFacility = Authenticate.idpList.find(x => x.idp_id === facility)
        dispatch(setFacility(selectedFacility))
        this.setState({ facilitySet: facility !== '' ? true : false })

    }

    render() {
        const { facilitySet } = this.state
        const idpList = this.props.Authenticate.idpList !== undefined ? this.props.Authenticate.idpList : []
        return (
            <div className="Patient">
                {facilitySet === true && <Redirect push to="/PatientLogin" /> }
                <PatientAccessForm doLogin={this.doLogin} setFacilityLogin={this.setFacilityLogin} idpList={idpList} Authenticate={this.props.Authenticate} />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(Patient)
