import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Jumbotron, Container, Button, Alert, Input, Row, Col, Label, Form } from 'reactstrap';

/**
 * Facility MFA Validate Form (15B) - run SMS/phone call token validation
 */
class FacilityMFAValidateForm extends Component {
    render() {
       // const { isFetching } = this.props.AuthChallenge
        const { formState } = this.props
        return (
            <Jumbotron>
                <Container>
                    <Row>
                        <Col>
                            <Form onSubmit={this.props.validateMFA}>
                                <p>Enter the 7-digit one-time passcode {formState.selectedMethod === 'sms' ? 'you received via SMS text message' : 'displayed when tapping the key icon in your Duo phone app'} at {formState.phoneNo} to verify the second factor of authentication.</p>
                                <br />
                                <Row><Col sm={4}><Label htmlFor="mfaverify">One-Time Passcode: </Label></Col>{" "}<Col sm={4}><Input id="mfaverify" onChange={this.props.onMFACodeChange} placeholder="Passcode" /></Col></Row>
                                <br />
                                <p>If you did not received the one-time passcode, <a href="#" onClick={this.props.resetMFA}>click here</a> to start over.</p>
                                <br />
                                {this.props.errorMessage !== '' && <Alert color="danger">{this.props.errorMessage}</Alert>}
                                <br />
                                <Button color="primary" size="lg" id="mfaSubmit" type="submit" onClick={this.props.validateMFA}>Continue{" "}<i className="fa fa-angle-double-right" /></Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        );
    }

}

FacilityMFAValidateForm.PropTypes = {
    AuthChallenge: PropTypes.object,
    errorMessage: PropTypes.string,
    onMFACodeChange: PropTypes.func.isRequired,
    resendMFA: PropTypes.func.isRequired,
    formState: PropTypes.object.isRequired,
    handleSelection: PropTypes.func.isRequired,
    processSelection: PropTypes.func.isRequired,
    validateMFA: PropTypes.func.isRequired
}

export default FacilityMFAValidateForm;