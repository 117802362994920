import React, { Component } from 'react';
import { Jumbotron, Container, Row, Col } from 'reactstrap';
import '../components/global/AboutFaq.css'

/**
 * About
 */

class Contact extends Component {
  /**
   * Constructor for class
   * @param {object} props 
   * 
   */
    render() {
      return (
          <Jumbotron className="afcContact">
            <Container>
              <Row className="text-center">
                <Col>
                  {/*<h2>Contact Us</h2>*/}
                </Col>
              </Row>
              <Row className="contactUs">
                {/*<Col xs="6" className="text-center">Call Us <br/>
                  <i className="fa fa-phone" />{" "}<span className="phone">1-323-682-8312</span><span className="callPhone"><a href="tel:+13236828312">1-323-682-8312</a></span>
                </Col>
                <Col xs="6" className="text-center">Email Us <br/>
                  <i className="fa fa-envelope" />{" "}<span className="email"><a href="mailto:info@iagree2share.org?subject=iAgree to Share Question">info@iagree2share.org</a></span>
                </Col>*/}
                <Col xs="3" className="text-center">Email Us <br/>
                  <i className="fa fa-envelope" />{" "}<span className="email"><a href="mailto:info@iagree2share.org?subject=iAgree to Share Question">info@iagree2share.org</a></span>
                </Col>
              </Row> 
            </Container>  
              <p className="text-center csTxt text-white">&copy;{' '}{(new Date().getFullYear())}{' '}iAgree{' '}
                <a href="/Terms" className="text-white">Terms and Conditions</a>|<a href="/Privacy" className="text-white">Statement of Privacy</a>
              </p>
          </Jumbotron>    
      )
    }
  }

export default Contact;