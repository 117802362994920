import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Jumbotron, Container, Row, Col, Button, Form, Input } from 'reactstrap';
import './global/Main.css'

/**
 * Provider Main Menu (part of #17)
 */
class ProviderMainMenu extends Component {
    render() {
        const { relyingParties, handleSSOClick } = this.props
        const facilityList = relyingParties.length > 0 ? relyingParties.map((facility) =>
            <div key={facility.facility_id}>
                <span>
                    <Button color='warning' size='lg' className={facility.relying_party_info} onClick={() => { handleSSOClick(facility.relying_party_id) }}>{facility.relying_party_info}</Button>
                </span>
            </div>
        ) : <div className="nofacility">
                <Button color='secondary' size='lg' disabled>No facilities are registered</Button> 
            </div>    
        /*const facilityIt = relyingParties.length > 0 ? relyingParties.map((facility) =>
            <span key={facility.facility_id}>
                <span>{facility.relying_party_info} Access</span>
            </span>
        ) : ''*/
        return (
            <Jumbotron>
                <Container>
                    {this.props.newUser &&
                        <h3 className="newUser">Congratulations! You have successfully enrolled in iAgree.</h3>
                    }
                    <h1 className="text-primary">{/*{facilityIt}*/}Access to My EHR Systems</h1>
                    <hr className="my-2" />
                    <Row>
                        <Col>
                            <p>Click on the EHR system you want to access.</p>
                            <br />
                            {facilityList}
                        </Col>
                    </Row>
                    <br /><br />
                    <Row>
                        <Col><p>Note: If you do not have {/* <img className="imgCR1" src={require("../images/Citrix_Receiver.png")} alt="Citrix Receiver"/> */} Citrix Receiver, <Button outline color="primary" size="sm" href="https://www.citrix.com/products/receiver/" target="_blank">Click to Download and Install</Button> the application.</p>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Form id='ssoForm' target={this.props.targetWindow} name="acsForm" style={{ display: 'none' }} action={this.props.formAction} method="POST">
                                <Input type="hidden" name="SAMLResponse" value={this.props.SAMLResponse} />
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        )
    }

}

ProviderMainMenu.PropTypes = {
    relyingParties: PropTypes.array.isRequired
}

export default ProviderMainMenu

