import React, { Component } from 'react';
import PropTypes from 'prop-types'
//import Tile from '../components/common/Tile'
import Loader from './common/Loader';
import { Jumbotron, Container, Row, Col, Button, Card, CardText } from 'reactstrap';
import './global/Main.css'

/**
 * Patient Access Form (#2)
 */
class PatientAccessForm extends Component {

    render() {
        const { isFetching } = this.props.Authenticate
        const facilityList = this.props.idpList.map((facility) =>
                <Card body outline color="info" className="text-center" key={facility.idp_id}>
                    <CardText>{facility.idp_info}</CardText>
                    <Button color="info" onClick={() => { this.props.setFacilityLogin(facility.idp_id) }}>Login</Button>
                </Card>
        )
        return (
            <Jumbotron>
                <Container>
                    <Row>
                        <Col>
                            {isFetching &&
                                <Loader msg='Loading available facilities' />
                            }
                            <h1 className="text-success">Patient Access</h1>
                            <hr className="my-2" />
                            <p>You can login to Single-FILE using your existing MyCSLink account or Single-FILE account.</p>
                            <br />
                            <Row>
                                <Col sm="6">
                                    <h5>Login using existing MyChart Account</h5>
                                    <hr className="my-2" />
                                    <p>Select the medical facility for your existing MyChart account and press the login button.</p>
                                    <Card body outline color="info">
                                        {facilityList}  
                                    </Card>
                                </Col>

                                <Col sm="6">
                                    <h5>Login using Single-FILE Account</h5>
                                    <hr className="my-2" />
                                    <p>Select press login with your existing Single-FIlE acount, otherwise create a new Single-FILE login using an existing personal account.</p>
                                    <Card body outline color="warning" className="text-center">
                                        <CardText>Login with your Single-FILE Account</CardText>    
                                        <Button color="warning" href="/SFLogin">Login</Button>
                                    </Card>
                                    <Card body outline color="primary" className="text-center">   
                                        <CardText>Create a Single-FILE Login</CardText>    
                                        <Button color="primary" href="/SFCreate">Create</Button>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        )
    }
}

PatientAccessForm.PropTypes = {
    Authenticate: PropTypes.object.isRequired,
    setFacilityLogin: PropTypes.func
}

export default PatientAccessForm;
