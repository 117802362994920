import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import { LogoutUser } from '../actions/Authenticate'
import { Jumbotron, Container, Row, Col } from 'reactstrap';

/**
 * Logged Out Page
 */
class AccountClosed extends Component {

    constructor(props) {
        super(props)
        this.state = {
            timer: null,
            counter: 10
        }

        this.tick = this.tick.bind(this)
    }

    /**
     * logout
     */
    componentWillMount = () => {
        const { dispatch } = this.props
        dispatch(LogoutUser())
    }

    componentDidMount = () => {
        let timer = setInterval(this.tick, 1000)
        this.setState({ timer })
    }

    componentWillUnmount = () => {
        clearInterval(this.state.timer)
    }

    tick = () => {
        this.setState({
            counter: this.state.counter - 1
        })
    }

    render() {
        return (
            <Jumbotron className="LoggedOut">
                {this.state.counter == 0 &&
                    <Redirect push to='/' />
                }
                <Container>
                    <Row>
                      <Col className="text-center">
                        <h1>Logged Out of iAgree</h1>
                      </Col>
                    </Row>
                    <hr className="homeHR" />
                    <Row className="text-center">
                      <Col sm={{ size: 8, push:2}}>
                        <h4>You will be redirected to the iAgree home page in <font color="danger">{this.state.counter}</font> second.</h4>
                      </Col>
                    </Row>
                </Container>
            </Jumbotron>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(AccountClosed)