import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { setUserEmail, updateUserProfile } from '../actions/Authenticate'
//import ProgressBar from '../components/nav/ProgressBar'
import EmailForm from '../components/EmailForm'
import '../components/global/Main.css'

/**
 * Email Container (#24.B)
 */
class Email extends Component {
    /**
     * Constructor for class
     * @param {object} props 
     * 
     */
    constructor(props) {
        super(props);

        /**
         * @property {string} selected          - selected radio option (default email or alternate)
         * @property {string} defaultEmail      - email associated with user account (Single-FILE, Facility, or AD)
         * @property {string} email             - email input field
         * @property {string} emailVerify       - verify email input field
         * @property {boolean} errorMsg         - store error messages here
         * @property {string} nextStep          - when form complete, the next screen to go to
         * @property {string} pathway           - whether user is patient or provider (determines nextStep)
         */
        this.state = {
            selected: '',
            defaultEmail: '',
            email: '',
            emailVerify: '',
            errorMsg: '',
            nextStep: '',
            pathway: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.updateRadioSelected = this.updateRadioSelected.bind(this)
        this.updateEmail = this.updateEmail.bind(this)
    }

    /**
     * Check if this is for a provider or patient
     */
    componentWillMount = () => {
        const { location } = this.props
        if (location.pathname === "/ProviderEmail") {
            this.setState({ pathway: 'provider' })
        } else if (location.pathname === '/PatientFacilityEmail') {
            this.setState({ pathway: 'patientfacility' })
        } else {
            this.setState({ pathway: 'patient' })
        }
    }

    componentDidMount = () => {
        //console.log(this.props)
        const {pathway, defaultEmail} = this.state
        const {Authenticate} = this.props
        if ( pathway === 'patient' && (defaultEmail === '' && Authenticate.user_profile.email)) {
            this.setState({defaultEmail: Authenticate.user_profile.email, selected: Authenticate.user_profile.email})
        } else if ( pathway === 'provider' && (defaultEmail === '' && Authenticate.provider.user.email !== undefined)) {
            this.setState({defaultEmail: Authenticate.provider.user.email, selected: Authenticate.provider.user.email})
        } else if ( pathway === 'patientfacility' && (defaultEmail === '')) {
            this.setState({defaultEmail: Authenticate.mychart.user.webAccountUsername, selected: Authenticate.mychart.user.webAccountUsername})
        }
    }


    /**
     * check whether default or alternate email selected, if alternate perform error checking on fields then process
     * @param {object} e              - button clicked
     */
    handleSubmit = e => {
        const { dispatch } = this.props
        if (this.state.selected !== this.state.defaultEmail) { // this means default not selected
            if (this.state.email !== this.state.emailVerify) {
                return this.setState({ errorMsg: 'Email addresses do not match' })
            } else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email) === false || this.state.email === '') {
                return this.setState({ errorMsg: 'Invalid/missing email address' })
            } else {
                dispatch(setUserEmail({
                    email: this.state.email
                }))
                dispatch(updateUserProfile({
                    email: this.state.email,
                    PT: 'true'
                }))
                .then(response => {
                    if (this.props.Authenticate.updateEmailErr) {
                        this.setState({ errorMsg: this.props.Authenticate.updateEmailErr.message })
                    }
                    else {
                        const path = (this.state.pathway === 'patient') ? '/SelectFacility' : (this.state.pathway === 'patientfacility') ? '/OTP' : '/EstablishProviderMFA'
                        this.setState({ errorMsg: '', nextStep: path })
                    }
                })
            }
        } else { // this means default is selected
            dispatch(setUserEmail({
                email: this.state.selected
            }))
            console.log(this.state.selected)
            console.log(this.props.Authenticate)
            const path = (this.state.pathway === 'patient') ? '/SelectFacility' : (this.state.pathway === 'patientfacility') ? '/OTP' : '/EstablishProviderMFA'
            this.setState({ errorMsg: '', nextStep: path })
        }
    }

    /**
     * set local state to match selected radio button
     * @param {object} e              - radio button clicked
     */
    updateRadioSelected = e => {
        this.setState({
            selected: e.target.value
        })
    }

    /**
     * update local state to match input field text
     * @param {object} e    - input field updated
     */
    updateEmail = e => {
        let field = {}
        field[e.target.name] = e.target.value
        this.setState(field)
    }

    render() {
        //const navPath = this.state.pathway === 'provider' ? 'newProvider' : 'newUser'
        return (
            <div className="Email">
                {this.state.nextStep !== '' &&
                    <Redirect push to={this.state.nextStep} />
                }

                {/* <ProgressBar navPath={navPath} /> */}
                <EmailForm formProps={this.props}
                    pathway={this.state.pathway}
                    defaultEmail={this.state.defaultEmail}
                    err={this.state.errorMsg}
                    onRadioUpdate={this.updateRadioSelected}
                    updateEmail={this.updateEmail}
                    submitEmail={this.handleSubmit}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(Email)