//#17
import React, { Component } from 'react';
//import { connect } from 'react-redux'
//import { Redirect } from 'react-router'
import { Jumbotron, Container, Row, Col } from 'reactstrap';
import '../components/global/Privacy.css'

/**
 * Terms and Conditions
 */
class Terms extends Component {
  /**
   * Constructor for class
   * @param {object} props 
   * 
   */
    render() {
    return (
        <Jumbotron className="Terms">
          <Container>
            <Row className="text-center">
              <Col>
                <h1>iAgree Terms of Use</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <br />
                <p><b>All Users:</b></p>
                <p>You are using  iAgree,  a demonstration project being developed by the iAgree consortium consisting of UC San Diego, UC Irvine, University of Southern California, University of Colorado, and Cedars-Sinai Medical Center.  The work is supported by an award from the National Institutes of Health (Grant Number R01HG011066).   The primary goal of this project is to develop a proof-of-concept system that allows patients to express their preferences on types of electronic health data they wish to share for research in a privacy preserving manner and give them the tools to manage their preferences.</p>
                <p>
                  <ul className="d">
                    <li>iAgree allows you to either register an account directly with iAgree or to use an account that you already have established with either a social media/Internet application (such as Google or Facebook for patients) or an institutional account (for researchers, compliance, or research administrative staff).</li>
                    <li>The EHR systems accessed via iAgree are not under the control of iAgree, so responsibility for the accuracy of EHR data lies with the respective health system. </li>
                    <li>You agree that you will keep your iAgree login ID not share it with anyone.</li>
                    <li>If you have reason to believe that your iAgree or EHR login IDs have been compromised, you will notify iAgree <a href="mailto:info@iagree2share.org?subject=iAgree to Share Question">info@iagree2share.org</a> or call us at (323)-682-8312 immediately.</li>
                    <li>As a participant in the iAgree pilot, you acknowledge that the iAgree project team will track your IP address, date and time of login, duration of each session, and the type of EHR data you access.   The tracking will be used solely to assess the functionality and usability of iAgree.  Only aggregate data that has been de-identified will be shared outside of the iAgree project team. </li>
                      <ul className="c">
                        <li>Please see the <a href="/Privacy" target="_blank">Statement of Privacy</a></li>
                      </ul>
                    <li>If you encounter any problems accessing or using iAgree, please contact us at <a href="mailto:info@iagree2share.org?subject=iAgree to Share Question">info@iagree2share.org</a> or call us at (323)-682-8312.</li>
                    <li>You may be asked to fill out a usability survey and/or participate in focus groups.  Again, any results will de-identified and aggregate before being shared outside of the iAgree team.  </li>
                  </ul>
                </p>
                <br />
                
                <p><b>Patients:</b></p>
                <p><b>Healthcare Providers:</b></p>
                <p></p>                
              </Col>
            </Row>
          </Container>  
        </Jumbotron>    )
  }
}

export default Terms;