import React, { Component } from 'react';
import { Jumbotron, Container, Row, Col, Alert } from 'reactstrap';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import queryString from 'query-string';
import { extractOauth2Code } from '../actions/Authenticate'
import ClipLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import { isEmpty } from 'lodash';
    

class Oauth2Response extends Component {
  /**
   * Constructor for class
   * @param {object} props 
   * 
   */

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      redirect: '',
      error: ''
    };
  }

  componentWillMount = () => {
    const { dispatch, Authenticate } = this.props;
    this.extractToken();
  }

  componentDidMount = () => {
    const { dispatch, Authenticate } = this.props;
    let params = queryString.parse(this.props.location.search)

    if (this.state.code) {
      dispatch(extractOauth2Code({
        code: this.state.code,
        idp_id: Authenticate.facility.idp_id,
        addFacility: this.props.Authenticate.facility.addFacility
      }))
    } else {
      if (params.error =='access_denied') {
        console.log('setting state!' + params.error)
        this.setState({
          error: params.error,
        })
      }
    }
  }

  extractToken = () => {
      let params = queryString.parse(this.props.location.search)

      this.setState({
      code: params.code,
      addFacility: this.props.Authenticate.facility.addFacility
    })
  }

    render() {
      //Can be a string as well. Need to ensure each key-value pair ends with ;
      const override = css` 
        display: block;
        margin: 0 auto;
        border-color: #000000;
      `;  
      const { Authenticate } = this.props;


      if (Authenticate.patient !== undefined) {
        if (Authenticate.patient.phoneNumbers) {
          this.setState({ redirect: "/OTP" })
          console.log("!000");

        }
        else if (Authenticate.patient.status === 500 ||  this.state.error =='access_denied') {
          this.setState({ redirect: "/selectFacility3" })
        } 
      } else{
        if (Authenticate.relying_parties !== undefined && this.state.error=='access_denied') {

            this.setState({ redirect: "/selectFacility3" })
        }
          if (Authenticate.relying_parties == undefined && this.state.error=='access_denied') {
            this.setState({ redirect: "/selectFacility?error=access_denied" })
          }
      } 
      

      return (
        <div>
          <Jumbotron className="afc">
            <Container>
              <Row className="text-center">
                <Col>
                  <h2>Linking your medical record to your iAgree account</h2>
                  <div className="sweet-loading">
                    <ClipLoader css={override} color={"#36D7B7"} speedMultiplier={1.0} />
                  </div>

                  { this.state.redirect != '' && <Redirect push to={this.state.redirect} /> }
                </Col>
                </Row>
            </Container>  
          </Jumbotron>
          </div>
      )
    }
  }

const mapStateToProps = (state, ownProps) => ({
  Authenticate: state.Authenticate,
})

export default connect(mapStateToProps)(Oauth2Response)