import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { fetchIDPList, setFacility, facilityLogin } from '../actions/Authenticate'
import ProgressBar from '../components/nav/ProgressBar'
import SelectFacilityForm from '../components/SelectFacilityForm'
import './SelectFacility.css'
import queryString from 'query-string';


/**
 * Select Facility Container (#9)
 */
class SelectFacility extends Component {
    /**
     * Constructor for class
     * @param {object} props 
     * 
     */
    constructor(props) {
        super(props);

        /**
         * @property {string} selected          - selected radio option of facility
         * @property {boolean} errorMsg         - store error messages here
         * @property {string} nextStep          - when form complete, the next screen to go to
         * @property {string} pathway           - whether user is creating account or logging in
         */
        this.state = {
            selected: '1',
            errorMsg: '',
            nextStep: '',
            error: ''
        }

        this.onRadioUpdate = this.onRadioUpdate.bind(this)
        this.handleContinue = this.handleContinue.bind(this)
        this.handleBack = this.handleBack.bind(this)
    }

    /**
     * Fetch the patient IDP list 
     */
    componentWillMount = () => {
        const { dispatch } = this.props
        dispatch(fetchIDPList({ idpType: 'patient' }))

        let params = queryString.parse(this.props.location.search)

        if (params.error == "access_denied") {
            this.setState({
                error: "Unable to link your iAgree account to the Health System selected."
              })
        }
       

    }

    /**
     * update local state when a facility is selected
     * @param {object} e    - target clicked
     */
    onRadioUpdate = e => {
        this.setState({
            selected: e.target.value
        })
    }

    /**
     * Update redux store with selected facility then proceed to next step
     * @param {object} e    - button clicked
     */
    handleContinue = e => {
        if (this.state.selected) {
            const { dispatch, Authenticate } = this.props
            //const selectedFacility = Authenticate.idpList.find(x => x.idp_info === this.state.selected)
            const selectedFacility = Authenticate.idpList.find(x => x.idp_id === this.state.selected)
            dispatch(setFacility(selectedFacility))
            this.setState({ errMsg: '', nextStep: '/IDVerification' })
        } else {
            this.setState({ errMsg: 'Please make a selection before continuing.' })
        }
    }

    /**
     * Update redux store with selected facility then proceed to next step
     * @param {object} e    - button clicked
     */
    handleOauth2 = e => {
        if (this.state.selected) {
            const { dispatch, Authenticate } = this.props
            //const selectedFacility = Authenticate.idpList.find(x => x.idp_info === this.state.selected)
            const selectedFacility = Authenticate.idpList.find(x => x.idp_id === this.state.selected)
            dispatch(setFacility(selectedFacility))
            dispatch(facilityLogin(selectedFacility))
        } else {
            this.setState({ errMsg: 'Please make a selection before continuing.' })
        }
    }

    handleBack = selectedBtn => {
       if (selectedBtn === 'back') 
            this.props.history.push('/email')
    }

    render() {
        return (
            <div>
                {this.state.nextStep !== '' &&
                    <Redirect push to={this.state.nextStep} />
                }
                <ProgressBar navPath='newUser'/>
                <SelectFacilityForm
                    Authenticate={this.props.Authenticate}
                    err={this.state.errMsg}
                    error={this.state.error}
                    onRadioUpdate={this.onRadioUpdate}
                    handleContinue={this.handleContinue}
                    handleOauth2={this.handleOauth2}
                    handleBack={this.handleBack} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(SelectFacility)