//#4A
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { doMyChartLogin } from '../actions/Authenticate'
import MyChartLoginForm from '../components/MyChartLoginForm'
import { Jumbotron, Container } from 'reactstrap';

class MyChartLogin extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            password: '',
            errorMessage: '',
            nextPage: ''
        }
        this.doLogin = this.doLogin.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentWillMount = () => {

    }


    componentWillReceiveProps = (nextProps) => {
        const { Authenticate } = nextProps
        let nextPage = ''
        const { isErr } = Authenticate
        if (Authenticate.mychart !== undefined) {
            switch (isErr) {
                case true:
                    let errMsg = ''
                    switch (Authenticate.mychart.authenticateWebAccountResult.status) {
                        case "0":
                            errMsg = 'Unknown Error'
                            break
                        case "2":
                            errMsg = 'Username/Password Incorrect'
                            break
                        case "3":
                            errMsg = 'Password Expired'
                            break
                        case "4":
                            errMsg = 'Account Deactivated'
                            break
                        case "5":
                            errMsg = 'Username/Password Incorrect'
                            break
                        case "6":
                            errMsg = 'Account Expired'
                            break
                        case "12":
                            errMsg = 'Maximum Login Attempts Exceeded'
                            break
                        default:
                            errMsg = 'Username/Password Incorrect'
                            break
                    }
                    this.setState({ errorMessage: errMsg })
                    break
                case false:
                    if (Authenticate.sfidentity !== undefined) {
                        if (Authenticate.sfidentity === 404) {
                            if (Authenticate.patientsearch.patient !== undefined) {
                                nextPage = '/PatientFacilityEmail'
                            } else if (Authenticate.wpr !== undefined) {
                                nextPage = '/PatientFacilityEmail'
                            }
                        } else if (Authenticate.sfidentity.user_single_file_identity !== undefined) {
                            nextPage = '/MFA'
                        }
                    }
                    break
                default:
                    nextPage = ''
                    break
            }
        }
        if (nextPage !== '') {
            this.setState({ nextPage: nextPage })
        }
    }

    doLogin = e => {
        const { dispatch, Authenticate } = this.props
        e.preventDefault();
        dispatch(doMyChartLogin({
            WebAccountUsername: this.state.email,
            WebAccountPassword: this.state.password,
            idpId: Authenticate.facility.idp_id       
        }))
    }

    handleChange = e => {
        if (e.target.value !== '') {
            let payload = {}
            payload[e.target.name] = e.target.value
            this.setState(payload)
        }
    }

    render() {
        const { Authenticate } = this.props
        const facility = Authenticate.facility !== undefined ? Authenticate.facility.idp_info : ''
        return (
            <div className="MyChart">
                <Jumbotron>
                    <Container>
                        {Authenticate.facility === undefined &&
                            <Redirect push to={'/Patient'} />
                        }
                        {this.state.nextPage !== '' &&
                            <Redirect push to={this.state.nextPage} />
                        }
                        <MyChartLoginForm errorMessage={this.state.errorMessage} facility={facility} Authenticate={Authenticate} handleChange={this.handleChange} doLogin={this.doLogin} />
                    </Container>
                </Jumbotron>    
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    Authenticate: state.Authenticate
})

export default connect(mapStateToProps)(MyChartLogin)
