import React, { Component } from 'react';
import { Jumbotron, Container, Row, Col } from 'reactstrap';
import '../components/global/AboutFaq.css'

/**
 * About
 */

class About extends Component {
  /**
   * Constructor for class
   * @param {object} props 
   * 
   */
    render() {
      return (
          <Jumbotron className="afc">
            <Container>
              <Row className="text-center">
                <Col>
                  {/*<h2>About Single-FILE</h2>*/}
                  <h2>iAgree</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>iAGREE is a pilot project sponsored by the National Institutes of Health (NIH Grant Number R01HG011066). The project aims to empower patients to make decisions about how their medical records are shared with researchers at multiple health care systems.</p>
                  <br />
                  <p>The goals of iAgree are:</p>
                    <ol>
                      <li>To give patients control over how their medical record data are shared with researchers working at non-profit institutions, for-profit institutions, and governmental organizations across different health care systems.</li>
                      <li>To show researchers how data for research studies are affected based on the decisions that patients make about which types of data to share.</li>
                      <li>To incorporate privacy-enhancing technology into the platform.</li>
                    </ol>  
                  <br /> 
                  <p>As part of the project, data will be collected to assess the usefulness of the iAgree platform. This data will be used to better understand how people are using the platform and how to improve the platform for future users.</p>
                  
                  {/*<p>The Single Federated Identity Login for Electronic Health Records (Single-FILE) project is a pilot project sponsored by the National Institute of Standards and Technology (NIST) and the Office of the National Coordinator for Health Technology (ONC). The project is intended to address a growing concern in healthcare where each institution maintains its own electronic health record (EHR) system. This leads to multiple login credentials for both patients and providers. Different username/password requirements can posse a barrier to accessing EHR data.</p>
                  <br />
                  <p>The specific use case for this pilot involves the transition of patients as they are discharged from Cedars-Sinai Medical Center and admitted to the California Rehabilitation Institute (Cal Rehab). Single-FILE can be used by both patients (or their proxies) and by their healthcare providers. As these patients make the transition from one facility to another, the ability for providers to quickly access complete patient records at both facilities in a timely and convenient manner is essential to providing continuity of care. For patients, the ability to gain a complete view of their EHR will enable greater participation and involvement in maintaining their health.</p>
                  <br />
                  <p>The goals of Single-FILE are:</p>
                    <ol>
                      <li>Provide a single federated identity that can be used to access multiple, independent EHR systems by means of a single sign-on.</li>
                      <li>Incorporate multi-factor authentication to enhance security and privacy.</li>
                      <li>Incorporate privacy-enhancing technology.</li>
                    </ol>  
                  <br />  
                  <p>As part of the project, statistics on usage will be collected to assess the utility and adaptation of Single-FILE in overcoming the problems associated with patients and their healthcare providers trying to manage multiple login IDs at both institutions. The ONC will use the experience gained from Single-FILE to develop a best-practice guide for the use of a federated ID for access to multiple healthcare records systems.</p>
                  <br />*/}
                  <p>For more information about privacy, please see the <a href="/Privacy" target="_blank">Statement of Privacy</a>.</p>
                </Col>
              </Row>
            </Container>  
              <p className="text-center text-white">&copy;{' '}{(new Date().getFullYear())}{' '}iAgree{' '}
                <a href="/Terms" className="text-white">Terms and Conditions</a>|<a href="/Privacy" className="text-white">Statement of Privacy</a>
              </p>
          </Jumbotron>
      )
    }
  }

export default About;