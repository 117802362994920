export const PubKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA0+UnN2WF9FCsCr5gAtAE
5tGQEVBCLkJdNlD1SmJKs/JarJxEj/lOZRywqFKPY7xQk3UV5i8GHdQPMuh+IJp4
BMWAi6dPhD6DN+WlzueqzL45Gnbm8L/SmaFPaM6znT0EjND1CHoCaZ2aP1lmt4cb
QR+NKVT4fHwlTQt6NfWQeG/QdbfUItlsKYGwU7ThQY6APVmPpoXtFvQbZOJBdN9O
pwjLzF9MjuZ+BtlljxpL6zYNEAG8VueQ0eYdWaRwzmy8o9UgvszSHJxaCectADGs
oXDs7c+YG28rJ3o/rdoCts6AK/uNQtBZxJWyEFoXLiYr+X90Pii1UH+eV34uRQ+P
351x5RXZHBfCqsW6L3AG1+8JXsCFo98N+561MWIm6ZW5crBfFOQHPBXA3VAefd+z
3erwkWNP18UlFgPRHThXNgJGJrDnlscFVtNDDFV2fWW8EsRVsjTG6fUJqpCmuGZG
Lx7WMfL6iRpzGcP4lYI5WC/9ZhD2IBMzKDOOqURb7d99hxCD3432ICXIzULmZasD
xYO+JsCCpPGvm6KFu9sWqF8BFLoftJAZopwb5UUh7wX+EThrFCK7MkXsJqL1zyBG
B4NgAK7aMm30oa7mkNr4jdJaG8k7aQ8wsi4JOo4WK1O6kwdiwFWbVj40Uxq1rB9R
kJsCFOhzhiUahc+r5btteWkCAwEAAQ==
-----END PUBLIC KEY-----`