import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router'
import createBrowserHistory from 'history/createBrowserHistory'
import throttle from 'lodash/throttle'
import configureStore from './store/configureStore.js'
import { loadState, saveState } from './store/localStorage'
//import DevTools from './components/DevTools'

import Header from './components/global/Header'
import Footer from './components/global/Footer'
import SessionMonitor from './components/global/SessionMonitor'

import 'bootstrap/dist/css/bootstrap.css'

import Home from './containers/Home'
import Patient from './containers/Patient'
import ProviderSelectFacility from './containers/Provider-SelectFacility'
import ProviderUnifiedLogin from './containers/ProviderUnifiedLogin'
import FacilityMFA from './containers/FacilityMFA'
import EstablishProviderMFA from './containers/EstablishProviderMFA'
import MyChartLogin from './containers/MyChartLogin'
import MFA from './containers/MFA'
import IDVerification from './containers/IDVerification'
import OTP from './containers/OTP'
import OTPVerify from './containers/OTPVerify'
import EstablishMFA from './containers/EstablishMFA'
import AccountHome from './containers/AccountHome'
import Help from './containers/Help'
import AddFacility from './containers/AddFacility'
import Profile from './containers/Profile'
import CloseAccount from './containers/CloseAccount'
import AccountClosed from './containers/AccountClosed'
import LoggedOut from './containers/LoggedOut'
import Email from './containers/Email'
import ForgotPassword from './containers/ForgotPassword'
import NewPassword from './containers/NewPassword'
import SelectFacility from './containers/SelectFacility'
import SelectFacility2 from './containers/SelectFacility2'
import SelectFacility3 from './containers/SelectFacility3'

import HomeSignUp from './containers/HomeSignUp'
import Terms from './containers/Terms'
import Privacy from './containers/Privacy'
import About from './containers/About'
import Faq from './containers/Faq'
import Contact from './containers/Contact'
import Oauth2Response from './containers/Oauth2Response'

import './index.css'

const store = configureStore()

//add listener to copy state to localStorage no more than once per second
store.subscribe(throttle(() => {
  saveState(store.getState())
}), 1000)
const history = createBrowserHistory()

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <div className="rootPage">
        <Header />
        <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/HomeSignUp" component={HomeSignUp} />
        <Route path="/Patient" component={Patient} />
        <Route path="/Provider-SelectFacility" component={ProviderSelectFacility} />
        <Route path="/ProviderLogin" component={ProviderUnifiedLogin} />
        <Route path="/ProviderIDVerification" component={FacilityMFA} />
        <Route path="/ProviderEmail" component={Email} />
        <Route path="/EstablishProviderMFA" component={EstablishProviderMFA} />
        <Route path="/FacilityMFA" component={FacilityMFA} />
        <Route path="/PatientLogin" component={MyChartLogin} />
        <Route path="/PatientFacilityEmail" component={Email} />
        <Route path="/MFA" component={MFA} />
        <Route path="/IDVerification" component={IDVerification} />
        <Route path="/OTP" component={OTP} />
        <Route path="/OTPVerify" component={OTPVerify} />
        <Route path="/EstablishMFA" component={EstablishMFA} />
        <Route path="/AccountHome" component={AccountHome} />
        <Route path="/Help" component={Help} />
        <Route path="/AddFacility" component={AddFacility} />
        <Route path="/ProcessFacility" component={AddFacility} />
        <Route path="/Profile" component={Profile} />
        <Route path="/CloseAccount" component={CloseAccount} />
        <Route path="/AccountClosed" component={AccountClosed} />
        <Route path="/Email" component={Email} />
        <Route path="/SelectFacility" component={SelectFacility} /> 
        <Route path="/SelectFacility2" component={SelectFacility2} />  
        <Route path="/SelectFacility3" component={SelectFacility3} />    
        <Route path="/ForgotPassword" component={ForgotPassword} />
        <Route path="/NewPassword" component={NewPassword} />
        <Route path="/Terms" component={Terms} />
        <Route path="/Privacy" component={Privacy} />
        <Route path="/About" component={About} />
        <Route path="/Faq" component={Faq} />
        <Route path="/Contact" component={Contact} />
        <Route path="/LoggedOut" component={LoggedOut} />
        <Route path="/Oauth2Response" component={Oauth2Response} />
        </Switch>
        <Footer />
        <SessionMonitor />
        {/* <DevTools /> */}
      </div>
    </Router>
  </Provider>,
  document.getElementById('root')
)

