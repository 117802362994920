/**
 * ActionTypes.js
 * Constants used to define Redux Actions
 */


/**
 * Authentication action constants
 */

export const SET_SESSION_EXPIRY = 'SET_SESSION_EXPIRY'
export const CLEAR_SESSION_EXPIRY = 'CLEAR_SESSION_EXPIRY'


export const AUTH_SF_REQ = 'AUTH_SF_REQ'
export const AUTH_SF_RECV = 'AUTH_SF_RECV'
export const AUTH_SF_ERR = 'AUTH_SF_ERR'

export const USER_ACCT_CHECK_REQ = 'USER_ACCT_CHECK_REQ'
export const USER_ACCT_CHECK_RECV = 'USER_ACCT_CHECK_RECV'
export const USER_ACCT_CHECK_ERR = 'USER_ACCT_CHECK_ERR'
export const USER_ACCT_CHECK_CLEAR = 'USER_ACCT_CHECK_CLEAR'

export const USER_ACCT_SETPROFILE = 'USER_ACCT_SETPROFILE'
export const USER_ACCT_SETEMAIL = 'USER_ACCT_SETEMAIL'

export const USER_ACCT_UPDATEEMAIL_REQ = 'USER_ACCT_UPDATEEMAIL_REQ'
export const USER_ACCT_UPDATEEMAIL_RECV = 'USER_ACCT_UPDATEEMAIL_RECV'
export const USER_ACCT_UPDATEEMAIL_ERR = 'USER_ACCT_UPDATEEMAIL_ERR'

export const MYCHART_SET_FACILITY = 'MYCHART_SET_FACILITY'
export const AUTH_MYCHART_REQ = 'AUTH_MYCHART_REQ'
export const AUTH_MYCHART_RECV = 'AUTH_MYCHART_RECV'
export const AUTH_MYCHART_ERR = 'AUTH_MYCHART_ERR'
export const AUTH_MYCHART_CLEAR = 'AUTH_MYCHART_CLEAR'

export const AUTH_MYCHARTVALIDATE_REQ = 'AUTH_MYCHARTVALIDATE_REQ'
export const AUTH_MYCHARTVALIDATE_RECV = 'AUTH_MYCHARTVALIDATE_RECV'
export const AUTH_MYCHARTVALIDATE_ERR = 'AUTH_MYCHARTVALIDATE_ERR'

export const AUTH_SF_IDENTITY_REQ = 'AUTH_SF_IDENTITY_REQ'
export const AUTH_SF_IDENTITY_RECV = 'AUTH_SF_IDENTITY_RECV'
export const AUTH_SF_IDENTITY_ERR = 'AUTH_SF_IDENTITY_ERR'

export const INSERT_SF_IDENTITY_REQ = 'INSERT_SF_IDENTITY_REQ'
export const INSERT_SF_IDENTITY_RECV = 'INSERT_SF_IDENTITY_RECV'
export const INSERT_SF_IDENTITY_ERR = 'INSERT_SF_IDENTITY_ERR'

export const INSERT_SF_AUTH_REQ = 'INSERT_SF_AUTH_REQ'
export const INSERT_SF_AUTH_RECV = 'INSERT_SF_AUTH_RECV'
export const INSERT_SF_AUTH_ERR = 'INSERT_SF_AUTH_ERR'

export const INSERT_SF_MFA_REQ = 'INSERT_SF_MFA_REQ'
export const INSERT_SF_MFA_RECV = 'INSERT_SF_MFA_RECV'
export const INSERT_SF_MFA_ERR = 'INSERT_SF_MFA_ERR'

export const CHECK_RP_REQ = 'CHECK_RP_REQ'
export const CHECK_RP_RECV = 'CHECK_RP_RECV'
export const CHECK_RP_ERR = 'CHECK_RP_ERR'

export const USER_AVAIL_RP_REQ = 'USER_AVAIL_RP_REQ'
export const USER_AVAIL_RP_RECV = 'USER_AVAIL_RP_RECV'
export const USER_AVAIL_RP_ERR = 'USER_AVAIL_RP_ERR'

export const USER_REMOVE_RP_REQ = 'USER_REMOVE_RP_REQ'
export const USER_REMOVE_RP_RECV = 'USER_REMOVE_RP_RECV'
export const USER_REMOVE_RP_ERR = 'USER_REMOVE_RP_ERR'

export const USER_REMOVE_RP_CLR = 'USER_REMOVE_RP_CLR'

export const USER_ADD_RP_REQ = 'USER_ADD_RP_REQ'
export const USER_ADD_RP_RECV = 'USER_ADD_RP_RECV'

export const INSERT_SF_RP_REQ = 'INSERT_SF_RP_REQ'
export const INSERT_SF_RP_RECV = 'INSERT_SF_RP_RECV'
export const INSERT_SF_RP_ERR = 'INSERT_SF_RP_ERR'

export const AUTH_PATIENT_SEARCH_REQ = 'AUTH_PATIENT_SEARCH_REQ'
export const AUTH_PATIENT_SEARCH_RECV = 'AUTH_PATIENT_SEARCH_RECV'
export const AUTH_PATIENT_SEARCH_ERR = 'AUTH_PATIENT_SEARCH_ERR'

export const OAUTH2_PATIENT_RECV = 'OAUTH2_PATIENT_RECV'
export const OAUTH2_PATIENT_ERR = 'OAUTH2_PATIENT_ERR'

export const AUTH_WPR_SEARCH_REQ = 'AUTH_WPR_SEARCH_REQ'
export const AUTH_WPR_SEARCH_RECV = 'AUTH_WPR_SEARCH_RECV'
export const AUTH_WPR_SEARCH_ERR = 'AUTH_WPR_SEARCH_ERR'

export const IDP_LIST_REQ = 'IDP_LIST_REQ'
export const IDP_LIST_RECV = 'IDP_LIST_RECV'
export const IDP_LIST_ERR = 'IDP_LIST_ERR'

export const USER_FAC_RP_REQ = 'USER_FAC_RP_REQ'
export const USER_FAC_RP_RECV = 'USER_FAC_RP_RECV'
export const USER_FAC_RP_ERR = 'USER_FAC_RP_ERR'

export const PROVIDER_SET_FACILITY = 'PROVIDER_SET_FACILITY'
export const AUTH_PROVIDER_REQ = 'AUTH_PROVIDER_REQ'
export const AUTH_PROVIDER_RECV = 'AUTH_PROVIDER_RECV'
export const AUTH_PROVIDER_ERR = 'AUTH_PROVIDER_ERR'

export const AUTH_PROVIDERVERIFY_REQ = 'AUTH_PROVIDERVERIFY_REQ'
export const AUTH_PROVIDERVERIFY_RECV = 'AUTH_PROVIDERVERIFY_RECV'
export const AUTH_PROVIDERVERIFY_ERR = 'AUTH_PROVIDERVERIFY_ERR'

export const AUTH_PROVIDERVERIFY_CLR = 'AUTH_PROVIDERVERIFY_CLR'

export const SET_AUTH_SUCCESS = 'SET_AUTH_SUCCESS'
export const LOGOUT_USER = 'LOGOUT_USER'
export const USER_LOGOUT = 'USER_LOGOUT'

export const CLOSE_ACCOUNT_REQ = 'CLOSE_ACCOUNT_REQ'
export const CLOSE_ACCOUNT_RECV = 'CLOSE_ACCOUNT_RECV'
export const CLOSE_ACCOUNT_ERR = 'CLOSE_ACCOUNT_ERR'


/**
 * AuthChallenge action constants
 */
export const MFA_OPTIONS_REQ = 'MFA_OPTIONS_REQ'
export const MFA_OPTIONS_RECV = 'MFA_OPTIONS_RECV'
export const MFA_OPTIONS_ERR = 'MFA_OPTIONS_ERR'

export const FACILITY_MFA_OPTIONS_REQ = 'FACILITY_MFA_OPTIONS_REQ'
export const FACILITY_MFA_OPTIONS_RECV = 'FACILITY_MFA_OPTIONS_RECV'
export const FACILITY_MFA_OPTIONS_ERR = 'FACILITY_MFA_OPTIONS_ERR'

export const AUTH_CREATE_REQ = 'AUTH_CREATE_REQ'
export const AUTH_CREATE_RECV = 'AUTH_CREATE_RECV'
export const AUTH_CREATE_ERR = 'AUTH_CREATE_ERR'

export const AUTH_CHALLENGE_REQ = 'AUTH_CHALLENGE_REQ'
export const AUTH_CHALLENGE_RECV = 'AUTH_CHALLENGE_RECV'
export const AUTH_CHALLENGE_ERR = 'AUTH_CHALLENGE_ERR'

export const AUTH_CHALLENGE_VALIDATE = 'AUTH_CHALLENGE_VALIDATE'
export const AUTH_CHALLENGE_STATUS = 'AUTH_CHALLENGE_STATUS'
export const AUTH_CHALLENGE_STATUS_ERR = 'AUTH_CHALLENGE_STATUS_ERR'

export const MFA_CLEAR = 'MFA_CLEAR'
export const MFA_OPTIONS_CLEAR = 'MFA_OPTIONS_CLEAR'

export const PATIENT_LOOKUP = 'PATIENT_LOOKUP'
export const PATIENT_LOOKUP_RECV = 'PATIENT_LOOKUP_RECV'
export const PATIENT_LOOKUP_ERR = 'PATIENT_LOOKUP_ERR'

export const OTP_REQUEST = 'OTP_REQUEST'
export const OTP_RECV = 'OTP_RECV'
export const OTP_ERR = 'OTP_ERR'
export const OTP_CLEAR = 'OTP_CLEAR'

export const OTP_VERIFY_REQUEST = 'OTP_VERIFY_REQUEST'
export const OTP_VERIFY_RECV = 'OTP_VERIFY_RECV'
export const OTP_VERIFY_ERR = 'OTP_VERIFY_ERR'

export const MFA_CREATE_REQ = 'MFA_CREATE_REQ'
export const MFA_CREATE_RECV = 'MFA_CREATE_RECV'
export const MFA_CREATE_ERR = 'MFA_CREATE_ERR'

/**
 * SSO action constants
 */

export const CREATE_SSO_REQ = 'CREATE_SSO_REQ'
export const CREATE_SSO_RECV = 'CREATE_SSO_RECV'
export const CREATE_SSO_ERR = 'CREATE_SSO_ERR'

export const DO_FACILITY_SSO_REQ = 'DO_FACILITY_SSO_REQ'
export const DO_FACILITY_SSO_RECV = 'DO_FACILITY_SSO_RECV'
export const DO_FACILITY_SSO_ERR = 'DO_FACILITY_SSO_ERR'
